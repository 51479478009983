import React,{Component} from 'react';
import {NavLink,Link} from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import SignOut from '@material-ui/icons/ExitToApp';
import Person from '@material-ui/icons/AccountCircle';
import Data from '@material-ui/icons/DataUsage';
import Information from '@material-ui/icons/Help';
import {withNamespaces} from 'react-i18next';
import { Card } from '@material-ui/core';


import './style.css';

class NavBar extends Component {
    changeLanguage= lng => this.props.i18n.changeLanguage(lng);
    render(){
        const {lng,t} = this.props;
        return (
        <>
            <div className="Header">
                <div className="Header-Container Content">
                    <NavLink to="/"> <img className="NavBar-Brand" src={Logo} alt="griceviz" /></NavLink>
                    <div className="HeaderIconContainer">
                        <span className={"Language HeaderRightIcon"+ (lng==="en"?" Language-Active":"")} onClick={()=>this.changeLanguage('en')}> EN </span>

                        <span className={"Language HeaderRightIcon"+(lng==="tr"?" Language-Active":"")} onClick={()=>this.changeLanguage('tr')}> TR </span>
                        <Link to="/profile" className="HeaderRightIcon" ><Person style={{color:"#777"}}/></Link>
                        <SignOut className="HeaderRightIcon" style={{cursor:"pointer",color:"#777"}} onClick={this.props.signOut}/>

                    </div>

                </div>
            </div>
            <div className="Header-Bottom">
                <div className="Content">
                    <Card className="Header-Bottom-Icon-Container shadow">
                        <NavLink className="NavLink Header-Bottom-Icon-Item" activeClassName="Active" to="/profile">
                            <Person />
                            <span className="Navlink-Tag">{t('navbar.profile')}</span>
                        </NavLink>
                        <NavLink className="NavLink Header-Bottom-Icon-Item" activeClassName="Active" to="/info">
                            <Data/>
                            <span className="Navlink-Tag">{t('navbar.info')}</span>
                        </NavLink>
                        <NavLink className="NavLink Header-Bottom-Icon-Item" activeClassName="Active" to="/about">
                        <Information/>
                        <span className="Navlink-Tag">{t('navbar.about')}</span>
                        </NavLink>
                    </Card>
                </div>
            </div>
        </>

        );
    }
}

export default withNamespaces('common')(NavBar);
