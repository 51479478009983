import React, {Component} from 'react';
import {connect} from 'react-redux';
import NavBar from '../../containers/NavBar';
import {signOut} from '../../store/actions';
import './style.css';

class Layout extends Component {  
 
    render () {        
        return (
            <>
                <NavBar signOut={this.props.signOut} location={this.props.location}/>
                <main className="Content Content-Layout">
                    {this.props.children}
                </main>
                <div className="Footer">
                    <span>© Halıcı Bilgi İşlem A.Ş.- www.halici.com.tr</span>
                </div>
            </>
        );
    }
}
const mapDispatchToProps = dispatch=> {
    return {
        signOut: ()=>dispatch(signOut())
    }
}
export default connect(null,mapDispatchToProps)(Layout);