import React, {Component} from 'react'
import LoginImages from '../../assets/images/Login/index.js';
import {withNamespaces} from 'react-i18next';

class LoginWrapper extends Component {
render(){
    return (
      <div className="Login">
        <div className = "LoginCard row">
          <div className = "col-lg-6 col-md-12" style = {{position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex', minHeight: '400px'}}>
            {this.props.children}
          </div>
          <div className = "col-lg-6 col-md-12 px-0" style = {{background: 'white', paddingTop: '3%'}}>
            <div className = "LoginRightSide" style = {{backgroundImage: `url(${LoginImages.PhoneTr})`}}>
              <img className = "LoginBrainquire ml-0 mt-4 row" alt = "Login" src = {this.props.lng === 'tr' ? LoginImages.BrainquireTr : LoginImages.BrainquireEn}/>
              <div className = "col-7 m-0 mt-5 p-0" style = {{letterSpacing: '0.05em', fontSize: '1.2vmax', fontWeight: 300, color: '#6A6A6A'}}>
                {description}
              </div>
              <div className = "row mt-5">
                <div className = "col-4">
                  <a href="https://itunes.apple.com/tr/app/griceviz/id1240173390?l=tr" target="_blank"><img style =  {{width: '11vmax', maxWidth: '150px'}} alt = "AppStore" src ={this.props.lng === 'tr' ? LoginImages.AppStoreTr : LoginImages.AppStoreEn} /></a>
                </div>
                <div className = "col-4">
                  <a href="https://play.google.com/store/apps/details?id=tr.com.halici.brainquiremobile" target="_blank"><img style = {{width: '11vmax', maxWidth: '150px'}} alt = "GooglePlay" src ={this.props.lng === 'tr' ? LoginImages.GooglePlayTr : LoginImages.GooglePlayEn} /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
}

const description = (
      <div>
        <span style = {{fontWeight:'500'}}> GriCeviz</span>, bilişsel becerilerin ve zihinsel yeteneklerin değerlendirilmesini ve geliştirilmesini hedefleyen, kişiselleştirilmiş beyin egzersizlerinden oluşan bir platformdur.
        <p className = "mt-4">
          Uygulama marketlerinden <span style = {{fontWeight:'500'}}>ücretsiz</span>  indirin ve kaydolun
        </p>
    </div>)
export default withNamespaces('common')(LoginWrapper);
