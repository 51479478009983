import {
  CORPORATION_LIST_FOR_FILTER_SUCCESS,
  ADD_CORPORATION_SUCCESS,
  CORPORATION_LIST_SUMMARY_SUCCESS,
  SIGN_OUT,
  GET_DIVISION_USERS_REQUEST,
  GET_DIVISION_USERS_SUCCESS,
  GET_DIVISION_USERS_FAIL,
} from "../actions/actionTypes";

const initialState = {
  corporationsForFilter: [],
  corporationsSummary: [],
  isFetchingDivisionUsers: false,
};

const corporationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CORPORATION_LIST_FOR_FILTER_SUCCESS:
      return {
        ...state,
        corporationsForFilter: action.corporations,
      };
    case CORPORATION_LIST_SUMMARY_SUCCESS: {
      let corpList = (action.corporations || []).sort(
        (c1, c2) => c2.id - c1.id
      );
      return {
        ...state,
        corporationsSummary: corpList,
      };
    }
    case ADD_CORPORATION_SUCCESS: {
      return {
        ...state,
        corporationsForFilter: [
          ...state.corporationsForFilter,
          {
            id: action.corporation.id,
            value: action.corporation.id,
            text: action.corporation.name,
            divisions: [],
          },
        ],
        corporationsSummary: [...state.corporationsSummary, action.corporation],
      };
    }
    case SIGN_OUT:
      return {
        ...state,
        corporationsForFilter: [],
        corporationsSummary: [],
      };
    case GET_DIVISION_USERS_REQUEST:
      return {
        ...state,
        isFetchingDivisionUsers: true,
      };
    case GET_DIVISION_USERS_SUCCESS:
      return {
        ...state,
        isFetchingDivisionUsers: false,
      };
    case GET_DIVISION_USERS_FAIL:
      return {
        ...state,
        isFetchingDivisionUsers: false,
      };
    default:
      return state;
  }
};

export default corporationsReducer;
