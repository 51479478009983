import React, { Component } from "react";
import { connect } from "react-redux";
import {
  get_coupons,
  deactivate_coupon,
  create_coupon,
} from "../../../store/actions";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Cancel";
import TimerOff from "@material-ui/icons/TimerOff";
import CheckCircle from "@material-ui/icons/CheckCircleOutline";
import "./style.css";

class Coupons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coupons: props.coupons || [],
      filters: { active: true, passive: true, personal: true, global: true },
    };
    this._submitCoupon = this._submitCoupon.bind(this);
  }

  componentDidMount() {
    this.props.get_coupons(this.state.filters).then((response) => {
      if (response) {
        this.setState({ coupons: response });
      }
    });
  }

  componentWillReceiveProps() {}

  _submitCoupon(e) {
    e.preventDefault();
    const params = {
      code: this.code.value,
      source_id: this.source_id.value,
      source: this.source.checked ? "user" : null,
      value: this.value.value,
      valid_from: this.valid_from.value,
      valid_till: this.valid_till.value,
      percent: this.percent.checked,
      use_count: this.use_count.value,
    };
    this.props
      .create_coupon(params)
      .then((res) => this.setState({ coupons: this.props.coupons }));
  }

  render() {
    return (
      <div className="row">
        <table>
          <tbody>
            <tr>
              <th>#</th>
              <th>Code</th>
              <th>id-use once</th>
              <th>Value/%</th>
              <th>From</th>
              <th>Till</th>
              <th>Use Count</th>
              <th>Used</th>
              <th>Action</th>
            </tr>
            <tr key="create">
              <td />
              <td>
                <input
                  type="text"
                  ref={(c) => (this.code = c)}
                  name="code"
                  placeholder="optional"
                  style={{ maxWidth: "100px" }}
                />
              </td>
              <td>
                <input
                  type="number"
                  ref={(c) => (this.source_id = c)}
                  name="for"
                  placeholder="all"
                  style={{ maxWidth: "40px" }}
                />
                <input
                  type="checkbox"
                  ref={(c) => (this.source = c)}
                  name="source"
                  placeholder="%"
                  style={{ maxWidth: "40px" }}
                />
              </td>
              <td>
                <input
                  type="number"
                  ref={(c) => (this.value = c)}
                  name="value"
                  placeholder="25"
                  style={{ maxWidth: "40px" }}
                />
                <input
                  type="checkbox"
                  ref={(c) => (this.percent = c)}
                  name="value"
                  placeholder="%"
                  style={{ maxWidth: "40px" }}
                />
              </td>
              <td>
                <input
                  type="date"
                  ref={(c) => (this.valid_from = c)}
                  name="fname"
                  style={{ maxWidth: "150px" }}
                />
              </td>
              <td>
                <input
                  type="date"
                  ref={(c) => (this.valid_till = c)}
                  name="fname"
                  style={{ maxWidth: "150px" }}
                />
              </td>
              <td>
                <input
                  type="number"
                  ref={(c) => (this.use_count = c)}
                  name="usecount"
                  placeholder="unlimited"
                  style={{ maxWidth: "40px" }}
                  disabled={true}
                />
              </td>
              <td />
              <td>
                <CheckCircle
                  key="check"
                  style={{ cursor: "pointer", color: "#3B84B0" }}
                  onClick={this._submitCoupon}
                />
              </td>
            </tr>
            {this.state.coupons.map((c, i) => {
              return (
                <tr key={`${i}_${c.code}`}>
                  <td>{i + 1}</td>
                  <td>{c.code}</td>
                  <td>{c.source ? c.source_id : "All"}</td>
                  <td>
                    {c.value}
                    {c.percent ? "%" : "TL"}
                  </td>
                  <td>{moment(c.valid_from).format("DD.MM.YYYY - HH:mm")}</td>
                  <td>{moment(c.valid_till).format("DD.MM.YYYY - HH:mm")}</td>
                  <td>{c.use_count ? c.use_count : "Not Used"}</td>
                  <td>
                    {c.used_at &&
                      moment(c.used_at).format("DD.MM.YYYY - HH:mm")}
                  </td>
                  <td>
                    <TimerOff
                      key="timeup"
                      style={{ cursor: "pointer", color: "#3B84B0" }}
                      onClick={() => {
                        this.props.deactivate_coupon(c.id).then((response) => {
                          console.log("CPS: ", this.props.coupons);

                          this.setState({ coupons: this.props.coupons });
                        });
                      }}
                    />
                    <CloseIcon
                      key="close"
                      style={{ cursor: "pointer", color: "red" }}
                      onClick={() => {}}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    coupons: state.purchase.coupons,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_coupons: (filters) => dispatch(get_coupons(filters)),
    deactivate_coupon: (id) => dispatch(deactivate_coupon(id)),
    create_coupon: (params) => dispatch(create_coupon(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("common")(Coupons));
