import {
  CORPORATION_LIST_FOR_FILTER_FAIL,
  CORPORATION_LIST_FOR_FILTER_REQUEST,
  CORPORATION_LIST_FOR_FILTER_SUCCESS,
  ADD_CORPORATION_REQUEST,
  ADD_CORPORATION_SUCCESS,
  ADD_CORPORATION_FAIL,
  CORPORATION_DETAILS_REQUEST,
  CORPORATION_DETAILS_SUCCESS,
  CORPORATION_DETAILS_FAIL,
  DELETE_CORP_ADMIN_REQUEST,
  DELETE_CORP_ADMIN_SUCCESS,
  DELETE_CORP_ADMIN_FAIL,
  CREATE_CORP_ADMIN_SUCCESS,
  CREATE_CORP_ADMIN_REQUEST,
  CREATE_CORP_ADMIN_FAIL,
  DELETE_CORP_DIV_ADMIN_FAIL,
  DELETE_CORP_DIV_ADMIN_SUCCESS,
  DELETE_CORP_DIV_ADMIN_REQUEST,
  CREATE_CORP_DIV_ADMIN_FAIL,
  CREATE_CORP_DIV_ADMIN_SUCCESS,
  CREATE_CORP_DIV_ADMIN_REQUEST,
  DELETE_CORP_DIV_USER_REQUEST,
  DELETE_CORP_DIV_USER_SUCCESS,
  DELETE_CORP_DIV_USER_FAIL,
  CREATE_CORP_DIV_USER_REQUEST,
  CREATE_CORP_DIV_USER_SUCCESS,
  CREATE_CORP_DIV_USER_FAIL,
  CORPORATION_LIST_SUMMARY_REQUEST,
  CORPORATION_LIST_SUMMARY_SUCCESS,
  CORPORATION_LIST_SUMMARY_FAIL,
  GET_DIV_NAMES_AND_COUNTS_REQUEST,
  GET_DIV_NAMES_AND_COUNTS_SUCCESS,
  GET_DIV_NAMES_AND_COUNTS_FAIL,
  EDIT_CORPORATION_INFO_REQUEST,
  EDIT_CORPORATION_INFO_SUCCESS,
  EDIT_CORPORATION_INFO_FAIL,
  ADD_DIVISION_REQUEST,
  ADD_DIVISION_SUCCESS,
  ADD_DIVISION_FAIL,
  EDIT_DIVISION_REQUEST,
  EDIT_DIVISION_SUCCESS,
  EDIT_DIVISION_FAIL,
  DELETE_DIVISION_REQUEST,
  DELETE_DIVISION_SUCCESS,
  DELETE_DIVISION_FAIL,
  GET_DIVS_AND_USERS_REQUEST,
  GET_DIVS_AND_USERS_SUCCESS,
  GET_DIVS_AND_USERS_FAIL,
  CREATE_CORP_PRIVILAGE_CHECK_FAIL,
  CREATE_CORP_PRIVILAGE_CHECK_REQUEST,
  CREATE_CORP_PRIVILAGE_CHECK_SUCCESS,
  REPORT_DATA_REQUEST,
  REPORT_DATA_SUCCESS,
  REPORT_DATA_FAIL,
  GET_DIVISION_USERS_REQUEST,
  GET_DIVISION_USERS_SUCCESS,
  GET_DIVISION_USERS_FAIL
} from './actionTypes';
import axios from '../../axios-instance';
import api from '../../api';
import { showError } from './';
const getCorporationListForFilterSuccess = (corporations,corporationDetails) => {
    return {
        type:CORPORATION_LIST_FOR_FILTER_SUCCESS,
        corporations,corporationDetails
    }
}

const getCorporationListForFilterFail = () => {
    return {
        type:CORPORATION_LIST_FOR_FILTER_FAIL
    }
}

const getCorporationListForFilterRequest = () => {
    return {
        type:CORPORATION_LIST_FOR_FILTER_REQUEST
    }
}

export const getCorporationListForFilter = () => {
    return (dispatch,getState) => {
        dispatch(getCorporationListForFilterRequest());
        return axios.get(api.list_corporations_for_filter,{params:{
            token:getState().auth.token
        }})
        .then(response=>{
            const corporations = response.data.map(
                corporation=> {
                    return {
                        id:corporation.id,
                        text:corporation.name,
                        value:corporation.id,
                        divisions:corporation.divisions.map(division=>{
                            return {
                                id:division.id,
                                value:division.id,
                                text:division.name,
                                corporation_id:division.corporation_id
                            }
                        })
                    }
                }
            );
                dispatch(getCorporationListForFilterSuccess(corporations));
        }).catch(error=>{
            dispatch(getCorporationListForFilterFail());
            dispatch(showError(error.message));
        })

    }
}

const getCorporationListSummaryRequest = () => {
    return {
        type:CORPORATION_LIST_SUMMARY_REQUEST
    }
}
const getCorporationListSummarySuccess = corporations => {
    return {
        type:CORPORATION_LIST_SUMMARY_SUCCESS,
        corporations
    }
}
const getCorporationListSummaryFail = () => {
    return {
        type:CORPORATION_LIST_SUMMARY_FAIL
    }
}

export const getCorporationListSummary = () => {
    return (dispatch,getState) => {
        dispatch(getCorporationListSummaryRequest())
        return axios.get(api.list_corporations,{
            params:{
                token:getState().auth.token,
                summary:true
            }
        })
        .then(response=>{
            dispatch(getCorporationListSummarySuccess(response.data))
        })
        .catch(error=>{
            dispatch(getCorporationListSummaryFail());
            dispatch(showError(error.message));
        })
    }
}
const addCorporationRequest = () => {
    return {
        type: ADD_CORPORATION_REQUEST
    }
}

const addCorporationSuccess = corporation => {
    return {
        type: ADD_CORPORATION_SUCCESS,
        corporation
    }
}

const addCorporationFail = () => {
    return {
        type: ADD_CORPORATION_FAIL
    }
}

export const addCorporation = corporation => {
    return (dispatch,getState) => {
        dispatch(addCorporationRequest());
        return axios.post(api.add_corporation,{
            ...corporation, token:getState().auth.token
        })
        .then(response => {
            dispatch(addCorporationSuccess(response.data.corporation));
            return response.data;
        })
        .catch(error=>{
            dispatch(addCorporationFail());
            dispatch(showError(error.message));
        })
    }
}

const getCorporationDetailsRequest = () => {
    return {
        type:CORPORATION_DETAILS_REQUEST
    }
}

const getCorporationDetailsSuccess = () => {
    return {
        type:CORPORATION_DETAILS_SUCCESS
    }
}

const getCorporationDetailsFail = () => {
    return {
        type:CORPORATION_DETAILS_FAIL
    }
}

export const getCorporationDetails = corporation_id => {
    return (dispatch,getState) => {
        dispatch(getCorporationDetailsRequest())
        return axios.get(api.get_corporation_details,{params:{corporation_id,token:getState().auth.token}})
            .then(response=> {
                dispatch(getCorporationDetailsSuccess());
                return response.data;
            })
            .catch(error=>{
                dispatch(getCorporationDetailsFail());
                dispatch(showError(error.message));
            })
    }
}

const deleteCorpAdminRequest = () => {
    return {
        type:DELETE_CORP_ADMIN_REQUEST
    }
}
const deleteCorpAdminSuccess = () => {
    return {
        type:DELETE_CORP_ADMIN_SUCCESS
    }
}
const deleteCorpAdminFail = () => {
    return {
        type:DELETE_CORP_ADMIN_FAIL
    }
}

export const deleteCorpAdmin = (corporation_id,user_id) => {
    return (dispatch,getState) => {
        dispatch(deleteCorpAdminRequest());
        return axios.post(api.delete_corporation_admin,{
            token:getState().auth.token,
            corporation_id,user_id
        }).then(response=>{
            dispatch(deleteCorpAdminSuccess())
            return response.data;
        }).catch(error=>{
            dispatch(deleteCorpAdminFail());
            dispatch(showError(error.message));
        })
    }
}

const createCorpAdminRequest = () => {
    return {
        type:CREATE_CORP_ADMIN_REQUEST
    }
}

const createCorpAdminSuccess = () => {
    return {
        type:CREATE_CORP_ADMIN_SUCCESS
    }
}

const createCorpAdminFail = () => {
    return {
        type:CREATE_CORP_ADMIN_FAIL
    }
}

export const createCorpAdmin = (data) => {
    return (dispatch,getState) => {
        dispatch(createCorpAdminRequest());
        return axios.post(api.create_corporation_admin,{
            ...data,token:getState().auth.token
        })
        .then(response=> {
            dispatch(createCorpAdminSuccess(response.data))
            return response.data;
        })
        .catch(error=>{
            dispatch(createCorpAdminFail());
            dispatch(showError(error.message));
        })
    }
}

const deleteCorpDivAdminRequest = () => {
    return {
        type:DELETE_CORP_DIV_ADMIN_REQUEST
    }
}
const deleteCorpDivAdminSuccess = () => {
    return {
        type:DELETE_CORP_DIV_ADMIN_SUCCESS
    }
}
const deleteCorpDivAdminFail = () => {
    return {
        type:DELETE_CORP_DIV_ADMIN_FAIL
    }
}

export const deleteCorpDivAdmin = (corp_div_id, user_id) => {
    return (dispatch,getState) => {
        dispatch(deleteCorpDivAdminRequest());
        return axios.post(api.del_corp_division_admin,{
            token:getState().auth.token,
            corp_division_id:corp_div_id,
            user_id
        }).then(response=>{
            dispatch(deleteCorpDivAdminSuccess())
            return response.data;
        }).catch(error=>{
            dispatch(deleteCorpDivAdminFail());
            dispatch(showError(error.message));
        })
    }
}

const createCorpDivAdminRequest = () => {
    return {
        type:CREATE_CORP_DIV_ADMIN_REQUEST
    }
}
const createCorpDivAdminSuccess = () => {
    return {
        type: CREATE_CORP_DIV_ADMIN_SUCCESS
    }
}
const createCorpDivAdminFail = () => {
    return {
        type: CREATE_CORP_DIV_ADMIN_FAIL
    }
}

export const createCorpDivAdmin = (data) => {
    return (dispatch,getState) => {
        dispatch(createCorpDivAdminRequest());
        return axios.post(api.create_corp_division_admin,{
            token:getState().auth.token,
            ...data
        }).then(response=>{
            dispatch(createCorpDivAdminSuccess())
            return response.data;
        }).catch(error=>{
            dispatch(createCorpDivAdminFail());
            dispatch(showError(error.message));
        })
    }
}


const deleteCorpDivUserRequest = () => {
    return {
        type:DELETE_CORP_DIV_USER_REQUEST
    }
}
const deleteCorpDivUserSuccess = () => {
    return {
        type:DELETE_CORP_DIV_USER_SUCCESS
    }
}
const deleteCorpDivUserFail = () => {
    return {
        type:DELETE_CORP_DIV_USER_FAIL
    }
}

export const deleteCorpDivUser = (corp_div_id, user_id) => {
    return (dispatch,getState) => {
        dispatch(deleteCorpDivUserRequest());
        return axios.post(api.del_corp_division_user,{
            token:getState().auth.token,
            corp_division_id:corp_div_id,
            user_id
        }).then(response=>{
            dispatch(deleteCorpDivUserSuccess())
            return response.data;
        }).catch(error=>{
            dispatch(deleteCorpDivUserFail());
            dispatch(showError(error.message));
        })
    }
}

const createCorpDivUserRequest = () => {
    return {
        type:CREATE_CORP_DIV_USER_REQUEST
    }
}
const createCorpDivUserSuccess = () => {
    return {
        type: CREATE_CORP_DIV_USER_SUCCESS
    }
}
const createCorpDivUserFail = () => {
    return {
        type: CREATE_CORP_DIV_USER_FAIL
    }
}

export const createCorpDivUser = (data) => {
    return (dispatch,getState) => {
        dispatch(createCorpDivUserRequest());
        return axios.post(api.create_corp_division_user,{
            token:getState().auth.token,
            ...data
        }).then(response=>{
            dispatch(createCorpDivUserSuccess())
            return response.data;
        }).catch(error=>{
            dispatch(createCorpDivUserFail());
            dispatch(showError(error.message));
        })
    }
}

const getDivNamesAndCountsRequest = () => {
    return {
        type: GET_DIV_NAMES_AND_COUNTS_REQUEST
    }
}
const getDivNamesAndCountsSuccess = corporation => {
    return {
        type: GET_DIV_NAMES_AND_COUNTS_SUCCESS,
        corporation
    }
}
const getDivNamesAndCountsFail = () => {
    return {
        type: GET_DIV_NAMES_AND_COUNTS_FAIL
    }
}

export const getDivNamesAndCounts = (corporation_id) => {
    return (dispatch,getState) => {
        dispatch(getDivNamesAndCountsRequest());
        return axios.get(api.get_div_names_and_counts, {params:{
            token: getState().auth.token,
            corporation_id}
        }).then(response=>{
            dispatch(getDivNamesAndCountsSuccess(response.data))
            return response.data;
        }).catch(error=>{
            dispatch(getDivNamesAndCountsFail());
            dispatch(showError(error.message));
        })
    }
}

const editCorporationInfoRequest = () => {
    return {
        type: EDIT_CORPORATION_INFO_REQUEST
    }
}
const editCorporationInfoSuccess = () => {
    return {
        type: EDIT_CORPORATION_INFO_SUCCESS
    }
}
const editCorporationInfoFail = () => {
    return {
        type: EDIT_CORPORATION_INFO_FAIL
    }
}

export const editCorporationInfo = (corporation) => {
    return (dispatch,getState) => {
        dispatch(editCorporationInfoRequest());
        return axios.post(api.edit_corporation_info, {
            token: getState().auth.token,
            ...corporation
        }).then(response=>{
            dispatch(editCorporationInfoSuccess())
            return response.data;
        }).catch(error=>{
            dispatch(editCorporationInfoFail());
            dispatch(showError(error.message));
        })
    }
}

const addDivisionRequest = () => {
    return {
        type: ADD_DIVISION_REQUEST
    }
}
const addDivisionSuccess = () => {
    return {
        type: ADD_DIVISION_SUCCESS
    }
}
const addDivisionFail = () => {
    return {
        type: ADD_DIVISION_FAIL
    }
}

export const addDivision = (corp_id, division) => {
    return (dispatch,getState) => {
        dispatch(addDivisionRequest());
        return axios.post(api.add_division, {
            token: getState().auth.token,
            corp_id,
            ...division
        }).then(response=>{
            dispatch(addDivisionSuccess())
            return response.data;
        }).catch(error=>{
            dispatch(addDivisionFail());
            dispatch(showError(error.message));
        })
    }
}

const editDivisionRequest = () => {
    return {
        type: EDIT_DIVISION_REQUEST
    }
}
const editDivisionSuccess = () => {
    return {
        type: EDIT_DIVISION_SUCCESS
    }
}
const editDivisionFail = () => {
    return {
        type: EDIT_DIVISION_FAIL
    }
}

export const editDivision = (corp_id, division) => {
    return (dispatch,getState) => {
        dispatch(editDivisionRequest());
        return axios.post(api.edit_division, {
            token: getState().auth.token,
            corp_id,
            ...division
        }).then(response=>{
            dispatch(editDivisionSuccess())
            return response.data;
        }).catch(error=>{
            dispatch(editDivisionFail());
            dispatch(showError(error.message));
        })
    }
}

const deleteDivisionRequest = () => {
    return {
        type: DELETE_DIVISION_REQUEST
    }
}
const deleteDivisionSuccess = () => {
    return {
        type: DELETE_DIVISION_SUCCESS
    }
}
const deleteDivisionFail = () => {
    return {
        type: DELETE_DIVISION_FAIL
    }
}

export const deleteDivision = (corp_id, div_id) => {
    return (dispatch,getState) => {
        dispatch(deleteDivisionRequest());
        return axios.post(api.delete_division, {
            token: getState().auth.token,
            corp_id,
            div_id
        }).then(response=>{
            dispatch(deleteDivisionSuccess())
            return response.data.result === "successful";
        }).catch(error=>{
            dispatch(deleteDivisionFail());
            dispatch(showError(error.message));
        })
    }
}

const getDivsAndUsersRequest = () => {
    return {
        type: GET_DIVS_AND_USERS_REQUEST
    }
}
const getDivsAndUsersSuccess = (data) => {
    return {
        type: GET_DIVS_AND_USERS_SUCCESS,
        data,
    }
}
const getDivsAndUsersFail = () => {
    return {
        type: GET_DIVS_AND_USERS_FAIL
    }
}

export const getDivsAndUsers = () => {
    return (dispatch,getState) => {
        dispatch(getDivsAndUsersRequest());
        axios.get(api.get_divs_and_users, {params: {
            token: getState().auth.token}
        }).then(response=>{
            dispatch(getDivsAndUsersSuccess(response.data))
        }).catch(error=>{
            dispatch(getDivsAndUsersFail());
            dispatch(showError(error.message));
        })
    }
}

const createCorpPrivilageCheckRequest = () => {
    return {
        type:CREATE_CORP_PRIVILAGE_CHECK_REQUEST
    }
}
const createCorpPrivilageCheckSuccess = () => {
    return {
        type:CREATE_CORP_PRIVILAGE_CHECK_SUCCESS
    }
}
const createCorpPrivilageCheckFail = () => {
    return {
        type:CREATE_CORP_PRIVILAGE_CHECK_FAIL
    }
}

export const createCorpPrivilageCheck = () => (dispatch,getState) => {
    dispatch(createCorpPrivilageCheckRequest());
    return axios.get(api.create_corp_privilage_check,{params:{token:getState().auth.token}})
        .then(response=> {
            dispatch(createCorpPrivilageCheckSuccess());
            return response.data;
        })
        .catch(error=>{
            dispatch(createCorpPrivilageCheckFail());
            dispatch(showError(error.message));
        })
}

const getReportDataRequest = () => ({
    type: REPORT_DATA_REQUEST
});

const getReportDataSuccess = () => ({
    type: REPORT_DATA_SUCCESS
});

const getReportDataFail = () => ({
    type: REPORT_DATA_FAIL
})



export const getReportData = ({corporation_id,corp_division_id}) => (dispatch, getState) => {
    dispatch(getReportDataRequest());
    return axios.get(api.report_data,{params:{token:getState().auth.token,corporation_id,corp_division_id}})
        .then(result=> {
            dispatch(getReportDataSuccess());
            return result.data;
        })
        .catch(error=>{
            dispatch(getReportDataFail());
            dispatch(showError(error.message));
        })
}





export const getDivisionUsers = ({division_id}) => (dispatch, getState) => {
    const requestAction = {type: GET_DIVISION_USERS_REQUEST};
    const successAction = {type: GET_DIVISION_USERS_SUCCESS};
    const failAction = {type: GET_DIVISION_USERS_FAIL};

    dispatch(requestAction);
    return axios.get(api.get_division_users,{params:{token:getState().auth.token,division_id}})
        .then(result=> {
            dispatch(successAction);
            return result.data;
        })
        .catch(error=>{
            dispatch(failAction);
            dispatch(showError(error.message));
        })
}