import axios from "axios";
import { baseURL } from "./environment";
import { signOut } from "./store/actions";

const instance = axios.create({
  baseURL,
  timeout: 30000,
  headers: { "Content-Type": "application/json", Accept: "application/json" },
});

export const setInterceptors = (store) => {
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        store.dispatch(signOut());
      }

      return Promise.reject(
        error.response &&
        error.response.data &&
        error.response.data.error_description
          ? {
              ...error.response.data,
              message: error.response.data.error_description,
            }
          : error
      );
    }
  );
};
export default instance;
