import {
    showError
} from './';
import {
    SIGN_OUT,
    SIGN_IN_SUCCESS,
    SIGN_IN_REQUEST,
    SIGN_IN_FAIL,
    FB_SIGN_IN_SUCCESS,
    FB_SIGN_IN_REQUEST,
    FB_SIGN_IN_FAIL,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAIL,
    RESET_PASSWORD_FAIL,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
} from './actionTypes';
import axios from '../../axios-instance';
import api from '../../api';

export const signInSuccess = (token, user) => {
    return {
        type: SIGN_IN_SUCCESS,
        token,
        user
    }
}
const singInRequest = () => {
    return {
        type: SIGN_IN_REQUEST
    }
}
const signInFail = () => {
    return {
        type: SIGN_IN_FAIL
    }
}

export const signIn = (email, password) => {

    return dispatch => {
        dispatch(singInRequest());
        axios.post(api.sign_in, { email, password, platform: "WEB"})
            .then(res => {
                dispatch(signInSuccess(res.data.token, res.data.user));
                localStorage.setItem("user", JSON.stringify(res.data.user));
                localStorage.setItem("token", res.data.token);
            })
            .catch(error => {
                dispatch(showError(error.message));
                dispatch(signInFail());
            })
    }
}

export const autoSignIn = () => {
    return dispatch => {
        const user = JSON.parse(localStorage.getItem("user"));
        const token = localStorage.getItem("token");
        if (user && token) {
            dispatch(signInSuccess(token, user));
        }
    }
}

const signOutSuccess = () => {
    return {
        type: SIGN_OUT
    }
}

export const signOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    return signOutSuccess();
}

const changePasswordRequest = () => {
    return {
        type:CHANGE_PASSWORD_REQUEST
    }
}

const changePasswordSuccess = () => {
    return {
       type:CHANGE_PASSWORD_SUCCESS
    }

}

const changePasswordFail = () => {
    return {
        type:CHANGE_PASSWORD_FAIL
    }
}

export const changePassword = ({old_password,new_password}) => {
    return (dispatch,getState) => {
        dispatch(changePasswordRequest());
        return axios.post(api.change_password,{old_password,new_password,token:getState().auth.token})
          .then(response=>{
              dispatch(changePasswordSuccess());
              return response.data;
          })
          .catch(error=>{
              dispatch(showError(error.message));
              dispatch(changePasswordFail());
          })
    }
}

const fbSignInSuccess = (token, user) => {
    return {
        type: FB_SIGN_IN_SUCCESS,
        token,
        user
    }
}
const fbSingInRequest = () => {
    return {
        type: FB_SIGN_IN_REQUEST
    }
}
const fbSignInFail = () => {
    return {
        type: FB_SIGN_IN_FAIL
    }
}

export const fbSignIn = (user) => {
  return dispatch => {
    dispatch(fbSingInRequest());
    axios.post(api.fb_sign_in, {
        first_name: user.first_name,
        last_name: user.last_name,
        fb_id: user.id,
        fb_email: user.email,
        picture_url: user.picture.data.url,
        platform: "WEB",
    })
    .then(res => {
        dispatch(fbSignInSuccess(res.data.token, res.data.user));
        localStorage.setItem("user", JSON.stringify(res.data.user));
        localStorage.setItem("token", res.data.token);
    })
    .catch(error => {
        dispatch(showError(error.message));
        dispatch(fbSignInFail());
    })
  }
}

const forgotPasswordSuccess = () => {
    return {
        type: FORGOT_PASSWORD_SUCCESS,
    }
}
const forgotPasswordRequest = () => {
    return {
        type: FORGOT_PASSWORD_REQUEST
    }
}
const forgotPasswordFail = () => {
    return {
        type: FORGOT_PASSWORD_FAIL
    }
}

export const forgotPassword = (email) => {
  return dispatch => {
    dispatch(forgotPasswordRequest());
    return axios.post(api.forgot_password, {email,callback_url:"https://info.griceviz.com/reset_password/"})
      .then(res => {
          dispatch(forgotPasswordSuccess());
          return res;
      })
      .catch(error => {
          dispatch(showError(error.message));
          dispatch(forgotPasswordFail());
      })
    }
}

const resetPasswordSuccess = () => {
    return {
        type: RESET_PASSWORD_SUCCESS,
    }
}
const resetPasswordRequest = () => {
    return {
        type: RESET_PASSWORD_REQUEST
    }
}
const resetPasswordFail = () => {
    return {
        type: RESET_PASSWORD_FAIL
    }
}

export const resetPassword = (creds) => {
  return dispatch => {
    dispatch(resetPasswordRequest());
    return axios.post(api.reset_password, {
      email: creds.email,
      validation_code: creds.validationCode,
      new_password: creds.newPassword
    })
      .then(res => {
          dispatch(resetPasswordSuccess());
          return res.data;
      })
      .catch(error => {
          dispatch(showError(error.message));
          dispatch(resetPasswordFail());
      })
  }
}

