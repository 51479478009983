import React from 'react';

export default function PrivacyPolicyPage() {
    return <div style={{ padding: 50 }}>
        <h3>PRIVACY POLICY</h3>
        <h5>Confidentiality Policy </h5>
        <p>We appreciate your use of BrainQuire Mental Development Games. We are aware of the importance of your privacy and would like to provide you with a healthy and secure user experience. This document will explain to you how the information which was requested from you, will be gathered and will be used. </p>
        <p>Requested and collected information is used to provide more accurate statistical results for you.</p>
        <h5>Types of Collected Information</h5>
        <p>As you begin to use the application as a member or guest, you are asked for information such as:</p>
        <ul>
            <li>E-mail address,</li>
            <li>Name,</li>
            <li>Date of birth,</li>
            <li>Occupation,</li>
            <li>Education status,</li>
            <li>Gender,</li>
            <li>Settings (language, theme, time-zone etc.)</li>
            <li>Game results (scores, elapsed times, play counts, daily/weekly/monthly usages)</li>
        </ul>

        <h5>Types of User Data Not Collected</h5>
        <ul>
            <li>Credit card or related payment information, </li>
            <li>Phone number,</li>
            <li>Social media information (contacts, friends)</li>
            <li>Location information (address, country, gps data etc.)</li>
        </ul>

        <p>You can access and edit your personal account at any time, or you can change your personal information and privacy preferences. If you find that information such as an invoice or a delivery is not accurate, complete or updated, you will need to correct it. If you provide incorrect, erroneous or outdated information, your registration may be interrupted and the reports provided by us may no longer be valid.
        </p>
        

        <h5>Advertising</h5>
        <p>Brainquire application does not contain any ads. Your personal data is not shared with third parties for marketing purposes. The platform may use your personal information for reasons such as general statistical data.
        
        </p>

        <h5>How To Delete Your Personal Info</h5>
        <p>When you use the “Login with Facebook” option, your Facebook account is used to log in to the Brainquire application. Brainquire does not save your Facebook personal data on its server. You can follow the steps below to disconnect the Brainquire application from your Facebook account and have this information deleted:</p>
        <ol>
            <li>Go to your Facebook Account’s Settings and Privacy. Click “Settings”</li>
            <li>Look for “Apps and Websites” and you will see all of the apps and websites you linked with your Facebook.</li>
            <li>Search and Click “Brainquire” in the search bar.</li>
            <li>Scroll and click “Remove”.</li>
            <li>Congratulations, you have now deleted the Brainquire application and your Facebook account connection and application activities from the system.</li>
            <li>If you want your game data stored anonymously on application servers for statistical purposes to be deleted, you can send an e-mail to info@brainquire.com</li>
        </ol>
        <p>The Privacy Policy may be edited and updated without giving a prior notice to the users as platform updates are received. For this reason, it is recommended that the user should review the policy every time they visit the platform. This document was last updated on March 5, 2022.
        </p>

        <h5>Contact Us</h5>
        <p>Please contact us if you have any questions about the Privacy Policy or platform implementations</p>
        <p>Address: ODTU Teknokent ODTU – Halici Software House Universiteler Quarter 06800 Cankaya/Ankara </p>
        <p>E-mail: bilgi@halici.com.tr</p>

    </div>

}