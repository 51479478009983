import React,{Component} from 'react';
import {connect} from 'react-redux'
import DonutChart from '../../../components/Charts/DonutChart'
import SortableList from '../../../components/Lists/SortableList'
import {getGamePlayCount, getCorporationListForFilter} from '../../../store/actions'
import FilterBar from '../../../components/FilterBar'
import SelectedFiltersBar from '../../../components/SelectedFiltersBar'
import {filterObjectToQuery} from '../../../utils/helpers'
import DateRangePicker from '../../../components/DateRangePicker';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import {withNamespaces} from 'react-i18next';

class Games extends Component {
  constructor(props){
    super(props);
    this.state = {
      selectedFilters: [],
    };
    this.datePicker = React.createRef();
    this.rowIndex = 0;
  }

  componentDidMount(){
    this.props.getGamePlayCount(this.state.selectedFilters);
  }

  componentWillReceiveProps(){
  }

  filterAddedHandler = (selectedFilter) => {
    const filters = this.state.selectedFilters[selectedFilter.name] || null;
    let newFilters;
    if(filters&&selectedFilter.name !== "date" && !selectedFilter.singleSelect)
      newFilters=[...filters,selectedFilter.option];
    else newFilters =[selectedFilter.option];
    const division = {};
    if (selectedFilter.name==="corporation_id") division.division_id=[]
    this.setState({
        selectedFilters: {
            ...this.state.selectedFilters,
            [selectedFilter.name]: newFilters,
            ...division
    }});
  }

  filterRemovedHandler = (removedFilter) => {
    const newFilters = this.state.selectedFilters[removedFilter.name].filter(opt => opt.value !== removedFilter.option.value);
    const division = {};
    if (removedFilter.name==="corporation_id") division.division_id=[]
    this.setState({
      selectedFilters: {
        ...this.state.selectedFilters,
        [removedFilter.name]: newFilters,
        ...division
      }
    }, ()=>{
        if(removedFilter.name==="date")
        this.datePicker.current.clearDates();
    })
  }

  getGamePlayCount = () => {
    let result = this.props.gamePlayCount ? this.props.gamePlayCount.map(game => {return {...game}}) : (() => {
        let gamePlayCount = [];
        for(let game in games) {
          gamePlayCount.push({...emptyGame, name: games[game]})
        }
        return gamePlayCount
      })();

      return result
  }

  categoryPlayCounts = (gamePlayCount) => {
    const memoryCategory = ['memgrid', 'memorder', 'kites', 'maze'];
    const numericCategory = ['balloons', 'equify', 'digit_puzzle', 'digit_exchange'];
    const attentionCategory = ['colorout', 'butterflies', 'changes', 'arrows'];
    const logicCategory = ['groups', 'choices' ,'ballinbox', 'doyaba'];
    const visualCategory = ['rotate_me', 'pieces', 'mirror', 'cube'];
    const wordsCategory = ['letter_circles','word_search', 'letter_balls' , 'wordlock'];

    const categoryObj = {name: '' ,today: 0 , last7: 0, last30: 0, total: 0, uniqPlayer: 0, avg_score: 0};
    let memory = {...categoryObj, name: 'memory'};
    let numeric = {...categoryObj, name: 'numeric'};
    let attention = {...categoryObj, name: 'attention'};
    let logic =  {...categoryObj, name: 'logic'};
    let visual =  {...categoryObj, name: 'visual'};
    let words =  {...categoryObj, name: 'words'};

    const sumObjs = (game, category) => {
      for(let key in game){
        if(key !== 'name')
        category[key] = (category[key] || 0) + game[key];
      }
      return category;
    }

    gamePlayCount.forEach((game) => {
        if(memoryCategory.includes(game.name)){
          sumObjs(game, memory);
        }else if (numericCategory.includes(game.name)) {
          sumObjs(game, numeric);
        }else if (attentionCategory.includes(game.name)) {
          sumObjs(game, attention);
        }else if (logicCategory.includes(game.name)){
          sumObjs(game, logic);
        }else if (visualCategory.includes(game.name)){
          sumObjs(game, visual);
        }else if (wordsCategory.includes(game.name)){
          sumObjs(game, words);
        }
      })

      let result = [memory, numeric, attention, logic, visual, words];
      result = result.map(category => {
        return {
          ...category,
          avg_score: (category.avg_score / 4)
        };
      })

      return result;
  }

  getChartData = (categoryPlayCounts) => {
    let data = categoryPlayCounts.map((category,i) => {return {[this.props.t('categories.'+category.name)] : category.total}})
    let result = data.reduce((acc,cur) => {
      return acc = {...acc, ...cur}
    }, {})
    return result;
  }

  formatterComponentAvg = ({value}) => (
    <span>
      {(+value).toFixed(2)}
      %
    </span>
  )

  categoryNameFormatter = ({value}) => {
    return this.props.t(`categories.${value}`)
  }

  gamesNameFormatter = ({value}) => {
    return this.props.t(`games.${value}.name`)
  }


  dataTypeProvider = (value) => {
      return (
      [<DataTypeProvider key = "0"
        formatterComponent = {this.formatterComponentAvg}
        for = {['avg_score']}
      />,
      <DataTypeProvider key = "1"
        formatterComponent = {value === 'games' ? this.gamesNameFormatter : this.categoryNameFormatter}
        for = {['name']}
      />]
    )
  }

  rowComponent = (props) => {
    return (
      <Table.Row
        {...props}
        style= {this.rowIndex++ % 2 ? {backgroundColor: '#fff'} : {backgroundColor: '#F5F7F9'}}
      />
    )
  }

  tableCellComponent = (props) => {
    return (
      <Table.Cell {...props}
        style = {{...props.style, borderBottom: '0px'}}>
      </Table.Cell>)
  }

  tableHeaderCellComponent = (props) => {
    let common = {color: 'white', paddingLeft:'24px', paddingRight: '0px', fontWeight: 'bold'};
    let style = {
      today: {...common, backgroundColor: '#24738C'},
      last7: {...common, backgroundColor: '#006480'},
      last30: {...common, backgroundColor: '#24738C'},
      total: {...common, backgroundColor: '#354B5C'},
      uniq_user: {...common, backgroundColor: '#00BC9D'},
      avg_score: {...common, backgroundColor: '#FFCF70'},
    }
    return(
      <TableHeaderRow.Cell {...props}
        style = {style[props.column.name]}
      />
    )}

    onSortingChange = (props) => {
      this.rowIndex = 0;
    }

  render(){
    const {t} = this.props;
    let gamePlayCount = this.getGamePlayCount().map(record => {return {...record}});
    let categoryPlayCounts = this.categoryPlayCounts(gamePlayCount);
    let chartPlayCount = this.props.gamePlayCount ? this.getChartData(categoryPlayCounts) : playCountEmpty(t);

    const filterKeys = [
        t('membershipTypeFilter',{returnObjects: true}),
        t('platformFilter',{returnObjects: true}),
        {
            ...t('corporationFilter', {returnObjects: true}),
            singleSelect:true,
            getData:this.props.getCorporationListForFilter,
            options:this.props.corporations
        }
    ];

    if(this.state.selectedFilters["corporation_id"]&&this.state.selectedFilters["corporation_id"][0]){
        filterKeys.push({
            ...t('divisionFilter', {returnObjects: true}),
            options:this.state.selectedFilters["corporation_id"][0].divisions
        })
    }

    return(
        <div className = 'row'>
            <div className = 'col-md-9'>
              <div className = "card d-block shadow p-4 ">
                    <p className = "h5  ml-2 d-inline font-weight-bold ">
                      {t('category_play_counts')}
                    </p>
                    <SelectedFiltersBar
                      filters = {this.state.selectedFilters}
                      onFilterRemove = {this.filterRemovedHandler}
                    />
                    <FilterBar
                      filterKeys = {filterKeys}
                      onFilterAdd = {this.filterAddedHandler}
                      onFilterRemove = {this.filterRemovedHandler}
                      selectedFilters = {this.state.selectedFilters}
                    />
                    <DateRangePicker
                        text={t('date')}
                        onDateSelect={this.filterAddedHandler}
                        ref={this.datePicker}
                        />
                    <button className="btn btn-sm ml-2" style= {{color: 'white', backgroundColor: '#39708c'}}
                      onClick={() => this.props.getGamePlayCount(this.state.selectedFilters)}>{t('filter')}</button>
                    <div className = "mt-3">
                      <SortableList columns={columns(t)}
                        dataTypeProvider = {this.dataTypeProvider('category')}
                        rows = {categoryPlayCounts}
                        columnExtensionsSort = {[{columnName: 'name', sortingEnabled: false}]}
                        onSortingChange = {this.onSortingChange}
                        rowComponent = {this.rowComponent}
                        tableCellComponent = {this.tableCellComponent}
                        columnExtensionsTable = {columnExtensions}
                        tableHeaderCellComponent = {this.tableHeaderCellComponent}
                        avgColumns = {['avg_score']}
                      />
                    </div>
                </div>
                <div className = "card shadow mt-4 d-block p-4">
                  <p className = "h5 mt-2 mx-2 d-inline">
                    {t('game_play_counts')}
                  </p>
                  <SelectedFiltersBar
                    filters = {this.state.selectedFilters}
                    onFilterRemove = {this.filterRemovedHandler}
                  />
                  <FilterBar
                    filterKeys = {filterKeys}
                    onFilterAdd = {this.filterAddedHandler}
                    onFilterRemove = {this.filterRemovedHandler}
                    selectedFilters = {this.state.selectedFilters}
                  />
                  <DateRangePicker
                      text={t('date')}
                      onDateSelect={this.filterAddedHandler}
                      ref={this.datePicker}
                      />
                  <button className="btn btn-sm ml-2" style= {{color: 'white', backgroundColor: '#39708c'}}
                    onClick={() => this.props.getGamePlayCount(this.state.selectedFilters)}>{t('filter')}
                  </button>
                  <div className = "mt-3">
                    <SortableList columns={columns(t)}
                      dataTypeProvider = {this.dataTypeProvider('games')}
                      rows = {gamePlayCount}
                      columnExtensions = {[{columnName: 'name', sortingEnabled: false}]}
                      rowComponent = {this.rowComponent}
                      onSortingChange = {this.onSortingChange}
                      changeSorting = {() => this.rowIndex = 0}
                      tableCellComponent = {this.tableCellComponent}
                      columnExtensionsTable = {columnExtensions}
                      tableHeaderCellComponent = {this.tableHeaderCellComponent}
                      avgColumns = {['avg_score']}
                    />
                  </div>
                </div>
            </div>
            <div className = 'col-md-3'>
              <div className = "card shadow bg-white rounded px-4 py-4 ">
                <DonutChart
                  data = {chartPlayCount}
                  height = {300}
                  option = {{
                  legend: {labels: {boxWidth: 30}},
                  cutoutPercentage: 50,
                }}/>
                <h5 className = "mt-3 text-center">{t('play_counts')}</h5>
              </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    gamePlayCount: state.statistics.gamePlayCount,
    corporations: state.corporations.corporationsForFilter,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getGamePlayCount: (filters) => dispatch(getGamePlayCount(filterObjectToQuery(filters))),
    getCorporationListForFilter: () => dispatch(getCorporationListForFilter())
  }
}

const columnExtensions = [
  {columnName: 'name'},
  {columnName: 'today',  align: 'center'},
  {columnName: 'last7', align: 'center'},
  {columnName: 'last30',  align: 'center'},
  {columnName: 'total', align: 'center'},
  {columnName: 'uniq_user', align: 'center', width: 150},
  {columnName: 'avg_score', align: 'center', width: 180},
]

const columns = (t) => {
  return [
    { name: 'name', title: ' ' },
    { name: 'today', title: t('today').toUpperCase()},
    { name: 'last7', title: t('last7').toUpperCase()},
    { name: 'last30', title: t('last30').toUpperCase()},
    { name: 'total', title: t('total').toUpperCase()},
    { name: 'uniq_user', title: t('uniq_user').toUpperCase()},
    { name: 'avg_score', title: t('avg_score').toUpperCase()}
  ];
}

let playCountEmpty = t => {
 return {
    [t('categories.visual')]: 1,
    [t('categories.words')]: 1,
    [t('categories.memory')]: 1,
    [t('categories.logic')]: 1,
    [t('categories.attention')]: 1,
    [t('categories.numeric')]: 1,
  }
};

let emptyGame = {name: '' ,today: 0 , last7: 0, last30: 0, total: 0, uniq_user: 0, avg_score: 0};
let games = ['memgrid', 'memorder', 'kites', 'maze', 'balloons', 'equify', 'digit_puzzle', 'digit_exchange',
'colorout', 'butterflies', 'changes', 'arrows', 'groups', 'choices' ,'ballinbox', 'doyaba', 'rotate_me',
'pieces', 'mirror', 'cube', 'letter_circles','word_search', 'letter_balls' , 'wordlock'];


export default connect(mapStateToProps,mapDispatchToProps)(withNamespaces('common')(Games));
