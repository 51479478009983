import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getCorporationDetails,
  deleteCorpAdmin,
  createCorpAdmin,
  deleteCorpDivAdmin,
  createCorpDivAdmin,
  createCorpDivUser,
  deleteCorpDivUser,
  getReportData,
  getDivisionUsers,
} from "../../../../store/actions";
import { withI18n } from "react-i18next";
import {
  Grid,
  Card,
  Typography,
  CircularProgress,
  Modal,
  withStyles,
} from "@material-ui/core";
import { FindInPage } from "@material-ui/icons";
import Edit from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";

import CorpUserList from "./CorpUserList";

import CustomInput from "../../../../components/ui/CustomInput";
import { inventoryURL } from "../../../../environment";
import "./style.css";
import CorporationReport from "../../../../components/Report/types/CorporationReport";
import DivisionReport from "../../../../components/Report/types/DivisionReport";




const loadingScreen = <Card className="shadow p-4 d-flex justify-content-center">
  <CircularProgress />
</Card>;

const errorScreen = (message) => <Card className="shadow p-4">
  {message}
</Card>

class Corporation extends Component {
  state = {
    selectedDivisionIndex: -1,
    divisionFilterText: "",
    showReport: false,
    reportType: "",
  };

  componentDidMount() {
    const corporation_id = +this.props.match.params.id;
    this.props.getCorporationDetails(corporation_id).then((corporation) => {
      const firstDivision = corporation && corporation.divisions[0];
      const selectedDivisionIndex = firstDivision ? 0 : -1;
      this.setState({
        corporation,
        selectedDivisionIndex
      });

      if (firstDivision) {
        this.fetchDivisionUsers(firstDivision, selectedDivisionIndex);
      }
    }

    );
  }

  deleteCorpAdmin = (user_id) => {
    this.props
      .deleteCorpAdmin(this.state.corporation.id, user_id)
      .then((data) => {
        if (data)
          this.setState((prevState) => {
            return {
              corporation: {
                ...prevState.corporation,
                admins: prevState.corporation.admins.filter(
                  (admin) => admin.id !== user_id
                ),
              },
            };
          });
      });
  };
  createCorpAdmin = (user) => {
    this.props
      .createCorpAdmin({
        ...user,
        corporation_id: this.state.corporation.id,
      })
      .then((user) => {
        if (user)
          this.setState((prevState) => {
            return {
              corporation: {
                ...prevState.corporation,
                admins: [...prevState.corporation.admins, user],
              },
            };
          });
      });
  };

  deleteCorpDivAdmin = (userId) => {
    const divisionId = this.state.corporation.divisions[
      this.state.selectedDivisionIndex
    ].id;
    this.props.deleteCorpDivAdmin(divisionId, userId).then((data) => {
      if (data)
        this.setState((prevState) => {
          return {
            corporation: {
              ...prevState.corporation,
              divisions: prevState.corporation.divisions.map((division) => {
                if (division.id === divisionId)
                  division.admins = division.admins.filter(
                    (admin) => admin.id !== userId
                  );
                return division;
              }),
            },
          };
        });
    });
  };

  createCorpDivAdmin = (user) => {
    const divisionId = this.state.corporation.divisions[
      this.state.selectedDivisionIndex
    ].id;
    this.props
      .createCorpDivAdmin({
        ...user,
        corp_division_id: divisionId,
      })
      .then((user) => {
        user &&
          this.setState((prevState) => {
            return {
              corporation: {
                ...prevState.corporation,
                divisions: [
                  ...prevState.corporation.divisions.map((division) => {
                    if (division.id === divisionId)
                      return {
                        ...division,
                        admins: [...division.admins, user],
                      };
                    return division;
                  }),
                ],
              },
            };
          });
      });
  };

  deleteCorpDivUser = (userId) => {
    const divisionId = this.state.corporation.divisions[
      this.state.selectedDivisionIndex
    ].id;
    this.props.deleteCorpDivUser(divisionId, userId).then((data) => {
      if (data)
        this.setState((prevState) => {
          return {
            corporation: {
              ...prevState.corporation,
              divisions: prevState.corporation.divisions.map((division) => {
                if (division.id === divisionId) {
                  division.user_count = division.user_count - 1;
                  division.users = division.users.filter(
                    (user) => user.id !== userId
                  );
                }
                return division;
              }),
            },
          };
        });
    });
  };

  createCorpDivUser = (user) => {
    const divisionId = this.state.corporation.divisions[
      this.state.selectedDivisionIndex
    ].id;
    this.props
      .createCorpDivUser({
        ...user,
        corp_division_id: divisionId,
      })
      .then((user) => {
        user &&
          this.setState((prevState) => {
            return {
              corporation: {
                ...prevState.corporation,
                divisions: [
                  ...prevState.corporation.divisions.map((division) => {
                    if (division.id === divisionId) {
                      division.user_count = division.user_count + 1;
                      return {
                        ...division,
                        users: [...division.users, user],
                      };
                    }
                    return division;
                  }),
                ],
              },
            };
          });
      });
  };
  withDivisions = (users, idString, report) =>
    users.map((user, index) => {
      const divisions =
        this.state.corporation && this.state.corporation.divisions
          ? this.state.corporation.divisions
            .filter((division) =>
              division.users.some(
                (div_user) => div_user.id === user[idString || "id"]
              )
            )
            .map((division) => division.name)
          : [];

      return {
        ...user,
        divisions: report ? divisions.join(",") : divisions,
        ...(report
          ? {
            order: index + 1,
            user_name: `${user.first_name} ${user.last_name}`,
            score: user.score.toFixed(2),
          }
          : {}),
      };
    });

  reportData = (params, type) => {
    if (type === "div" && params.corp_division_id === -1) return;
    if (params.inventory) {
      if (type == "corp") {
        window.open(
          `${inventoryURL}/schoolreport/${params.corporation_id
          }/corporation/0?token=${this.props.token}`
        );
      } else if (type == "div") {
        window.open(
          `${inventoryURL}/schoolreport/${params.corp_id}/division/${params.corp_division_id
          }?token=${this.props.token}`
        );
      }
    } else {
      this.setState({ showReport: true, reportData: null, reportType: type });
      this.props.getReportData(params).then((reportData) => {
        const data = {
          ...reportData,
          by_category: Object.keys(reportData.by_category).reduce(
            (acc, category) => {
              acc[category] = this.withDivisions(
                reportData.by_category[category],
                "user_id",
                true
              );
              return acc;
            },
            {}
          ),
          by_game: Object.keys(reportData.by_game).reduce((acc, game) => {
            acc[game] = this.withDivisions(
              reportData.by_game[game],
              "user_id",
              true
            );
            return acc;
          }, {}),
        };
        this.setState({ reportData: data });
      });
    }
  };
  closeReport = () => this.setState({ showReport: false, reportType: null });
  updateRestrictedGames = (divisionIndex, data) => {
    this.setState((prevState) => ({
      corporation: {
        ...prevState.corporation,
        divisions: prevState.corporation.divisions.map((division, index) => {
          if (index !== divisionIndex) return division;
          return {
            ...division,
            users: prevState.corporation.divisions[divisionIndex].users.map(
              (user, userIndex) => ({
                ...user,
                restricted_games: [...data[userIndex].games],
              })
            ),
          };
        }),
      },
    }));
  };
  updatePlayLimits = (divisionIndex, data) => {
    this.setState((prevState) => ({
      corporation: {
        ...prevState.corporation,
        divisions: prevState.corporation.divisions.map((division, index) => {
          if (index !== divisionIndex) return division;
          return {
            ...division,
            users: prevState.corporation.divisions[divisionIndex].users.map(
              (user, userIndex) => ({
                ...user,
                play_limit: data[userIndex].value || null,
              })
            ),
          };
        }),
      },
    }));
  };




  render() {
    const { corporation, selectedDivisionIndex } = this.state;
    const { t, lng } = this.props;
    if (!corporation)
      return this.props.corporationDetailsLoading ? loadingScreen : errorScreen(t("errorMessages.corporationNotFound"));


    const divisions = (corporation.divisions || []).filter((division) =>
      division.name
        .toLocaleLowerCase(lng)
        .includes(this.state.divisionFilterText.toLocaleLowerCase(lng))
    );


    const division = divisions[selectedDivisionIndex] || {};
    const isCorpAdmin =
      this.props.user.role === 1 ||
      corporation.admins.some((admin) => admin.id === this.props.user.id);

    return (
      <>
        <Grid container className="Corporation">
          <Grid item xs={12} sm={4} md={3}>
            {this.generateCorpGeneralInfo(corporation)}
            {this.generateDivisionList(divisions)}
          </Grid>


          <Grid item sm={8} md={9} xs={12}>
            {this.corpAdminList(corporation, isCorpAdmin)}
            {selectedDivisionIndex > -1 && (
              <>
                {this.generateDivisionAdmins(division, t, isCorpAdmin, corporation)}
                {this.generateUserList(division, t, corporation)}
              </>
            )}
          </Grid>
        </Grid>
        {this.generateReportModal(corporation)}
      </>
    );
  }

  generateReportModal(corporation) {
    return <Modal
      open={this.state.showReport}
      onClose={this.closeReport}
      className={this.props.classes.modalStyle1}
    >
      {this.state.reportType === "corp" ? (
        <CorporationReport
          loading={this.props.reportDataLoading}
          data={this.state.reportData}
          corporation={corporation} />
      ) : (
        <DivisionReport
          loading={this.props.reportDataLoading}
          data={this.state.reportData}
          division={corporation.divisions[this.state.selectedDivisionIndex]}
          corporation={corporation} />
      )}
    </Modal>;
  }

  generateUserList(division, t, corporation) {
    return <CorpUserList
      users={this.withDivisions(division.users || [])}
      delete={this.deleteCorpDivUser}
      loading={this.props.corpDivUserLoading || this.props.isFetchingDivisionUsers}
      create={this.createCorpDivUser}
      title={t("corporation_info.corp_users")}
      inventory={corporation.inventory}
      restrictionManage
      reportable={true}
      selectedDivisionIndex={this.state.selectedDivisionIndex}
      onRestrictionComplete={this.updateRestrictedGames}
      onSetPlayLimitComplete={this.updatePlayLimits} />;
  }

  generateDivisionAdmins(division, t, isCorpAdmin, corporation) {
    return <>
      <CorpUserList
        users={this.withDivisions(division.admins || [])}
        delete={this.deleteCorpDivAdmin}
        create={this.createCorpDivAdmin}
        loading={this.props.corpDivAdminLoading}
        title={t("corporation_info.corp_div_admins")}
        disable={!isCorpAdmin}
        inventory={corporation.inventory}
        reportClick={() => this.reportData(
          {
            inventory: corporation.inventory,
            corp_id: corporation.id,
            corp_division_id: corporation.divisions[this.state.selectedDivisionIndex].id,
          },
          "div"
        )}
        divReportName={corporation.divisions[this.state.selectedDivisionIndex].name} />
      <br className="mt-4" />
    </>;
  }

  corpAdminList(corporation, isCorpAdmin) {
    const t = this.props.t;
    return <>
      <CorpUserList
        users={this.withDivisions(corporation.admins || [])}
        delete={this.deleteCorpAdmin}
        create={this.createCorpAdmin}
        loading={this.props.corpAdminLoading}
        title={t("corporation_info.corp_admins")}
        disable={!isCorpAdmin}
        inventory={corporation.inventory}
        reportClick={() => this.reportData(
          {
            inventory: corporation.inventory,
            corporation_id: corporation.id,
          },
          "corp"
        )} />
      <br className="mt-4" />
    </>


  }

  fetchDivisionUsers = (division, index) => {
    this.props.getDivisionUsers({ division_id: division.id }).then(res => {
      const oldCount = (this.state.corporation.divisions[index].users).length;
      const newCount = res? res.length : oldCount;
      if (oldCount !== newCount) {
        let newCorp = this.state.corporation;
        newCorp.divisions[index].users = res;
        this.setState({ corporation: newCorp });
      }

    });
  }

  onDivisionClick = (division, index) => {
    this.setState({ selectedDivisionIndex: index });

    // bu division'ın user listesi henüz çekilmediyse çekelim:
    const divisionData = this.state.corporation.divisions[index];
    const userCount = divisionData.users.length;
    const countThatCameFromServer = divisionData.user_count;

    if (userCount !== countThatCameFromServer)
      this.fetchDivisionUsers(division, index);
  }

  generateDivisionList(divisions) {
    const { t } = this.props;
    return <Card className="shadow mt-4 p-4 ">
      <div className="d-flex justify-content-between">
        <Typography variant="h6">{t("divisions")}</Typography>
        <CustomInput
          lefticon={<FindInPage style={{ color: "#7A7A7A" }} />}
          placeholder={t("searchDivision")}
          value={this.state.divisionFilterText}
          onChange={(e) => this.setState({ divisionFilterText: e.target.value })}
          containerstyle={{
            maxWidth: "50%",
            backgroundColor: "#F0F0F0",
            borderWidth: 0,
          }} />
      </div>

      <div>
        {divisions.map((division, index) => (
          <div
            key={division.id}
            className={"DivisionSelect" +
              (this.state.selectedDivisionIndex === index
                ? " Active"
                : "")}

            onClick={() => this.onDivisionClick(division, index)}

          // onClick={this.state.corpDivUserLoading !== index
          //   ? () => this.setState({ selectedDivisionIndex: index })
          //   : () => { }}
          >
            <Typography
              style={{
                color: this.state.selectedDivisionIndex === index
                  ? "white"
                  : "#7a7a7a",
              }}
              variant="subtitle2"
            >
              {division.name}
            </Typography>
            <div>
              <Typography
                style={{
                  color: this.state.selectedDivisionIndex === index
                    ? "white"
                    : "#7a7a7a",
                }}
                variant="subtitle2"
              >
                {division.user_count}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </Card>;
  }

  generateCorpGeneralInfo(corporation) {
    return <Card className="shadow">
      <div className="ImageContainer">
        <img
          className="img img-responsive Image"
          src={corporation.image}
          alt={corporation.name} />
        <Typography className="Name" variant="h6">
          {corporation.name}
          <Link to={"/info/corporations/" + corporation.id + "/edit"}>
            <Edit
              style={{
                cursor: "pointer",
                color: "#3A6C8D",
                padding: "0 3px",
                border: "1px solid #3A6C8D",
                borderRadius: 5,
                marginLeft: 8,
              }} />
          </Link>
        </Typography>
      </div>
    </Card>;
  }
}

const styles = (theme) => ({
  modalStyle1: {
    position: "absolute",
    top: "10%",
    left: "10%",
    overflow: "scroll",
    height: "100%",
    display: "block",
  },
});

const mapStateToProps = (state) => {
  return {
    corporationDetails: state.corporations.corporationDetails,
    corporationDetailsLoading: state.loading["CORPORATION_DETAILS"],
    corpAdminLoading:
      state.loading["CREATE_CORP_ADMIN"] || state.loading["DELETE_CORP_ADMIN"],
    corpDivAdminLoading:
      state.loading["CREATE_CORP_DIV_ADMIN"] ||
      state.loading["DELETE_CORP_DIV_ADMIN"],
    corpDivUserLoading:
      state.loading["CREATE_CORP_DIV_USER"] ||
      state.loading["DELETE_CORP_DIV_USER"],
    reportDataLoading: state.loading["REPORT_DATA"],
    user: state.auth.user,
    token: state.auth.token,
    isFetchingDivisionUsers: state.corporations.isFetchingDivisionUsers
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCorporationDetails: (corporation_id) =>
      dispatch(getCorporationDetails(corporation_id)),
    deleteCorpAdmin: (corporation_id, user_id) =>
      dispatch(deleteCorpAdmin(corporation_id, user_id)),
    createCorpAdmin: (data) => dispatch(createCorpAdmin(data)),
    deleteCorpDivAdmin: (corp_div_id, user_id) =>
      dispatch(deleteCorpDivAdmin(corp_div_id, user_id)),
    createCorpDivAdmin: (data) => dispatch(createCorpDivAdmin(data)),
    deleteCorpDivUser: (corp_div_id, user_id) =>
      dispatch(deleteCorpDivUser(corp_div_id, user_id)),
    createCorpDivUser: (data) => dispatch(createCorpDivUser(data)),
    getReportData: (params) => dispatch(getReportData(params)),
    getDivisionUsers: (params) => dispatch(getDivisionUsers(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withI18n()(withStyles(styles)(Corporation)));
