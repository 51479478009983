import React, {Component} from 'react'
import {connect} from 'react-redux'
import DonutChart from '../../../components/Charts/DonutChart'
import BandedColumnTable from '../../../components/Lists/BandedColumnTable'
import HorizontalBarChart from '../../../components/Charts/HorizontalBarChart'
import {getNewUserCounts, getUserCounts} from '../../../store/actions'
import { Table, TableBandHeader, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import {SingleDatePicker} from "react-dates";
import SelectedFiltersBar from '../../../components/SelectedFiltersBar'
import Refresh from '@material-ui/icons/Autorenew'
import {withNamespaces} from 'react-i18next';
import moment from "moment";

class UserCounts extends Component {
  constructor(props){
    super(props);
    this.state = {
      activeButton: 0,
      selectedFilters: [],
      date: moment(),
      datePickerFocused: false,
    };
  }


  componentDidMount(){
    this.props.getNewUserCounts();
    this.props.getUserCounts(this.state.date);
  }

  _onClick = (i) => {
    switch (i) {
      case 0:
        this.setState({activeButton: 0});
        break;
      case 1:
        this.setState({activeButton: 1});
        break;
      default:
    }
  };

  getTotalUsers = (newUserCounts) => {
    let member = Object.values(newUserCounts.member).reduce((acc, curr) => {return acc + curr});
    let guest = Object.values(newUserCounts.guest).reduce((acc, curr) => {return acc + curr});
    return {
      member: member,
      guest: guest,
    }
  }

  filterAddedHandler = (selectedFilter) => {
    this.setState({
        selectedFilters: {
            [selectedFilter.name]: [selectedFilter.option]
    }});
  }

  filterRemovedHandler = (removedFilter) => {
    const newFilters = this.state.selectedFilters[removedFilter.name].filter(opt => opt.value !== removedFilter.option.value);
    this.setState({
      selectedFilters: {
        ...this.state.selectedFilters,
        [removedFilter.name]: newFilters,
      }
    }, ()=>{
        if(removedFilter.name === "date")
        this.datePicker.current.clearDates();
    })
  }

  tableCellComponent = (props) => {
    const {column} = props;
    if(column.name === 'name'){
      let style;
      let cellStyle;
      if(props.value.includes('member') || props.value === 'guest')
        style = {color:'#24738C',fontWeight: 'bold', marginLeft: '0.4em'}
      else if (props.value === 'total') {
        style = {color:'#24738C',fontWeight: 'bold', marginRight: '0.5em'}
        cellStyle = {textAlign: 'right'}
      }
      else {
        style = {color:'#7D7D7D',fontWeight: 'bold',marginLeft: '1.5em'}
      }
      return (
        <Table.Cell {...props} style = {{...props.style,backgroundColor: '#f6f7f9', borderBottom: '0px', ...cellStyle}}>
          <span style = {style}>
            {this.props.t('userCountsTable.' + props.value)}
          </span>
        </Table.Cell>)
    }
    return <Table.Cell {...props} style = {{...props.style, borderBottom: '0px'}}/>
  }

  bandHeaderCellComponent = (props) => {
    let backgroundColor;
    props.tableColumn.column.name === "passive" ? backgroundColor = '#82919C' : backgroundColor = '#7FAAB8';
    return (
      <TableBandHeader.Cell {...props} style = {{textAlign: 'center', color: 'white', fontWeight: 'bold', backgroundColor: backgroundColor,...props.style}}>
        {props.children}
      </TableBandHeader.Cell>
    )
  }

  getRows = () => {
    let {userCounts} = this.props;
    let rows = userCounts ? initRows.map((row,idx) => {return {...row, ...userCounts[row.name]}}) : initRows;
    let result = [];
    let totalArr = [];
    let total;
    let labels = ['personal_total', 'family_total', 'corporation_total', 'school_total'];
    rows.forEach((row,idx) => {
        if(row.name === 'guest')
          result.push(row);
        else{
          totalArr.push(row)
        }
        if(idx % 4 === 3){
          total = totalArr.reduce((acc, cur) => {
            for(let k in cur){
              if(k !== 'name')
                acc[k] = (acc[k] || 0) + cur[k]
            } return acc;
          }, {})
          total.name = labels[Math.floor(idx/4)]
          result.push(...totalArr, total)
          totalArr  = [];
        }
    })
    let generalTotal = rows.reduce((acc, cur) => {
      for(let k in cur){
        if(k !== 'name')
          acc[k] = (acc[k] || 0) + cur[k]
      } return acc;
    },{})
    generalTotal.name = 'total';
    result.push(generalTotal);
    return result;
  }

  rowComponent = (props) => {
    return (
      <Table.Row
        {...props}
        style= {props.row.name.includes("total") || props.row.name.includes("total")
          ? {backgroundColor: '#f6f7f9'} : null}
      />
    )
  }

  tableHeaderCellComponent = (props) => {
    let common = {color: 'white', paddingRight: '0px', fontWeight: 'bold'};
    let style = {
      passive: {...common, backgroundColor: '#82919C'},
      active: {...common, backgroundColor: '#596D7B'},
      total: {...common, backgroundColor: '#354B5C'},
      today: {...common, backgroundColor: '#7FAAB8'},
      last7: {...common, backgroundColor: '#538FA2'},
      last30: {...common, backgroundColor: '#24738C'},
    }
    return(
      <TableHeaderRow.Cell {...props} style = {style[props.column.name]} />
  )}

  getLastDaysInfo = (rows, days) => {
    let {t} = this.props;
    let result = {};
    rows.forEach(row => {
      if(row.name.includes('total')){
        if(row.name !== 'total'){
        let name = row.name.split("_")
        result[t(name[0])] = row[`last${days}`]}
      }else if(row.name === 'guest')
        result[t(row.name)] = row[`last${days}`]
    })
    return result;
  }

  getNewUserCounts = (newUserCountsEmpty) => {
    let {newUserCounts, t} = this.props;
    let result = {}
    for(let type in newUserCountsEmpty){
      result[type] = {}
      for(let day in newUserCountsEmpty[type])
          result[type][day] = 0;
    }
    for(let type in newUserCounts){
      for(let day in newUserCounts[type]){
        result[type][t(`weeklyUsers.${moment(day).format('dddd')}`)] = newUserCounts[type][day]
      }
    }
    return result;
  }

  render(){
    let {t} = this.props;
    let rows = this.getRows();
    let newUserCountsEmpty = getNewUserCountsEmpty(this.props.t);
    let newUserCounts = this.props.newUserCounts ? this.getNewUserCounts(newUserCountsEmpty) : newUserCountsEmpty;
    let userCountLast7 = this.props.userCounts ? this.getLastDaysInfo(rows, 7) : userCountEmpty(t);
    let userCountLast30 = this.props.userCounts ? this.getLastDaysInfo(rows, 30) : userCountEmpty(t);
    let totalNewUser = this.props.newUserCounts ? this.getTotalUsers(newUserCounts) : {member:0, guest: 0};

    return(
      <div className = 'row'>
        <div className = 'col-md-7 col-lg-8 col-xl-9'>
          <div className = "card d-block shadow p-4 ">
            <div className = "d-flex align-items-center">
              <div className = "h5 ml-2 d-inline font-weight-bold mb-0">
                {t('user_counts')}
              </div>
              <div className = "ml-3">
                <SelectedFiltersBar
                  filters = {this.state.selectedFilters}
                  onFilterRemove = {this.filterRemovedHandler}
                />
              </div>
              <div className ="ml-auto mt-1" style = {{cursor:'pointer'}}>
                <SingleDatePicker
                  focused={this.state.datePickerFocused}
                  onFocusChange={focus => this.setState({datePickerFocused: focus.focused})}
                  numberOfMonths={1}
                  date={this.state.date}
                  block
                  small
                  regular
                  displayFormat="DD/MM/YYYY"
                  id="userCount"
                  hideKeyboardShortcutsPanel
                  placeholder={t("date")}
                  isOutsideRange={() => false}
                  onDateChange={date => this.setState({date: date})}
                />
              </div>
              <div className = "mx-2 d-inline" style = {{fontWeight: '250', fontSize: '16px', cursor: 'pointer'}}
                onClick={() => {this.props.getUserCounts(this.state.date); this.props.getNewUserCounts();}} >
                <Refresh/> {t('filter')}
              </div>
            </div>
            <div className = "mt-3 ">
              <BandedColumnTable
                columns = {columns}
                rows = {rows}
                columnBands = {columnBands}
                rowComponent = {this.rowComponent}
                columnExtensions = {columnExtensions}
                tableCellComponent = {this.tableCellComponent}
                bandHeaderCellComponent = {this.bandHeaderCellComponent}
                tableHeaderCellComponent = {this.tableHeaderCellComponent}/>
            </div>
          </div>
        </div>
        <div className = 'col-lg-4 col-md-5 col-xl-3'>
          <div className = "card shadow bg-white rounded px-4 py-4 ">
            <h5 className = "text-center">{t('new_attendant')}</h5>
            <div className = "row text-center d-flex justify-content-center">
              <div className = "mr-2">
                <div className = "h2 mb-0">
                  {totalNewUser.member}
                </div>
                <div>
                  {t('member')}
                </div>
              </div>
              <div className = "ml-2 text-secondary">
                <div className = "h2 mb-0">
                  {totalNewUser.guest}
                </div>
                <div>
                  {t('guest')}
                </div>
              </div>
            </div>
            <HorizontalBarChart
              data = {newUserCounts.member}
              label = {t('user')}
              secondData = {newUserCounts.guest}
              secondLabel = {t('guest')}
              options = {{
                scales: {
                  yAxes: [{ stacked: true }]
                },
                legend: {
                  display: false,
                }
              }}
            />
          </div>
          <div className = "card shadow bg-white rounded px-4 py-4 my-4">
            <h5 className = "text-center">{t('user_counts')}</h5>
            <div className = "d-inline text-center">
              <button type = "button" onClick={() => this._onClick(0)} className = {`ToggleButton btn badge-pill my-1 mx-1 Donut-Chart-Filter-Button ${this.state.activeButton === 0 ? 'active' : 'passive'}`}>{t('last7')}</button>
              <button type = "button" onClick={() => this._onClick(1)} className = {`ToggleButton btn badge-pill my-1 mx-1 Donut-Chart-Filter-Button ${this.state.activeButton === 1 ? 'active' : 'passive'}`}>{t('last30')}</button>
            </div>
            <DonutChart
              data = {this.state.activeButton === 0 ? userCountLast7 : userCountLast30}
              height = {300}
              options = {{
              legend: {position: 'bottom', labels: {boxWidth: 30}},
              cutoutPercentage: 50,
            }}/>
          </div>
        </div>
      </div>
  )}
}

const mapStateToProps = state => {
  return {
    userCountLast7: state.statistics.userCountLast7,
    userCountLast30: state.statistics.userCountLast30,
    newUserCounts: state.statistics.newUserCounts,
    userCounts: state.statistics.userCounts,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getNewUserCounts: () => dispatch(getNewUserCounts()),
    getUserCounts: (date) => dispatch(getUserCounts(date)),
  }
}

const columnExtensions = [
  {columnName: 'name', width: 160},
  {columnName: 'passive',  align: 'center'},
  {columnName: 'active', align: 'center'},
  {columnName: 'total',  align: 'center'},
  {columnName: 'today', align: 'center'},
  {columnName: 'last7', align: 'center'},
  {columnName: 'last30', align: 'center'},
]

const initRows = [
  {name: 'personal_member', passive: 0, active: 0, total:0, today: 0, last7: 0, last30: 0},
  {name: 'personal_gift', passive: 0, active: 0, total: 0, today: 0, last7: 0, last30: 0},
  {name: 'personal_test', passive: 0, active: 0, total: 0, today: 0, last7: 0, last30: 0},
  {name: 'personal_demo', passive: 0, active: 0, total: 0, today: 0, last7: 0, last30: 0},
  {name: 'family_member', passive:0, active: 0, total: 0, today: 0, last7: 0, last30: 0},
  {name: 'family_gift', passive:0, active: 0, total: 0, today: 0, last7: 0, last30: 0},
  {name: 'family_test', passive:0, active: 0, total: 0, today: 0, last7: 0, last30: 0},
  {name: 'family_demo', passive:0, active: 0, total: 0, today: 0, last7: 0, last30: 0},
  {name: 'corporation_member', passive:0, active: 0, total: 0, today: 0, last7: 0, last30: 0},
  {name: 'corporation_gift', passive:0, active: 0, total: 0, today: 0, last7: 0, last30: 0},
  {name: 'corporation_test', passive:0, active: 0, total: 0, today: 0, last7: 0, last30: 0},
  {name: 'corporation_demo', passive:0, active: 0, total: 0, today: 0, last7: 0, last30: 0},
  {name: 'school_member', passive:0, active: 0, total: 0, today: 0, last7: 0, last30: 0},
  {name: 'school_gift', passive:0, active: 0, total: 0, today: 0, last7: 0, last30: 0},
  {name: 'school_test', passive:0, active: 0, total: 0, today: 0, last7: 0, last30: 0},
  {name: 'school_demo', passive:0, active: 0, total: 0, today: 0, last7: 0, last30: 0},
  {name: 'guest', passive: '-', active: '-', total: 0, today: 0, last7: 0, last30: 0},
]

const columns = [
  {name: 'name', title: ' '},
  {name: 'passive', title: 'PASİF'},
  {name: 'active', title: 'AKTİF'},
  {name: 'total', title:'TOPLAM'},
  {name: 'today', title: 'BUGÜN'},
  {name: 'last7', title: 'SON 7 GÜN'},
  {name: 'last30', title: 'SON 30 GÜN'}]

const columnBands = [
  {
    title: 'TÜMÜ',
    children: [
      {columnName: 'passive'},
      {columnName: 'active'},
      {columnName: 'total'}
    ]
  },
  {
    title: 'YENİ KATILAN',
    children: [
      {columnName: 'today'},
      {columnName: 'last7'},
      {columnName: 'last30'}
    ]
  }
]

const getNewUserCountsEmpty = (t) => {
  let result = {
    member: {},
    guest: {}
  }
  for (let i = 6; i > -1; i--) {
    result.member[t(`weeklyUsers.${moment().subtract(i, 'days').format('dddd')}`)] = 1;
    result.guest[t(`weeklyUsers.${moment().subtract(i, 'days').format('dddd')}`)] = 1;
  }
  return result
};

const userCountEmpty = (t) => {
  return {
    [t('personal')] : 1,
    [t('corporation')] : 1,
    [t('school')] : 1,
    [t('family')] : 1,
    [t('guest')] : 1,
  }
};



export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('common')(UserCounts));
