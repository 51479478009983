import React, {Component} from 'react';
import {HorizontalBar} from 'react-chartjs-2';

class HorizontalBarChart extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: {},
      option: {},
      colors: [

      ],
    }
  }

  render() {

  const data = {
    labels: Object.keys(this.props.data || {}),
    datasets: [
      {
        label: this.props.label,
        backgroundColor: 'rgba(13, 34, 70, 0.6)',
        hoverBackgroundColor: '#0D2246',
        data: Object.values(this.props.data || {}),
      },
    ]};

    this.props.secondData && (data.datasets = [...data.datasets,
      {
        label: this.props.secondLabel,
        backgroundColor: 'rgba(169, 179, 174, 0.8)',
        hoverBackgroundColor: '#a9b3ae',
        data: Object.values(this.props.secondData || {}),
      }]);


    const options = this.props.options;

      return(
        <HorizontalBar height = {400} options = {options} data = {data} />
    );
  }
}
export default HorizontalBarChart;
