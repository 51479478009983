import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CircularProgress, Typography } from '@material-ui/core';
import FindInPage from '@material-ui/icons/FindInPage';
import { getCorporationListSummary } from "../../store/actions";
import CustomInput from '../../components/ui/CustomInput';
import { withI18n } from 'react-i18next';
import CompareUsers from './CompareUsers';
import CompareFilteredUsers from './CompareFilteredUsers';

class Statistics extends Component {
  state = {
    selectedCorporationIndex: -1,
    corporationFilterText: ""
  }

  componentDidMount() {
    if (this.props.authUser.role !== 101 && !this.props.corporations[0]) this.props.getCorporationListSummary().then(_ => this.setState({ getCorpsCompleted: true }));
    else this.setState({getCorpsCompleted:true})
  }
  corporationSelectHandler = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value })
  }

  render() {
    const user_id = this.props.match.params.user_id;
    if(user_id) return <div className="CompareUsers row"><CompareUsers self user_id = {user_id}/></div>
    if(this.props.authUser.role === 101 ) return <CompareFilteredUsers />
    if (this.props.corporationsLoading || !this.state.getCorpsCompleted) return (<Card className="shadow p-4 d-flex justify-content-center "><CircularProgress /></Card>)
    if (!this.props.corporations || !this.props.corporations[0]) {
      if (this.props.authUser.is_family_administrator)
        return (
          <div className="CompareUsers row">
            <CompareUsers family />
          </div>)

      else if (this.state.getCorpsCompleted)
        return (<div className="CompareUsers row"><CompareUsers self /></div>)
    }

    const { selectedCorporationIndex, corporationFilterText } = this.state;
    const { t } = this.props;
    const corporations = this.props.corporations.filter(corporation => corporation.name.toLocaleLowerCase(this.props.lng).includes(this.state.corporationFilterText.toLocaleLowerCase(this.props.lng)));
    const statistics = selectedCorporationIndex > -1 && corporations[selectedCorporationIndex] ? corporations[selectedCorporationIndex].inventory ? <div>Envanter Rapor Ekranı</div> : <CompareUsers corps corporation_id={corporations[selectedCorporationIndex].id} /> : null
    return (
      <div className="CompareUsers row">
        <div className="col-4">
          <Card className="shadow p-3 DivisionSelectContainer">
            <div className="d-flex justify-content-between">
              <Typography variant="h6">{t('corporations')}</Typography>
              <CustomInput
                lefticon={<FindInPage style={{ color: "#7A7A7A" }} />}
                placeholder={t('searchCorporation')}
                value={corporationFilterText}
                onChange={e => this.setState({ corporationFilterText: e.target.value })}
                containerstyle={{ maxWidth: "50%", backgroundColor: "#F0F0F0", borderWidth: 0 }} />
            </div>
            <div>
              {
                corporations.map((corporation, index) => (
                  <div
                    key={corporation.id}
                    className={"DivisionSelect" + (this.state.selectedCorporationIndex === index ? " Active" : "")}
                    onClick={() => this.setState({ selectedCorporationIndex: index })}
                  >
                    <Typography
                      style={{ color: this.state.selectedCorporationIndex === index ? "white" : "#7a7a7a" }}
                      variant="subtitle2">{corporation.name}</Typography>
                    <div>
                      <Typography
                        style={{ color: this.state.selectedDivisionIndex === index ? "white" : "#7a7a7a" }}
                        variant="subtitle2">{corporation.user_count} </Typography>
                    </div>
                  </div>
                ))
              }
            </div>
          </Card>
        </div>
        {statistics}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    corporations: state.corporations.corporationsSummary,
    corporationsLoading: state.loading["CORPORATION_LIST_SUMMARY"],
    authUser: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCorporationListSummary: () => dispatch(getCorporationListSummary())    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withI18n()(Statistics));
