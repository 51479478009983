import React from 'react';


export default  () =>  <div  style={{
    margin:"3em",
    color:"4a4a4a",
    fontSize:".95em",
    textAlign:"justify"
}}> <h4 style={{textAlign:"left"}}>GriCeviz Nedir ? </h4>

        <p>İnsan bilişsel becerilerinin yapı ve içeriği ile ilgili geleneksel iki kuramın ortaklığından oluşan Catteell ortaklığından oluşan <b> Catteell-Horn Carroll </b> Zeka Kuramı temel alınarak hayata geçirilen GriCeviz Bilişsel Beceri ve Zihinsel Gelişim Platformu uygulamasında,bilişsel becerilerin ve zihinsel yeteneklerin değerlendirilmesi ve geliştirilmesini hedefleyen oyunlaştırılmış zihin egzersizleri bulunmaktadır. Güçlü ve zayıf yönlerin tespit edilmesine kılavuzluk eden kişiselleştirilmiş beyin egzersizlerinden oluşan GriCeviz Platformu’nda, <b>6 kategoride </b> (Sözel, Sayısal, Görsel, Mantık, Belek ve Dikkat) <b>24 farklı oyunlaştırılmış zihin egzersizi</b> bulunur.</p>
        <p> GriCeviz’de <b>“Öğrenme kişiye özel bir deneyimdir”</b> prensibi benimsenmiştir ve sahip olduğu teknoloji sayesinde her kullanıcının performansı gözlemlenerek öğrenme karakteristikleri çıkarılır ve böylece her kullanıcıya <b>“kişiye özel”</b> bir deneyim sunulur. GriCeviz’in <b>TÜBİTAK</b> destekli<b>“GriCeviz Yapay Zeka Algoritması”,</b> makine öğrenmesi tekniklerini uygulayarak kullanıcıların öğrenme hızlarını artıracak oyun seçenekleri sunar. Algoritma sayesinde değişik yaş ve eğitim düzeyindeki kullanıcıların yararlanabileceği bir platform olan GriCeviz’in tamamından faydalanılabilmesi için kullanıcının en az okuma-yazma bilmesi önerilmektedir.</p>
        <p><b>ODTÜ-Halıcı Yazılımevi’</b>nde geliştirilen GriCeviz Projesi’nde; <b>Psikoloji, Yapay Zeka, Özel Eğitim, PDR, Ölçme Değerlendirme</b> ve <b> Yazılım</b> alanlarında çalışan bilim insanları, mühendisler, eğitimciler ve zeka oyunları yarışmalarında üstün başarılar göstermiş yarışmacılardan oluşan geniş bir ekibin emekleri bulunuyor.<b>Emrehan Halıcı’</b> nın 40 yılı aşkın süredir hazırladığı Akıl Oyunları Soruları ve Zeka Oyunları yarışmalarından edindiği deneyimler ışığında geliştirilen GriCeviz, yurt dışında da <b>“Brainquire”</b> adıyla kullanıma sunulmuştur.</p>
</div> // about anasayfası //