import React from 'react';
import { Button } from '@material-ui/core';
import './style.css';

export default ({children, message, onClick, hiden}) => (<>
  {hiden? <div className="HideContentContainer">
    <div className="Modal">
        <Button variant="contained" color="primary" onClick={onClick}>{message}</Button>
    </div>
    {children}
  </div>: children}
</>);