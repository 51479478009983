import {
    GET_INVENTORY_COUPONS_REQUEST,
    GET_INVENTORY_COUPONS_SUCCESS,
    GET_INVENTORY_COUPONS_FAIL,
    DEACTIVATE_INVENTORY_COUPON_REQUEST,
    DEACTIVATE_INVENTORY_COUPON_SUCCESS,
    DEACTIVATE_INVENTORY_COUPON_FAIL,
    CREATE_INVENTORY_COUPON_REQUEST,
    CREATE_INVENTORY_COUPON_SUCCESS,
    CREATE_INVENTORY_COUPON_FAIL,
  } from "./actionTypes";
  import axios from "../../axios-instance";
  import api from "../../api";
    
  const inventoryCouponsRequest = () => ({
    type: GET_INVENTORY_COUPONS_REQUEST,
  });
  
  const inventoryCouponsSuccess = (coupons) => ({
    type: GET_INVENTORY_COUPONS_SUCCESS,
    coupons,
  });
  
  const inventoryCouponsFail = () => ({
    type: GET_INVENTORY_COUPONS_FAIL,
  });
  
  const deactivateInventoryCouponRequest = () => ({
    type: DEACTIVATE_INVENTORY_COUPON_REQUEST,
  });
  
  const deactivateInventoryCouponSuccess = (coupon) => ({
    type: DEACTIVATE_INVENTORY_COUPON_SUCCESS,
    coupon,
  });
  
  const deactivateCInventoryCouponFail = () => ({
    type: DEACTIVATE_INVENTORY_COUPON_FAIL,
  });
  
  const createInventoryCouponRequest = () => ({
    type: CREATE_INVENTORY_COUPON_REQUEST,
  });
  
  const createInventoryCouponSuccess = (coupon) => ({
    type: CREATE_INVENTORY_COUPON_SUCCESS,
    coupon,
  });
  
  const createInventoryCouponFail = () => ({
    type: CREATE_INVENTORY_COUPON_FAIL,
  });
  
  
  
  export const deactivate_inventory_coupon = (coupon_id) => (dispatch, getState) => {
    dispatch(deactivateInventoryCouponRequest());
    return axios
      .post(api.deactivate_inventory_coupon, {
        coupon_id,
        token: getState().auth.token,
      })
      .then((res) => {
        dispatch(deactivateInventoryCouponSuccess(res.data));
        return res.data;
      })
      .catch((error) => {
        dispatch(deactivateCInventoryCouponFail());
      });
  };
  
  export const create_inventory_coupon = (coupon) => (dispatch, getState) => {
    dispatch(createInventoryCouponRequest());
    return axios
      .post(api.create_inventory_coupon, {
        code: coupon.code,
        value: coupon.value,
        percent: coupon.percent,
        source: coupon.source,
        source_id: coupon.source_id,
        valid_from: coupon.valid_from,
        valid_till: coupon.valid_till,
        use_count: coupon.use_count,
        token: getState().auth.token,
      })
      .then((res) => {
  
        dispatch(createInventoryCouponSuccess(res.data));
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        dispatch(createInventoryCouponFail());
      });
  };
  
  
  export const get_inventory_coupons = (filters) => (dispatch, getState) => {
    dispatch(inventoryCouponsRequest());
    return axios
      .get(api.filter_inventory_coupons, {
        params: { filters, token: getState().auth.token },
      })
      .then((res) => {
        dispatch(inventoryCouponsSuccess(res.data.coupons));
        return res.data.coupons;
      })
      .catch((error) => {
        dispatch(inventoryCouponsFail());
      });
  };
  