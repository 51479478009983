import React from "react";

export default () => (
  <div>
    <h4> CHC Kuramı </h4>
    <p>
      Hepimiz dünyaya gerek bedensel gerekse de zihinsel olarak belirli bir
      kapasiteyle/ gizil güçle geliriz. Doğuştan getirdiğimiz bedensel
      yatkınlıklarımız ne kadar sağlıklı bir yaşam sürebileceğimiz, hangi
      hastalıklara yatkın olabileceğimiz ya da hangi spor dalında daha başarılı
      olabileceğimiz gibi konularda genel bir çerçeve çizer. Benzer bir biçimde
      dikkat, bellek, akıl yürütme ya da planlama gibi birçok zihinsel
      yeteneğimizde doğuştan getirilen gizil bir potansiyele sahiptir. Ancak,
      hem fiziksel hem de zihinsel gizil güçlerimizin ne ölçüde ve ne biçimde
      hayata geçebileceği ilgili yeteneklere yapılan kişisel, ailesel ve
      toplumsal yatırım tarafından belirlenir. Zaman içinde yatırım yapılan
      yetenekler korunup gelişirken, ifade şansı bulamayanların gelişimi durur
      ve körelir. Özellikle çocukluğun ilk yılları bu potansiyellerin yaşama
      geçirilmesi için oldukça önemlidir. Yıllar içinde egzersizlerle beslenen
      yetenekler güçlenir ve gelişir. Otuz ile kırk yaş aralığına gelindiğinde
      yeteneklerin gelişimi durur ve kayıplar başlar. İlerleyen yaşla beraber
      kayıpların miktarı da artar. Bu aşamada da egzersizler kayıpları azaltıp
      sağlıklı yaşlanmaya olanak tanıyan önemli koruyucu etkenler haline gelir.
    </p>
    <p>
      Bedensel egzersizlerin tüm kas sistemlerini dengeli biçimde çalıştırması
      gerektiği gibi zihinsel egzersizlerinde farklı yetenek gruplarını orantılı
      biçimde çalıştırması gerekmektedir. Yeteneklerin hali hazırdaki düzeyleri
      doğru olarak tespit edilmeli ve düzeyine uygun egzersizle
      çalıştırılmalıdır. Genel olarak bakıldığında geleneksel zihinsel egzersiz
      programları dikkat, bellek, sözel, görsel ve sayısal işlemler gibi oldukça
      kapsayıcı ya da genel zihinsel süreçlere odaklanmaktadır. Ancak, bu
      süreçlerin altında çalışan daha özgün ve görgül olarak ayrıştırılabilecek
      yetenekler yer almaktadır. Çağdaş bir yaklaşım olan CHC kuramı bu
      yetenekleri sınıflamak ve aralarındaki ilişkileri modelleye bilmek için
      oldukça iyi bir kuramsal çerçeve sunmaktadır. Gri ceviz kapsamında
      geliştirilen mental egzersiz uygulamaları bir yandan dikkat ve bellek gibi
      temel süreçler bağlamında tanımlanırken aynı zamanda çalıştırdıkları özgün
      yetenekler ya da yetenek grupları CHC kuramı kapsamında sınıflanmaktadır.
      Bu ikili sınıflamanın bilimsel olarak çok daha güçlü bir egzersiz programı
      kurgulanmasına olanak sağlamaktadır.
    </p>
    <p>
      McGrew, Cattell-Horn’un Akıcı-Kristalize (Gf-Gc) Zekâ Modeli ve Carroll’un
      üç katmanlı zekâ modelini bütünleştirerek Cattell-Horn-Carroll Bilişsel
      Beceriler Modelini oluşturmuştur. CHC Modeli, Flanagan tarafından gözden
      geçirilerek genişletilmiştir. CHC Modelinde önerilen hiyerarşik yapılanma
      Carroll’un üç katmanlı zekâ modeline oldukça benzemektedir. Hiyerarşinin
      en üstünde küçük “g” harfiyle ifade edilen genel zeka faktörü
      bulunmaktadır. En alt katmanda görgül olarak tanımlanan 70’in üzerinde
      sınırlı bilişsel yeteneğe yer verilmiştir. Orta katmanda ise başta
      Cattell- Horn’un Gf-Gc becerileri olmak üzere büyük “G” harfiyle ifade
      edilen sınırlı sayıdaki geniş̧ yetenek alanı yer almaktadır. CHC Kapsamında
      bu güne kadar tanımlanmış geniş yetenek alanları aşağıda sunulmuştur.
    </p>
      <ol>
        <li>Kristalize Zeka Gc (Crystallized Intelligence)</li>
        <li>Akıcı Zeka Gf (Fluid Intelligence)</li>
        <li>Niteliksel Akıl Yürütme Gq (Quantitatve Reasoning</li>
        <li>Okuma ve Yazma Becerisi Grw (Reading & Writing </li>
        <li>Kısa Süreli Bellek Gsm (Short-Term Memory)</li>
        <li>
          Uzun Süreli Depolama ve Geri Çağırma Glr (Long-Term Storage And
          Retrieval)
        </li>
        <li>Görsel İşlemleme Gv (Visual Processing)</li>
        <li>İşitsel İşlemleme Ga (Auditory Processing)</li>
        <li>İşlem Hızı Gs (Processing Speed)</li>
        <li>Karar/Tepki Süresi/ Hız Gt (Decision/Reaction Time/Speed)</li>
        <li>Genel Bilgi Gkn (General Knowledge)</li>
        <li>Dokunsal Yetenekler Gh (Tactile abilities) </li>
        <li>Kinestetik Yetenekler Gk (Kinesthetic Abilities) </li>
        <li>Koku alma yetenekleri Go (Olfactory Abilities)</li>
        <li>Psikomotor Hız Gps (Psikomotor speed)</li>
      </ol>
    <p>
      Bu yeteneklerden ilk onu üzerinde en fazla çalışılmış olanlardır. Geniş
      yetenek alanlarına ilişkin sınıflamalar yeni çalışmaların ışığında
      zenginleşmeye devam etmektedir.
    </p>
      <h3> Akıcı Zekâ (GF) </h3>
    <p>
      Akıcı Zekâ kişinin kendisi için yeni olan bir malzeme ya da süreçte
      çıkarım yaparken ki tümden gelimci ya da tüme varımcı akıl yürütmelerine
      işaret eder. İlişkili sınırlı yeteneklerden bazıları şunlardır:
    </p>
      <ul>
        <li>Sıralı Akıl Yürütme (RG)AKICI ZEKÂ (GF)</li>
        <li>Niceliksel Akıl Yürütme (RQ) </li>
        <li>Tümevarım (I)</li>
      </ul>
      <h3>Kristalize Zekâ(GC) </h3>
    <p>
      Temel olarak sözel ya da dil temelli bilgi deposudur. Büyük ölçüde eğitsel
      deneyimler ve genel yaşam deneyimleri sırasında diğer yeteneklerin
      yatırımı aracılığıyla geliştirilen yetenekleri temsil eder.
    </p>
      <ul>
        <li>Dil Gelişimi (LD) </li>
        <li>Sözcük Dağarcığı (VL)</li>
        <li>Dinleme Yeteneği (LS)</li>
        <li>Genel Sözel Bilgi (K0)</li>
        <li>Genel Kültür (K2)</li>
      </ul>
      <h3>Niceliksel Akıl Yürütme (GQ)</h3>
    <p>
      Niceliksel Akıl Yürütme, Matematiksel Bilgi birikimi (Mathematical
      Knowledge) ve Matematik Başarısını (Mathematical Achievement)
      içermektedir. Nicel bilgileri kullanma ve sayısal simgeleri işleme
      yeteneğini temsil eder.
    </p>
      <ul>
        <li>Matematiksel Bilgi (KM)</li>
        <li>Matematiksel Başarım (A3)</li>
      </ul>
      <h3>Kısa Süreli Bellek (GSM)</h3>
    <p>
      Kısa Süreli Bellek bilgiyi anlık farkındalık düzeyinde yakalayıp tutma ve
      ardından kısa bir süre içinde kullanabilme yeteneğidir.
    </p>
      <ul>
        <li>Anlık Bellek Boyutu (MS)</li>
        <li>Çalışma Belleği (WM)</li>
      </ul>
      <h3>Uzun Süreli Depolama ve Geri Çağırma (GLR)</h3>
    <p>
      Uzun Süreli Depolama ve Geri Çağırma bilgiyi uzun erimli bellekte depolama
      ya da yeni veya önceden edinilmiş bilgiyi (kavram, fikir, konu, ad) uzun
      süreli bellekten akıcı bir şekilde geri çağırma yeteneğidir.
    </p>      
      <ul>
        <li>Çağrışımsal Bellek (MA)</li>
        <li>Anlamlı Bellek (MM):</li>
        <li>Serbest Hatırlama (M6)</li>
        <li>Düşünsel Akıcılık (FI)</li>
        <li>İlişkisel Akıcılık (FA)</li>
        <li>Anlatım Akıcılığı (FE)</li>
      </ul>
      <h3>Görsel İşlemleme (GV)</h3>
    <p>
      Görsel İşlemleme, görsel örüntü ya da uyaranları üretme, algılama,
      çözümleme, sentezleme, depolama, geri çağırma, değiştirme, dönüştürme ve
      onlarla düşünme yeteneğidir.
    </p>
      <ul>
        <li>Görsel Bellek (MV)</li>
        <li>Uzamsal İlişkiler (SR)</li>
        <li>Kapama Hızı (CS)</li>
        <li>Görselleştirme (Vz)</li>
      </ul>
      <h3>İşitsel İşlemleme (Ga) </h3>
    <p>
      İşitsel işlemleme, işitsel uyarınca arasındaki örtüleri algılama,
      çözümleme ve sentezleme; ses örtüleri arasındaki güç algılanan ince
      ayrımları (örn, karmaşık müzikal yapılar) ve karıştırıcı uyaranların
      varlığı durumunda konuşmaları ayırt edebilme yeteneğidir.
    </p>
      <ul>
        <li>Fonetik Kodlama-Analiz (PC:A) </li>
        <li>Fonetik Kodlama-Sentez (PC:S)</li>
        <li>Konuşma Sesi Ayrımsama (US)</li>
      </ul>
      <h3>İşlem Hızı (Gs) </h3>
    <p>
      İşlem Hızı, özellikle odaklanmış dikkat (focused attention) ve
      konsantrasyonu korumak için baskı altındayken, bir bilişsel görevi akıcı
      ve otomatik olarak gerçekleştirme yeteneğidir.
    </p>
      <ul>
        <li>Algısal Hız (P)</li>
        <li>Test Alma Düzeyi (R9)</li>
      </ul>
      <h3>Karar/Tepki Süresi/Hızı (Gt)</h3>
    <p>
      Karar/Tepki Süresi/Hız doğrulukla tepki verme ya da karar alma hızıdır.
    </p>
      <ul>
        <li>Basit Tepki Süresi (R1)</li>
        <li>Zihinsel Karşılaştırma Hızı (R7)</li>
      </ul>
      
      <h3>Okuma ve Yazma Becerisi (GRW)</h3>
    <p>
      Cattle ve Horn tarafından Kristalize Zekânın (Gc) bir parçası olarak
      sınıflanmıştır. Carroll tarafından bir yetenek alanından çok bir başarım
      alanı olarak kabul edilmiştir. CHC kuramında ise bağımsız bir yetenek
      olarak sınıflanmaktadır.
    </p>
      <ul>
        <li>
          Okurken Kod açma (RD)Bedensel egzersizlerin tüm kas sistemlerini
          dengeli biçimde çalıştırması gerektiği gibi zihinsel egzersizlerinde
          farklı yetenek gruplarını orantılı biçimde çalıştırması gerekmektedir.
          Yeteneklerin hali hazırdaki düzeyleri doğru olarak tespit edilmeli ve
          düzeyine uygun egzersizle çalıştırılmalıdır. Genel olarak bakıldığında
          geleneksel zihinsel egzersiz programları dikkat, bellek, sözel, görsel
          ve sayısal işlemler gibi oldukça kapsayıcı ya da genel zihinsel
          süreçlere odaklanmaktadır. Ancak, bu süreçlerin altında çalışan daha
          özgün ve görgül olarak ayrıştırılabilecek yetenekler yer almaktadır.
          Çağdaş bir yaklaşım olan CHC kuramı bu yetenekleri sınıflamak ve
          aralarındaki ilişkileri modelleye bilmek için oldukça iyi bir kuramsal
          çerçeve sunmaktadır. Gri ceviz kapsamında geliştirilen mental egzersiz
          uygulamaları bir yandan dikkat ve bellek gibi temel süreçler
          bağlamında tanımlanırken aynı zamanda çalıştırdıkları özgün yetenekler
          ya da yetenek grupları CHC kuramı kapsamında sınıflanmaktadır. Bu
          ikili sınıflamanın bilimsel olarak çok daha güçlü bir egzersiz
          programı kurgulanmasına olanak sağlamaktadır.
        </li>
        <li> Okurken Kavrama (RC)</li>
        <li>Okuma Hızı (RS)</li>
      </ul>
  </div>
);
