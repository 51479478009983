import React, {Component} from 'react';
import {
  Grid,
  Table,
  TableBandHeader,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

class BandedColumnTable extends Component {
  constructor(props){
    super(props);
    this.state = {}
  }

  rowComponent = (props) => (<Table.Row {...props}/>)

  tableCellComponent = (props) => (<Table.Cell {...props}/>)

  tableHeaderCellComponent = (props) => (<TableHeaderRow.Cell {...props}/>)

  render(){
    let rows = this.props.rows || []
    let columns = this.props.columns || []
    return(
      <Grid
        rows={rows}
        columns={columns}
      >
        {this.props.dataTypeProvider}
        <Table
          rowComponent = {this.props.rowComponent || this.rowComponent}
          cellComponent = {this.props.tableCellComponent || this.tableCellComponent}
          columnExtensions = {this.props.columnExtensions || null}
        />
        <TableHeaderRow
          cellComponent = {this.props.tableHeaderCellComponent  || this.tableHeaderCellComponent}
        />
        <TableBandHeader
          columnBands ={this.props.columnBands}
          cellComponent = {this.props.bandHeaderCellComponent}
        />
      </Grid>
    )
  }
}

export default BandedColumnTable;
