import React from 'react';

export default function UserDataDeletionPage() {
    return <div style={{padding:50}}>
        <h3>User Data Deletion / Kullanıcı Verilerini Silme</h3>
        <h5>English</h5>
        <p>When you use the “Login with Facebook” option, your Facebook account is used to log in to the Brainquire application. Brainquire does not save your Facebook personal data on its server. You can follow the steps below to disconnect the Brainquire application from your Facebook account and have this information deleted:</p>
        <ol>
            <li>Go to your Facebook Account’s Settings and Privacy. Click “Settings”</li>
            <li>Look for “Apps and Websites” and you will see all of the apps and websites you linked with your Facebook.</li>
            <li>Search and Click “Brainquire” in the search bar.</li>
            <li>Scroll and click “Remove”.</li>
            <li>Congratulations, you have now deleted the Brainquire application and your Facebook account connection and application activities from the system.</li>
            <li>If you want your game data stored anonymously on application servers for statistical purposes to be deleted, you can send an e-mail to info@brainquire.com</li>
        </ol>

        <h5>Türkçe</h5>
        <p>“Facebook İle Giriş” seçeneğini kullandığınızda Facebook hesabınız Griceviz uygulamasına giriş için kullanılmaktadır. Griceviz sunucularında Facebook hesabınıza ait kişisel bilgiler depolanmamaktadır. Griceviz uygulamasının Facebook hesabınız ile ilişkisini kesmek ve bu bilgilerin silinmesini sağlamak için aşağıdaki adımları izleyebilirsiniz:</p>
        <ol>
            <li>Facebbok sayfanıza gidin, “Ayarlar ve Gizlilik” sekmesinden “Ayarlar” bölümünü açın.</li>
            <li>“Uygulamalar ve İnternet Siteleri” menüsünden Facebook hesabınızla ilişkilendirilmiş uygulamaları görebilirsiniz.</li>
            <li>Uygulama listesinden “Griceviz” yanındaki “Kaldır” butonuna basın.</li>
            <li>Tebrikler, artık Griceviz uygulaması ile Facebook hesabınızın bağlantısını ve uygulama aktivitelerinizi sistemden sildiniz.</li>
            <li>Uygulama sunucularında istatistiksel amaçlarla anonim olarak saklanan oyun verilerinezin de silinmesini istiyorsanız info@griceviz.com adresine konu hakkında e-posta gönderebilirsiniz.</li>
        </ol>

    </div>

}