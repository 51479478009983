import React from 'react';
import { Typography, LinearProgress } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import Logo from '../../../assets/images/logo.png'
import CategoriesChart from '../CategoriesChart';
import ReportSimpleList from '../ReportSimpleList';
import OrderByCategoryList from '../OrderByCategoryList';
import ReportDescriptions from '../ReportDescriptions';
import 'moment/locale/tr';
import moment from 'moment';
 import { print } from '../../../utils/pdf';
import '../style.css';
import ScoreByWeekChart from '../ScoreByWeekChart';

moment.locale('tr');
const DivisionReport = ({ loading, corporation, division, data, t }) => {

  // const divData = corporation.divisions.map(div => ({
  //   name: div.name,
  //   user_count: div.users.length,
  //   admins: div.admins.map(admin => `${admin.first_name} ${admin.last_name}`).join(" ")
  // }));
  // const corpAdmins = corporation.admins.map(admin => ({ name: `${admin.first_name} ${admin.last_name}` }));
  
  const corpPlayCountData = data && Object.keys(data.game_counts.today).map(category => ({
    category: t(`category_games.${category}.text`),
    last7: data.game_counts.last7[category],
    last30: data.game_counts.last30[category],
    today: data.game_counts.today[category]
  }));

  const score_by_date = data && data.score_by_date;

  return (
    <div className="Report" style={{
      width: 1024,
      margin: "auto",
      border: "2px solid #7f7f7f",
      padding: "1em 3em",
      fontSize: ".8em",
      backgroundColor: "white",
      overflow: "auto",
      fontFamily: "Roboto!important"
    }}>
      {loading ? <LinearProgress /> :
        !data ? <div>REPORT DATA NOT FOUND</div> :
          <div className="ReportContainer" style={{

          }}>
            <button onClick={() => print({}, ['toPDF'])}>PDF indir</button>
            <div id="toPDF" style={{
              padding: "1em 3em"
            }}>
              <div style={{
                textAlign: "left",
                fontWeight: "bold!important",
                fontStyle: "'Roboto'!important",
                fontSize:".8em!important"
              }}>
                <Typography variant="h5">BÖLÜM RAPORU</Typography>
                <span style={{
                  fontSize: ".8em",
                  color: "#9698AA"

                }}>{moment(new Date()).format("D MMMM Y dddd HH:mm")}</span><br />
              </div>
              <div>
                <img src={Logo} className="Logo" width="30%" style={{
                  marginTop: "2em",
                  marginLeft: "2em"
                }} />
                <div style={{ float: "right" }}>
                  <Typography variant="h6">KURUM</Typography>
                  <table style={{
                    fontWeight: "bold",
                    fontSize: ".8em"
                  }}>
                    <tbody>
                      <tr><td>Üyelik Başlangıç Tarihi</td><td>:</td><td>{corporation.start_at}</td></tr>
                      <tr><td>Üyelik Bitiş Tarihi</td><td>:</td><td>{corporation.end_at}</td></tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2em"
              }}>
                <img src={corporation.image}
                  width="20%"
                  className="CorpImage"
                  style={{
                    marginRight: "2em"
                  }}
                />
                <table style={{ marginLeft: "2em" }}>
                  <tbody>
                    <tr>
                      <td>BÖLÜM ADI</td>
                      <td>:</td>
                      <td>{division.name}</td>
                    </tr>
                    <tr>
                      <td>BÖLÜM MEVCUDU</td>
                      <td>:</td>
                      <td>{division.users.length}</td>
                    </tr>
                    <tr>
                      <td>BAĞLI OLDUĞU KURUM</td>
                      <td>:</td>
                      <td>{corporation.name}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <ReportDescriptions />
              {/* <Typography variant="h5" style={{
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "2em!important"
              }}>BÖLÜM HAKKINDA</Typography>
              <div style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "2em",
                marginBottom: "2em",
                alignItems: "stretch",
                pageBreakAfter:"always"
              }}>
                <div>
                  <ReportSimpleList 
                    labels={[{ text: "Bölüm Adı", for: "name" }, { text: "Bölüm Mevcudu", for: "user_count" }, { text: "Yönetici", for: "admins" }]} 
                    data={divData} />
                </div>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between"
                }}>
                  <table border="1" style={{
                    border: "1px solid rgba(224, 224, 224, 1)"
                  }}>
                    <tbody>
                      <tr>
                        <th>KURUM ÜYELİK BAŞLANGIÇ</th>
                        <td>{corporation.start_at}</td>
                      </tr>
                      <tr>
                        <th>KURUM ÜYELİK BİTİŞ</th>
                        <td>{corporation.end_at}</td>
                      </tr>
                    </tbody>
                  </table>
                  <ReportSimpleList labels={[{ text: "Yöneticiler", for: "name" }]} data={corpAdmins} />
                </div>
              </div> */}
              <Typography variant="h5" style={{
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "2em!important"
              }}>KATEGORİ BAZINDA AĞIRLIKLI PUAN DAĞILIMI</Typography>
              <ScoreByWeekChart data={score_by_date} />
              <CategoriesChart style={{pageBreakBefore:"always"}} data={Object.values(data.average)} labels={Object.keys(data.average).map(item => t(`category_games.${item}.text`))} />
              <Typography variant="body2" style={{ marginBottom: "2em" }}>*Ağırlıklı Puan Dağılımı radar grafiği, sınıfın/bölümün hangi alanlarda eğilimli olduğunu; gelişime açık olduğu alanları ve üstünlük ile eksiklikleri çıkarımlarının yapılabileceği bir gösterim biçimidir. Sınıf/Bölümün ağırlıklı puan dağılımı, kurumun ağırlıklı puan dağılımı ile karşılaştırmalı olarak gösterilmektedir.</Typography>
              <Typography variant="h5" style={{
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "10px",
                marginBottom: ".5em",
                pageBreakBefore:"always",
              }}>KATEGORİ BAZINDA AĞIRLIKLI PUAN SIRALAMALARI</Typography>
              <Typography variant="body2">*Kategori bazında ağırlıklı puanıyla ilk 3'de yer alan kullanıcılar listelenmektedir.</Typography>
              <div className="row" style={{
                pageBreakAfter:"always"
              }}>
                {
                  Object.keys(data.by_category).map((category, i) => (
                    <div className="col-sm-6 p-1" key={i} >
                      <ReportSimpleList 
                        labels={[{ text: "Sıra", for: "order" }, { text: "Kullanıcı", for: "user_name" }, { text: "Puan", for: "score" }]} 
                        data={data.by_category[category]} 
                        img={`/images/categories/${category}.png`} 
                        title={t(`category_games.${category}.text`)} 
                        />
                    </div>
                  ))
                }
              </div>
              <Typography variant="h5" style={{
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "2em"
              }}>OYUN BAZINDA AĞIRLIKLI PUAN SIRALAMALARI</Typography>
              <div style={{ marginTop: ".5em", marginBottom: "2em" }}>
                <OrderByCategoryList labels={[{ text: "Kategori", for: "game_category_uid" }, { text: "Oyun", for: "game_uid" }, { text: "Kullanıcı", for: "user_name" }, { text: "Puan", for: "score" }]} data={data.by_game} />
              </div>
              <Typography variant="h5" style={{
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "2em!important",
                marginBottom: "2em"
              }}>KATEGORİ BAZINDA OYNAMA SAYILARI</Typography>
              <ReportSimpleList labels={[{ text: "Kategori", for: "category" }, { text: "Bugün", for: "today" }, { text: "Son 7 Gün", for: "last7" }, { text: "Son 30 Gün", for: "last30" }]} data={corpPlayCountData} />
            </div></div>}
    </div>)
};


export default withNamespaces('common')(DivisionReport)