//auth actions
export const SIGN_IN_REQUEST = "SIGN_IN_REQUEST";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAIL = "SIGN_IN_FAIL";

export const FB_SIGN_IN_REQUEST = "FB_SIGN_IN_REQUEST";
export const FB_SIGN_IN_SUCCESS = "FB_SIGN_IN_SUCCESS";
export const FB_SIGN_IN_FAIL = "FB_SIGN_IN_FAIL";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const SIGN_OUT = "SIGN_OUT";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

//users actions
export const USERS_REQUEST = "USERS_REQUEST";
export const USERS_SUCCESS = "USERS_SUCCESS";
export const USERS_FAIL = "USERS_FAIL";

export const USERS_TABLE_REQUEST = "USERS_TABLE_REQUEST";
export const USERS_TABLE_SUCCESS = "USERS_TABLE_SUCCESS";
export const USERS_TABLE_FAIL = "USERS_TABLE_FAIL";

export const USER_REQUEST="USER_REQUEST";
export const USER_SUCCESS="USER_SUCCESS";
export const USER_FAIL="USER_FAIL";

export const TRANSACTION_HISTORY_REQUEST = "TRANSACTION_HISTORY_REQUEST";
export const TRANSACTION_HISTORY_SUCCESS = "TRANSACTION_HISTORY_SUCCESS";
export const TRANSACTION_HISTORY_FAIL = "TRANSACTION_HISTORY_FAIL";

export const ADMIN_NOTES_REQUEST = "ADMIN_NOTES_REQUEST";
export const ADMIN_NOTES_SUCCESS = "ADMIN_NOTES_SUCCESS";
export const ADMIN_NOTES_FAIL = "ADMIN_NOTES_FAIL";

export const ADMIN_NOTE_UPDATE_REQUEST = "ADMIN_NOTE_UPDATE_REQUEST";
export const ADMIN_NOTE_UPDATE_SUCCESS = "ADMIN_NOTE_UPDATE_SUCCESS";
export const ADMIN_NOTE_UPDATE_FAIL = "ADMIN_NOTE_UPDATE_FAIL";

export const ADMIN_NOTE_DELETE_REQUEST = "ADMIN_NOTE_DELETE_REQUEST";
export const ADMIN_NOTE_DELETE_SUCCESS = "ADMIN_NOTE_DELETE_SUCCESS";
export const ADMIN_NOTE_DELETE_FAIL = "ADMIN_NOTE_DELETE_FAIL";

export const ADMIN_NOTE_ADD_REQUEST = "ADMIN_NOTE_ADD_REQUEST";
export const ADMIN_NOTE_ADD_SUCCESS = "ADMIN_NOTE_ADD_SUCCESS";
export const ADMIN_NOTE_ADD_FAIL = "ADMIN_NOTE_ADD_FAIL";

export const INVITE_TO_FAMILY_REQUEST = "INVITE_TO_FAMILY_REQUEST";
export const INVITE_TO_FAMILY_SUCCESS = "INVITE_TO_FAMILY_SUCCESS";
export const INVITE_TO_FAMILY_FAIL = "INVITE_TO_FAMILY_FAIL";

export const REMOVE_FROM_FAMILY_REQUEST = "REMOVE_FROM_FAMILY_REQUEST";
export const REMOVE_FROM_FAMILY_SUCCESS = "REMOVE_FROM_FAMILY_SUCCESS";
export const REMOVE_FROM_FAMILY_FAIL = "REMOVE_FROM_FAMILY_FAIL";

export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";

export const SEARCH_USERS_REQUEST = "SEARCH_USERS_REQUEST";
export const SEARCH_USERS_SUCCESS = "SEARCH_USERS_SUCCESS";
export const SEARCH_USERS_FAIL = "SEARCH_USERS_FAIL";

export const EDIT_USER_REQUEST = "EDIT_USER_REQUEST";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAIL = "EDIT_USER_FAIL";

export const GET_PROFILE_CARD_GAME_SUMMARY_REQUEST = "GET_PROFILE_CARD_GAME_SUMMARY_REQUEST";
export const GET_PROFILE_CARD_GAME_SUMMARY_SUCCESS = "GET_PROFILE_CARD_GAME_SUMMARY_SUCCESS";
export const GET_PROFILE_CARD_GAME_SUMMARY_FAIL = "GET_PROFILE_CARD_GAME_SUMMARY_FAIL";

export const PLAY_LIMIT_REQUEST = "PLAY_LIMITS_REQUEST";
export const PLAY_LIMIT_SUCCESS = "PLAY_LIMITS_SUCCESS";
export const PLAY_LIMIT_FAIL = "PLAY_LIMITS_FAIL";

export const RESTRICT_GAMES_REQUEST = 'RESTRICT_GAMES_REQUEST';
export const RESTRICT_GAMES_SUCCESS = 'RESTRICT_GAMES_SUCCESS';
export const RESTRICT_GAMES_FAIL = 'RESTRICT_GAMES_FAIL';

//corporation actions
export const CORPORATION_LIST_FOR_FILTER_REQUEST = "CORPORATION_LIST_FOR_FILTER_REQUEST";
export const CORPORATION_LIST_FOR_FILTER_SUCCESS = "CORPORATION_LIST_FOR_FILTER_SUCCESS";
export const CORPORATION_LIST_FOR_FILTER_FAIL = "CORPORATION_LIST_FOR_FILTER_FAIL";

export const CORPORATION_LIST_SUMMARY_REQUEST = "CORPORATION_LIST_SUMMARY_REQUEST";
export const CORPORATION_LIST_SUMMARY_SUCCESS = "CORPORATION_LIST_SUMMARY_SUCCESS";
export const CORPORATION_LIST_SUMMARY_FAIL = "CORPORATION_LIST_SUMMARY_FAIL";

export const CORPORATION_DETAILS_REQUEST = "CORPORATION_DETAILS_REQUEST";
export const CORPORATION_DETAILS_SUCCESS = "CORPORATION_DETAILS_SUCCESS";
export const CORPORATION_DETAILS_FAIL = "CORPORATION_DETAILS_FAIL";

export const ADD_CORPORATION_REQUEST = "ADD_CORPORATION_REQUEST";
export const ADD_CORPORATION_SUCCESS = "ADD_CORPORATION_SUCCESS";
export const ADD_CORPORATION_FAIL = "ADD_CORPORATION_FAIL";

export const DELETE_CORP_ADMIN_REQUEST = "DELETE_CORP_ADMIN_REQUEST";
export const DELETE_CORP_ADMIN_SUCCESS = "DELETE_CORP_ADMIN_SUCCESS";
export const DELETE_CORP_ADMIN_FAIL = "DELETE_CORP_ADMIN_FAIL";

export const CREATE_CORP_ADMIN_REQUEST = "CREATE_CORP_ADMIN_REQUEST";
export const CREATE_CORP_ADMIN_SUCCESS = "CREATE_CORP_ADMIN_SUCCESS";
export const CREATE_CORP_ADMIN_FAIL = "CREATE_CORP_ADMIN_FAIL";

export const DELETE_CORP_DIV_ADMIN_REQUEST = "DELETE_CORP_DIV_ADMIN_REQUEST";
export const DELETE_CORP_DIV_ADMIN_SUCCESS = "DELETE_CORP_DIV_ADMIN_SUCCESS";
export const DELETE_CORP_DIV_ADMIN_FAIL = "DELETE_CORP_DIV_ADMIN_FAIL";

export const CREATE_CORP_DIV_ADMIN_REQUEST = "CREATE_CORP_DIV_ADMIN_REQUEST";
export const CREATE_CORP_DIV_ADMIN_SUCCESS = "CREATE_CORP_DIV_ADMIN_SUCCESS";
export const CREATE_CORP_DIV_ADMIN_FAIL = "CREATE_CORP_DIV_ADMIN_FAIL";

export const DELETE_CORP_DIV_USER_REQUEST = "DELETE_CORP_DIV_USER_REQUEST";
export const DELETE_CORP_DIV_USER_SUCCESS = "DELETE_CORP_DIV_USER_SUCCESS";
export const DELETE_CORP_DIV_USER_FAIL = "DELETE_CORP_DIV_USER_FAIL";

export const CREATE_CORP_DIV_USER_REQUEST = "CREATE_CORP_DIV_USER_REQUEST";
export const CREATE_CORP_DIV_USER_SUCCESS = "CREATE_CORP_DIV_USER_SUCCESS";
export const CREATE_CORP_DIV_USER_FAIL = "CREATE_CORP_DIV_USER_FAIL";

export const GET_DIV_NAMES_AND_COUNTS_REQUEST  = "GET_DIV_NAMES_AND_COUNTS_REQUEST";
export const GET_DIV_NAMES_AND_COUNTS_SUCCESS  = "GET_DIV_NAMES_AND_COUNTS_SUCCESS";
export const GET_DIV_NAMES_AND_COUNTS_FAIL  = "GET_DIV_NAMES_AND_COUNTS_FAIL";

export const EDIT_CORPORATION_INFO_REQUEST = "EDIT_CORPORATION_INFO_REQUEST";
export const EDIT_CORPORATION_INFO_SUCCESS = "EDIT_CORPORATION_INFO_SUCCESS";
export const EDIT_CORPORATION_INFO_FAIL = "EDIT_CORPORATION_INFO_FAIL";

export const ADD_DIVISION_REQUEST = "ADD_DIVISION_REQUEST";
export const ADD_DIVISION_SUCCESS = "ADD_DIVISION_SUCCESS";
export const ADD_DIVISION_FAIL = "ADD_DIVISION_FAIL";

export const EDIT_DIVISION_REQUEST = "EDIT_DIVISION_REQUEST";
export const EDIT_DIVISION_SUCCESS = "EDIT_DIVISION_SUCCESS";
export const EDIT_DIVISION_FAIL = "EDIT_DIVISION_FAIL";

export const DELETE_DIVISION_REQUEST = "DELETE_DIVISION_REQUEST";
export const DELETE_DIVISION_SUCCESS = "DELETE_DIVISION_SUCCESS";
export const DELETE_DIVISION_FAIL = "DELETE_DIVISION_FAIL";

export const GET_DIVS_AND_USERS_REQUEST = "GET_DIVS_AND_USERS_REQUEST";
export const GET_DIVS_AND_USERS_SUCCESS = "GET_DIVS_AND_USERS_SUCCESS";
export const GET_DIVS_AND_USERS_FAIL = "GET_DIVS_AND_USERS_FAIL";


export const CREATE_CORP_PRIVILAGE_CHECK_REQUEST = "CREATE_CORP_PRIVILAGE_CHECK_REQUEST";
export const CREATE_CORP_PRIVILAGE_CHECK_SUCCESS = "CREATE_CORP_PRIVILAGE_CHECK_SUCCESS";
export const CREATE_CORP_PRIVILAGE_CHECK_FAIL = "CREATE_CORP_PRIVILAGE_CHECK_FAIL";

export const GET_DIVISION_USERS_REQUEST = "GET_DIVISION_USERS_REQUEST";
export const GET_DIVISION_USERS_SUCCESS = "GET_DIVISION_USERS_SUCCESS";
export const GET_DIVISION_USERS_FAIL = "GET_DIVISION_USERS_FAIL";

//error actions
export const SHOW_ERROR = "SHOW_ERROR";
export const HIDE_ERROR = "HIDE_ERROR";

//statistics actions
export const GAME_PLAY_COUNT_REQUEST = "GAME_PLAY_COUNT_REQUEST";
export const GAME_PLAY_COUNT_FAIL = "GAME_PLAY_COUNT_FAIL";
export const GAME_PLAY_COUNT_SUCCESS = "GAME_PLAY_COUNT_SUCCESS";

export const NEW_USERS_COUNT_REQUEST = "NEW_USERS_COUNT_REQUEST";
export const NEW_USERS_COUNT_SUCCESS = "NEW_USERS_COUNT_SUCCESS";
export const NEW_USERS_COUNT_FAIL = "NEW_USERS_COUNT_FAIL";

export const USER_COUNTS_REQUEST = "USER_COUNTS_REQUEST";
export const USER_COUNTS_SUCCESS = "USER_COUNTS_SUCCESS";
export const USER_COUNTS_FAIL = "USER_COUNTS_FAIL";

export const SUMMARY_DATA_REQUEST = "SUMMARY_DATA_REQUEST";
export const SUMMARY_DATA_SUCCESS = "SUMMARY_DATA_SUCCESS";
export const SUMMARY_DATA_FAIL = "SUMMARY_DATA_FAIL";

export const GET_USER_COMPARISON_INFO_REQUEST = "GET_USER_COMPARISON_INFO_REQUEST";
export const GET_USER_COMPARISON_INFO_SUCCESS = "GET_USER_COMPARISON_INFO_SUCCESS";
export const GET_USER_COMPARISON_INFO_FAIL = "GET_USER_COMPARISON_INFO_FAIL";


export const ALL_CATEGORY_STATISTICS_REQUEST = "ALL_CATEGORY_STATISTICS_REQUEST";
export const ALL_CATEGORY_STATISTICS_SUCCESS = "ALL_CATEGORY_STATISTICS_SUCCESS";
export const ALL_CATEGORY_STATISTICS_FAIL = "ALL_CATEGORY_STATISTICS_FAIL";


//////////// purchase 

export const PURCHASE_REQUEST = "PURCHASE_REQUEST";
export const PURCHASE_SUCCESS = "PURCHASE_SUCCESS";
export const PURCHASE_FAIL = "PURCHASE_FAIL";

export const VALIDATE_COUPON_REQUEST = "VALIDATE_COUPON_REQUEST";
export const VALIDATE_COUPON_SUCCESS = "VALIDATE_COUPON_SUCCESS";
export const VALIDATE_COUPON_FAIL = "VALIDATE_COUPON_FAIL";


//////////// report_data 

export const REPORT_DATA_REQUEST = "REPORT_DATA_REQUEST";
export const REPORT_DATA_SUCCESS = "REPORT_DATA_SUCCESS";
export const REPORT_DATA_FAIL = "REPORT_DATA_FAIL";

//////////// coupon_data 
export const GET_COUPONS_REQUEST = "GET_COUPONS_REQUEST";
export const GET_COUPONS_SUCCESS = "GET_COUPONS_SUCCESS";
export const GET_COUPONS_FAIL = "GET_COUPONS_FAIL";

export const DEACTIVATE_COUPON_REQUEST = "DEACTIVATE_COUPON_REQUEST";
export const DEACTIVATE_COUPON_SUCCESS = "DEACTIVATE_COUPON_SUCCESS";
export const DEACTIVATE_COUPON_FAIL = "DEACTIVATE_COUPON_FAIL";

export const CREATE_COUPON_REQUEST = "CREATE_COUPON_REQUEST";
export const CREATE_COUPON_SUCCESS = "CREATE_COUPON_SUCCESS";
export const CREATE_COUPON_FAIL = "CREATE_COUPON_FAIL";

/////////// inventory_coupon_data
export const GET_INVENTORY_COUPONS_REQUEST = "GET_INVENTORY_COUPONS_REQUEST";
export const GET_INVENTORY_COUPONS_SUCCESS = "GET_INVENTORY_COUPONS_SUCCESS";
export const GET_INVENTORY_COUPONS_FAIL = "GET_INVENTORY_COUPONS_FAIL";

export const DEACTIVATE_INVENTORY_COUPON_REQUEST = "DEACTIVATE_INVENTORY_COUPON_REQUEST";
export const DEACTIVATE_INVENTORY_COUPON_SUCCESS = "DEACTIVATE_INVENTORY_COUPON_SUCCESS";
export const DEACTIVATE_INVENTORY_COUPON_FAIL = "DEACTIVATE_INVENTORY_COUPON_FAIL";

export const CREATE_INVENTORY_COUPON_REQUEST = "CREATE_INVENTORY_COUPON_REQUEST";
export const CREATE_INVENTORY_COUPON_SUCCESS = "CREATE_INVENTROY_COUPON_SUCCESS";
export const CREATE_INVENTORY_COUPON_FAIL = "CREATE_INVENTORY_COUPON_FAIL";