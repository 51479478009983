import html2pdf from 'html2pdf.js';

// Dokümantasyon şurada:   https://ekoopmans.github.io/html2pdf.js/   


export const multiplePagePrint = ({settings, pageIdArray, fileName}) => {
  const element = document.getElementById(pageIdArray[0]);
//   html2pdf(element).save();

const opt = {
    margin:       0,
    filename:     fileName,
    image:        { type: 'jpeg', quality: 0.98 },
    html2canvas:  { 
      scale: 2, 
      allowTaint: true,
      logging: true,
      useCORS: true,
      //taintTest: true, 
    },
   // jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
   jsPDF:{},
   // pagebreak: { mode: 'avoid-all', before: '#page2el' }
  };

  var worker = html2pdf().set(opt).from(element).save();
}