import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  getUser,
  getGamePlayCount,
  getTransactionHistory,
  getAdminNotes,
  getProfileCardGameSummary,
  getAllCategoryStatistics,
} from "../../../store/actions";
import SortableList from "../../../components/Lists/SortableList";
import ProfileCard from "../../../components/Info/ProfileCard";
import AdminNote from "../../../components/Info/AdminNote";
import {
  Grid,
  Card,
  CircularProgress,
  Modal,
  Typography,
  IconButton,
} from "@material-ui/core";
import Print from "@material-ui/icons/Print";
import { withI18n } from "react-i18next";
import { categories } from "../../../strings/values";
import { Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import FamilyCard from "../../../components/Info/FamilyCard";
import moment from "moment";
import "./style.css";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import Radar from "../../../components/Charts/RadarChart";
import HideContent from "../../../components/ui/HideContent/HideContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import { print } from "../../../utils/pdf";

import Purchase from "../../../components/Purchase/Purchase";
import UserReport from "../UserReport/UserReport";

const initialRadarData = {
  filter: {
    numeric: 5,
    words: 4,
    memory: 5,
    visual: 3,
    logic: 6,
    attention: 5,
  },
  this_user: {
    numeric: 0,
    words: 0,
    memory: 0,
    visual: 0,
    logic: 0,
    attention: 0,
  },
};

class Profile extends Component {
  state = {
    user: null,
    history: {},
    gameSummary: {},
    modal: {
      visibility: false,
      data: {},
    },
    adminNotes: [],
    radarData: initialRadarData,
    showReport: false,
  };

  componentDidMount() {
    const user_id = +this.props.match.params.id || this.props.authUser.id;

    // let user = this.props.users.find(user => user.id === user_id);
    // if(user) this.setState({user:{...user}})
    this.props
      .getUser(user_id)
      .then((user) => user && this.setState({ user }, this.updateForm));
    this.props.getGamePlayCount(user_id);
    this.props
      .getProfileCardGameSummary(user_id)
      .then((res) => this.setState({ gameSummary: { ...res } }));
    if (this.props.authUser.role === 1) {
      this.props
        .getAdminNotes(user_id)
        .then((data) => this.setState({ adminNotes: data || [] }));
      this.props
        .getTransactionHistory(user_id)
        .then((data) => this.setState({ history: data }));
    }
    if (this.props.authUser.is_premium)
      this.props.getAllCategoryStatistcis(user_id).then((res) => {
        if (res && res.statistics) this.setState({ radarData: res.statistics });
      });
  }
  updateForm = () => {
    const { birth_date, education, occupation, gender } = this.state.user;
    this.setState({
      formData: {
        birth_date: birth_date ? moment(birth_date) : null,
        education: education || "",
        occupation: occupation || "",
        gender: gender || "",
      },
    });
  };
  familyMemberAdded = (user) => {
    this.setState((prevState) => {
      return {
        user: {
          ...prevState.user,
          family: [...(prevState.user.family || []), user],
        },
      };
    });
  };
  familyMemberRemoved = (user_id) => {
    this.setState((prevState) => {
      return {
        user: {
          ...prevState.user,
          family: prevState.user.family.filter(
            (user) => user.user_id !== user_id
          ),
        },
      };
    });
  };
  rowComponent = ({ tableRow, ...props }) => {
    return (
      <Table.Row
        {...props}
        style={
          tableRow.rowId % 2
            ? { backgroundColor: "#fff" }
            : { backgroundColor: "#F5F7F9" }
        }
      />
    );
  };
  categoryNameFormatter = ({ value }) => {
    return value === "total" ? (
      this.props.t(`categories.${value}`)
    ) : (
      <>
        <img width="40" src={`/images/categories/${value}.png`} />{" "}
        {this.props.t(`categories.${value}`)}
      </>
    );
  };
  gameNameFormatter = ({ value }) => {
    return value === "total" ? (
      this.props.t(`games.${value}.name`)
    ) : (
      <>
        <img width="40" src={`/images/games/${value}.png`} />{" "}
        {this.props.t(`games.${value}.name`)}
      </>
    );
  };
  tableCellComponent = (props) => {
    return (
      <Table.Cell {...props} style={{ ...props.style, borderBottom: "0px" }} />
    );
  };

  tableHeaderCellComponent = (props) => {
    let common = {
      color: "white",
      paddingLeft: "24px",
      paddingRight: "0px",
      fontWeight: "bold",
      borderBottom: "none",
    };
    let style = {
      today: { ...common, backgroundColor: "#24738C" },
      last7: { ...common, backgroundColor: "#006480" },
      last30: { ...common, backgroundColor: "#24738C" },
      total: { ...common, backgroundColor: "#354B5C" },
      name: { ...common },
    };
    return <TableHeaderRow.Cell {...props} style={style[props.column.name]} />;
  };

  getActivities = () => {
    let { t } = this.props;
    let data = this.state.history;
    let style = {
      borderLeft: "3px solid #BAC1C4",
      paddingLeft: "25px",
      paddingBottom: "10px",
    };
    let list = [
      <div
        key="register"
        style={data.subscriptions ? style : { paddingLeft: "28px" }}
      >
        <div
          className="bubble dot register p-2 font-weight-bold"
          style={{ fontSize: "0.9rem" }}
        >
          <div style={{ color: "#2A768D" }}>
            {moment(data.register_date)
              .utc()
              .format("DD.MM.YYYY - HH:mm")}
          </div>
          <div style={{ color: "#354C60", fontSize: "0.9rem" }}>
            {t("register")}
          </div>
        </div>
      </div>,
    ];
    data.subscriptions &&
      data.subscriptions.forEach((sub, index) => {
        let newStyle = { paddingLeft: "28px" };
        index !== data.subscriptions.length - 1 &&
          Object.assign(newStyle, style);
        let state;
        if (sub.state === "Purchased") state = "purchase";
        else state = "cancel";
        list.push(
          <div key={index} style={newStyle}>
            <div
              className={`bubble dot blue p-2 ${state} font-weight-bold`}
              style={{ fontSize: "0.9rem" }}
            >
              <div style={{ color: "#2A768D" }}>
                {moment(sub.date).format("DD.MM.YYYY")}
              </div>
              <div
                style={{
                  color: sub.state === "Cancelled" ? "#FF6C7C" : "#01BC9E",
                  fontSize: "0.9rem",
                }}
              >
                {sub.state === "Cancelled" &&
                  t(sub.state.toLowerCase()) + " / "}
                {t(sub.product)}
              </div>
              {sub.parent && (
                <div style={{ color: "#7F7F7F", fontSize: "0.7rem" }}>
                  {t("parent")} :
                  <div className="font-weight-normal">{sub.parent}</div>
                </div>
              )}
            </div>
          </div>
        );
      });

    return <>{list}</>;
  };

  tableCellComponent = (props) => {
    return (
      <Table.Cell {...props} style={{ ...props.style, borderBottom: "0px" }} />
    );
  };

  tableHeaderCellComponent = (props) => {
    let common = {
      color: "white",
      paddingLeft: "24px",
      paddingRight: "0px",
      fontWeight: "bold",
      borderBottom: "none",
    };
    let style = {
      today: { ...common, backgroundColor: "#24738C" },
      last7: { ...common, backgroundColor: "#006480" },
      last30: { ...common, backgroundColor: "#24738C" },
      total: { ...common, backgroundColor: "#354B5C" },
      name: { ...common },
    };
    return <TableHeaderRow.Cell {...props} style={style[props.column.name]} />;
  };

  adminNoteCompleted = (data, type) => {
    switch (type) {
      case "add":
        this.setState((prevState) => {
          return { adminNotes: [data, ...prevState.adminNotes] };
        });
        break;
      case "update":
        let updatedNotes = this.state.adminNotes.map((note) => {
          if (note.id === data.id) return data;
          return note;
        });
        this.setState({ adminNotes: updatedNotes });
        break;
      case "delete":
        let deletedNotes = this.state.adminNotes.filter(
          (note) => note.id !== data.id
        );
        this.setState({ adminNotes: deletedNotes });
        break;
      default:
    }
  };

  updateProfileCard = (user) => {
    this.setState({ user });
  };

  closeReport = () => this.setState({ showReport: false });

  render() {
    const { user, gameSummary } = this.state;
    const { first_name, last_name, email } = user || {
      first_name: "",
      last_name: "",
      email: "",
    };
    const { t, gamePlayCount, userLoading, history } = this.props;
    const userId = +this.props.match.params.id || this.props.authUser.id;
    const isPremium = this.props.authUser.is_premium;
    if (userLoading)
      return (
        <Card className="shadow p-4 d-flex justify-content-center">
          <CircularProgress />
        </Card>
      );
    if (!user)
      return <div className="mt-4">{t("errorMessages.userNotFound")}</div>;

    const categoriesCount = (gamePlayCount || []).reduce((acc, game) => {
      acc[categories[game.name]]
        ? (acc[categories[game.name]] = {
            ...acc[categories[game.name]],
            today: acc[categories[game.name]].today + game.today,
            last7: acc[categories[game.name]].last7 + game.last7,
            last30: acc[categories[game.name]].last30 + game.last30,
            total: acc[categories[game.name]].total + game.total,
          })
        : (acc[categories[game.name]] = {
            today: game.today,
            last7: game.last7,
            last30: game.last30,
            total: game.total,
            name: categories[game.name],
          });

      return acc;
    }, {});

    const firstLastPlayDatesSection = (
      <Card className="shadow p-4 mx-2 mb-4">
        {/* <div className="row p-3 my-0 mx-0" style={{ backgroundColor: "#F5F7F9", color: "#7D7D7E", fontSize: "0.8rem" }}>
                    <div className="col-7 font-weight-bold px-1">
                      {t("total_play_count")}
                    </div>
                    <div className="col-5 px-0 text-center">{gameSummary.game_count}</div>
                  </div> */}
        <div
          className="row p-4 my-0 mx-0"
          style={{
            backgroundColor: "#F5F7F9",
            color: "#7D7D7E",
            fontSize: "0.8rem",
          }}
        >
          <div className="col-7 font-weight-bold px-1">{t("first_play")}</div>
          <div className="col-5 px-0 text-center">
            {gameSummary.first_game_date
              ? moment(gameSummary.first_game_date).format("DD.MM.YYYY")
              : "-"}
          </div>
        </div>
        <div
          className="row p-4 my-0 mx-0"
          style={{
            backgroundColor: "white",
            color: "#7D7D7E",
            fontSize: "0.8rem",
          }}
        >
          <div className="col-7 font-weight-bold px-1">{t("last_play")}</div>
          <div className="col-5 px-0 text-center">
            {gameSummary.last_game_date
              ? moment(gameSummary.last_game_date).format("DD.MM.YYYY")
              : "-"}
          </div>
        </div>
        <div
          className="row p-4 my-0 mx-0"
          style={{
            backgroundColor: "#F5F7F9",
            color: "#7D7D7E",
            fontSize: "0.7rem",
          }}
        >
          <div className="col-7 font-weight-bold px-1">{t("bps")}</div>
          <div className="col-5 px-0 text-center ">{gameSummary.bps}</div>
        </div>
        <div
          className="row p-4 my-0 mx-0"
          style={{
            backgroundColor: "white",
            color: "#7D7D7E",
            fontSize: "0.7rem",
          }}
        >
          <div className="col-7 font-weight-bold px-1">
            {t("weighted_score")}
          </div>
          <div className="col-5 px-0 text-center ">
            {(gameSummary.weighted_score || 0).toFixed(2)}
          </div>
        </div>
        {this.props.authUser.role === 1 && (
          <div
            className="row p-4 my-0 mx-0"
            style={{
              backgroundColor: "#F5F7F9",
              color: "#7D7D7E",
              fontSize: "0.8rem",
            }}
          >
            <div className="col-7 font-weight-bold px-1">
              {t("last_game_before_purchase")}
            </div>
            <div className="col-5 px-0 text-center">
              {gameSummary.last_game_before_purchase
                ? t(`games.${gameSummary.last_game_before_purchase}.name`)
                : "-"}
            </div>
          </div>
        )}
      </Card>
    );

    const profileCard = (
      <ProfileCard
        history={history}
        update={this.updateProfileCard}
        user={this.state.user}
        t={t}
      />
    );

    const familyCard = (user.membership_type.includes("family") ||
      user.is_family_administrator) && (
      <FamilyCard
        onFamilyMemberAdd={this.familyMemberAdded}
        onFamilyMemberRemove={this.familyMemberRemoved}
        family={user.family || []}
      />
    );

    const statsListByCategory = (
      <SortableList
        columns={columns(t)}
        rows={Object.values(categoriesCount)}
        rowComponent={this.rowComponent}
        tableCellComponent={this.tableCellComponent}
        columnExtensionsTable={columnExtensions}
        tableHeaderCellComponent={this.tableHeaderCellComponent}
        dataTypeProvider={
          <DataTypeProvider
            for={["name"]}
            formatterComponent={this.categoryNameFormatter}
          />
        }
        columnExtensionsSort={[{ columnName: "name", sortingEnabled: false }]}
      />
    );

    const statsTableByCategory = (
      <Card className="shadow mx-2 mb-4 p-4">{statsListByCategory}</Card>
    );

    const statsListByGameForReport = (
      <SortableList
        columns={columns(t)}
        rows={gamePlayCount}
        rowComponent={this.rowComponent}
        tableCellComponent={this.tableCellComponent}
        columnExtensionsTable={columnExtensions}
        tableHeaderCellComponent={this.tableHeaderCellComponent}
        dataTypeProvider={
          <DataTypeProvider
            for={["name"]}
            formatterComponent={this.gameNameFormatter}
          />
        }
        columnExtensionsSort={[{ columnName: "name", sortingEnabled: false }]}
      />
    );

    const statsTableByGame = (
      <Card
        className="shadow mx-2 mb-4 p-4"
        style={{ height: "40vh", overflowY: "scroll" }}
      >
        <SortableList
          columns={columns(t)}
          rows={gamePlayCount}
          rowComponent={this.rowComponent}
          tableCellComponent={this.tableCellComponent}
          columnExtensionsTable={columnExtensions}
          tableHeaderCellComponent={this.tableHeaderCellComponent}
          dataTypeProvider={
            <DataTypeProvider
              for={["name"]}
              formatterComponent={this.gameNameFormatter}
            />
          }
          columnExtensionsSort={[{ columnName: "name", sortingEnabled: false }]}
        />
      </Card>
    );

    const radarChartSection = (
      <Card className="shadow px-1 pt-4 pb-1 mx-2 mb-4 RadarContainer">
        <HideContent
          hiden={!isPremium}
          message={t("buy_now")}
          onClick={() => {
            alert("will buy");
          }}
        >
          <Radar data={this.state.radarData} />
          <div className="text-right">
            <Link to={`/info/statistics/${userId}`}>Detaylı İstatistikler</Link>
          </div>
        </HideContent>
      </Card>
    );

    const activitiesSection = this.props.authUser.role === 1 && (
      <Card className="shadow p-4 mx-2 mb-4">
        <p className="h5 my-3 mx-2">{t("activity")}</p>
        {this.state.history && this.getActivities()}
      </Card>
    );

    const adminNotesSection = this.props.authUser.role === 1 && (
      <Card className="shadow p-4 mx-2">
        <div className="align-items-baseline d-flex justify-content-between mb-2">
          <div className="d-inline-block h5">{t("admin_notes")}</div>
          <button
            className="btn btn-secondary btn-sm"
            onClick={() =>
              this.setState({
                modal: {
                  visibility: true,
                  data: { userId: userId },
                },
              })
            }
          >
            {t("add")}
          </button>
        </div>
        {this.state.adminNotes.map((data, index) => {
          return (
            <div
              key={index}
              className="row py-2 px-2 my-0 mx-0"
              style={{
                backgroundColor: index % 2 ? "white" : "#F5F7F9",
                color: "#7D7D7E",
                fontSize: "0.8rem",
              }}
            >
              <div
                className="col-lg-9 col-11 col-sm-11 font-weight-bold px-1"
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {data.title}
              </div>
              <div
                className="col-lg-3 col-1 col-sm-1 px-0 text-right"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  this.setState({
                    modal: { visibility: true, data: data },
                  })
                }
              >
                {t("show")}
              </div>
            </div>
          );
        })}
      </Card>
    );

    const adminNoteModal = (
      <Modal
        open={this.state.modal.visibility}
        onClose={() => this.setState({ modal: { visibility: false } })}
      >
        <AdminNote
          data={this.state.modal.data}
          onClose={() => this.setState({ modal: { visibility: false } })}
          onComplete={this.adminNoteCompleted}
        />
      </Modal>
    );

    const printButton = (
      <div style={{ position: "absolute", right: 0, top: 7 }}>
        <IconButton
        title="Detaylı PDF Rapor"
          onClick={() => {
            if (!isPremium)
              alert("Detaylı PDF Rapor'u sadece premium üyeler indirebilir. ");
            else this.setState({ showReport: true });
          }}
        >
          <Print style={{ color: "#F6707B" }} />
        </IconButton>
      </div>
    );

    const reportModal = this.state.showReport && (
      <Modal
        open={this.state.showReport}
        onClose={this.closeReport}
        style={{ overflow: "scroll" }}
        // className={this.props.classes.modalStyle1}
      >
        <UserReport
          user={user}
          radarChartSection={radarChartSection}
          radarData={this.state.radarData}
          statsListByCategory={statsListByCategory}
          statsListByGameForReport={statsListByGameForReport}
        />

        {/* {UserReport} */}
        {/* <div
          className="Report"
          style={{
            width: 1024,
            margin: "auto",
            border: "2px solid #7f7f7f",
            padding: "1em 3em",
            fontSize: ".8em",
            backgroundColor: "white",
            overflow: "auto",
            fontFamily: "Roboto!important",
          }}
        >
          <div className="ReportContainer" style={{}}>
            <button onClick={() => print({}, ["toPDF"])}>PDF indir</button>
            <div
              id="toPDF"
              style={{
                padding: "1em 3em",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  fontWeight: "bold!important",
                  fontStyle: "'Roboto'!important",
                  fontSize: ".8em!important",
                }}
              >
                <Typography variant="h5">{`${user.first_name} ${
                  user.last_name
                }`}</Typography>
                <span
                  style={{
                    fontSize: ".8em",
                    color: "#9698AA",
                  }}
                >
                  {moment(new Date()).format("D MMMM Y dddd HH:mm")}
                </span>
                <br />
              </div>

              {radarCharSection}
              {statsTableByCategory}
              {statsTableByGameForReport}
            </div>
          </div>
        </div> */}
      </Modal>
    );

    const generatePilotSurveySection = () => {
      // bazı öğrencilere ve öğretmenlere google forms üzerinden anket uyguladık. Kimlerin doldurduğunu anlayabilmek için id bilgisini parametre olarak ankete gönderiyoruz
      // Daha sonra başka anketler de uygulama ihtimalimize karşı bu kısmı silmiyorum.
      if (!userId) return null;

      const pilotSurveyUsers = {
        // use_id: user_type  ikililerini tutar.
        216280: 1,
        216281: 2,
      };

      if (!pilotSurveyUsers[userId]) return null;

      let formUrl = "";
      if (pilotSurveyUsers[userId] === 2)
        //öğrenci
        formUrl = `https://docs.google.com/forms/d/e/1FAIpQLScZIwLJo-ocoN3QoCZH_r0b5D8ydby2rsqnjiHIib1mekSliA/viewform?usp=pp_url&entry.1547985899=${userId}`;
      else if (pilotSurveyUsers[userId] === 1)
        //öğretmen
        formUrl = `https://docs.google.com/forms/d/e/1FAIpQLSeARXkWMJWvx_U6BhQq_99v4o1SaGSV0mON-hsY4DlernVhqg/viewform?usp=pp_url&entry.1547985899=${userId}`;

      return (
        <Card
          className="shadow p-4 mx-2 mb-4"
          style={{
            textAlign: "center",
            border: "solid #d5cb97 2px",
            backgroundColor: "#f2f1e4",
          }}
        >
          <a
            target="_blank"
            href={formUrl}
            style={{ fontWeight: "bolder", fontSize: "1.2rem" }}
          >
            PİLOT ÇALIŞMA FORMUNA GİT
            <LinearProgress color="secondary" />
          </a>
        </Card>
      );
    };

    return (
      <div>
        <Grid container>
          <Grid item md={3} sm={12}>
            {/* {generatePilotSurveySection()} */}
            {profileCard}
            {familyCard}
          </Grid>
          <Grid item md={9} sm={12}>
            <Grid container>
              {!user.is_premium && (
                <Grid item md={12} sm={12} xs={12}>
                  <Purchase userId= {user.id} />
                </Grid>
              )}
              <Grid item md={8} sm={12}>
                {statsTableByCategory}
                {statsTableByGame}
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                {radarChartSection}
                {activitiesSection}
                {firstLastPlayDatesSection}
                {adminNotesSection}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {adminNoteModal}
        {reportModal}
        {printButton}
      </div>
    );
  }
}

const columns = (t) => {
  return [
    { name: "name", title: " " },
    { name: "today", title: t("today").toUpperCase() },
    { name: "last7", title: t("last7").toUpperCase() },
    { name: "last30", title: t("last30").toUpperCase() },
    { name: "total", title: t("total").toUpperCase() },
  ];
};
const columnExtensions = [
  { columnName: "name" },
  { columnName: "today", align: "center" },
  { columnName: "last7", align: "center" },
  { columnName: "last30", align: "center" },
  { columnName: "total", align: "center" },
];

const mapStateToProps = (state) => {
  return {
    users: state.users.users,
    gamePlayCount: state.statistics.gamePlayCount,
    userLoading: state.loading["USER"],
    authUser: state.auth.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (id) => dispatch(getUser(id)),
    getGamePlayCount: (id) => dispatch(getGamePlayCount(null, id)),
    getTransactionHistory: (id) => dispatch(getTransactionHistory(id)),
    getAdminNotes: (id) => dispatch(getAdminNotes(id)),
    getProfileCardGameSummary: (user_id) =>
      dispatch(getProfileCardGameSummary(user_id)),
    getAllCategoryStatistcis: (user_id) =>
      dispatch(getAllCategoryStatistics(user_id)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withI18n()(Profile));
