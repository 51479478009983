import i18next from 'i18next';
import common_tr from './strings/translations/tr/common.json';
import common_en from './strings/translations/en/common.json';

const i18n = i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'tr',                              // language to use
  resources: {
      en: {
          common: common_en               // 'common' is our custom namespace
      },
      tr: {
          common: common_tr
      },
  },
});

export default i18n;