import React, { Component } from "react";
import { formDataUpdate } from "../../../utils/formValidate";
import { withI18n } from "react-i18next";
const languages = {
  tr: "Türkçe",
  en: "English",
};

const initialFormElements = {
  first_name: {
    value: "",
    validityRules: { minLength: 1 },
  },
  last_name: {
    value: "",
    validityRules: { minLength: 1 },
  },
  email: {
    value: "",
    validityRules: { isEmail: true },
  },
  password: {
    value: "",
    validityRules: { minLength: 6 },
  },
  mail_language: { value: "tr" },
};

class AddCorpUser extends Component {
  state = {
    formElements: initialFormElements,
  };
  addUser = () => {
    const user = {
      first_name: this.state.formElements.first_name.value,
      last_name: this.state.formElements.last_name.value,
      email: this.state.formElements.email.value.trim(),
      password: this.state.formElements.password.value.trim(),
      mail_language: this.state.formElements.mail_language.value,
    };
    this.props.add(user);
    this.setState({ formElements: initialFormElements });
  };

  formElementChangedHandler = (event) => {
    let { name, value } = event.target;
    this.setState((prevState) => {
      return {
        formElements: formDataUpdate(prevState.formElements, value, name),
      };
    });
  };
  checkFormValidity = () => {
    const { first_name, last_name, email, password } = this.state.formElements;
    return [first_name, last_name, email, password].every(
      (parameter) => parameter.isValid
    );
  };
  render() {
    const { t } = this.props;
    return (
      <div className="row mb-4">
        <div className="col-4">
          <input
            type="text"
            placeholder={t("addUser.first_name")}
            name="first_name"
            style={{ backgroundColor: "#F0F0F0" }}
            className={`form-control ${
              this.state.formElements.first_name.touched
                ? this.state.formElements.first_name.isValid
                  ? "is-valid"
                  : "is-invalid"
                : ""
            }`}
            onChange={this.formElementChangedHandler}
            value={this.state.formElements.first_name.value}
          />
        </div>
        <div className="col-4">
          <input
            type="text"
            placeholder={t("addUser.last_name")}
            name="last_name"
            style={{ backgroundColor: "#F0F0F0" }}
            className={`form-control ${
              this.state.formElements.last_name.touched
                ? this.state.formElements.last_name.isValid
                  ? "is-valid"
                  : "is-invalid"
                : ""
            }`}
            onChange={this.formElementChangedHandler}
            value={this.state.formElements.last_name.value}
          />
        </div>
        <div className="col-4"></div>
        <div className="col-4">
          <input
            type="text"
            name="email"
            style={{ backgroundColor: "#F0F0F0" }}
            placeholder={t("email")}
            className={`form-control ${
              this.state.formElements.email.touched
                ? this.state.formElements.email.isValid
                  ? "is-valid"
                  : "is-invalid"
                : ""
            }`}
            onChange={this.formElementChangedHandler}
            value={this.state.formElements.email.value}
          />
        </div>
        

        <div className="col-4">
          <input
            type="text"
            name="password"
            style={{ backgroundColor: "#F0F0F0" }}
            placeholder={t("password")}
            className={`form-control ${
              this.state.formElements.password.touched
                ? this.state.formElements.password.isValid
                  ? "is-valid"
                  : "is-invalid"
                : ""
            }`}
            onChange={this.formElementChangedHandler}
            value={this.state.formElements.password.value}
          />
        </div>
        <div className="col-4 d-flex justify-content-between">
          <button
            className="btn dropdown-toggle btn-outline-info"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {languages[this.state.formElements.mail_language.value]}
          </button>
          <div className="dropdown-menu">
            <div
              className="dropdown-item"
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.formElementChangedHandler({
                  target: { name: "mail_language", value: "tr" },
                })
              }
            >
              Türkçe
            </div>
            <div
              className="dropdown-item"
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.formElementChangedHandler({
                  target: { name: "mail_language", value: "en" },
                })
              }
            >
              İngilizce
            </div>
          </div>
          <button
            className="btn btn-info ml-2 px-4"
            disabled={!this.checkFormValidity()}
            onClick={this.addUser}
          >
            {t("add")}
          </button>
        </div>
      </div>
    );
  }
}

export default withI18n()(AddCorpUser);
