import {
  GET_COUPONS_SUCCESS,
  DEACTIVATE_COUPON_SUCCESS,
  CREATE_COUPON_SUCCESS
} from "../actions/actionTypes";

const initialState = {
  coupons: []
};

const purchaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUPONS_SUCCESS:
      return {
        ...state,
        coupons: action.coupons
      };
    case DEACTIVATE_COUPON_SUCCESS:
      return {
        ...state,
        coupons: state.coupons.map(c =>
          c.id === action.coupon.id ? action.coupon : c
        )
      };
    case CREATE_COUPON_SUCCESS:
      console.log('CP: ', action.coupon);
      
      return {
        ...state,
        coupons: state.coupons.unshift(action.coupon) && state.coupons
      };
    default:
      return {
        ...state
      };
  }
};

export default purchaseReducer;
