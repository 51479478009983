import React from "react";
import { Grid, Typography } from "@material-ui/core";
import Contact from '@material-ui/icons/ContactMail';
import Email from '@material-ui/icons/Email';
import Phone from '@material-ui/icons/Call';
import Location from '@material-ui/icons/MyLocation';
import Edit from '@material-ui/icons/Edit';
import {Link} from 'react-router-dom';
import './style.css';
import MembershipTypeChip from "../../MembershipTypeChip";
import moment from 'moment';
const corporationCard = props => (
  <Grid item  md={12} lg={6} xl={4} className="p-2" >
    <div className="CorporationCard d-flex p-3 rounded" style={{backgroundColor:"#F6F7F9",width:"100%"}}>
          <img src={props.corporation.image} style={{width:90,height:90,objectFit:"contain",backgroundColor:"white"}} alt={props.corporation.name}/>
          <Grid container direction="column" alignItems="flex-start" justify="space-between">
            <Grid item style={{width:"100%"}}>
            <Grid container alignItems="center" justify="space-between">
            <Link to={"/info/corporations/"+props.corporation.id}>
            <Typography className="ml-3" variant="h6" style={{color:"#39708F"}} >
              {props.corporation.name}
            </Typography>
          </Link>
            <Link to={"/info/corporations/"+props.corporation.id+"/edit"} >
                        <Edit style={{cursor:"pointer",color:"#3A6C8D", padding:"0 3px", border:"1px solid #3A6C8D", borderRadius:5}}/>
                      </Link>
            </Grid>
            </Grid>
            <Grid item>
                <MembershipTypeChip className="ml-3" style={{height:20,width:80}} label={props.t(`membershipType.${props.corporation.c_type}`)} value={props.corporation.c_type}/>
                <MembershipTypeChip className="ml-2" style={{backgroundColor:"#FDD567",height:20,width:80,color:"#31332A"}} label={`${props.corporation.user_count}/${props.corporation.size}`}/>
                <MembershipTypeChip className="ml-2" style={{backgroundColor:"#59a118",height:20,width:80,color:"#31332A"}} label={`id: ${props.corporation.id}`}/>
                <div className="ml-3 mt-2 d-flex flex-row text-center" >
                  <div className="rounded d-flex flex-row" style={{backgroundColor:"#FFFFFF"}}>
                    <Typography className="border-right px-2" variant="caption" style={{minWidth:80}}>{props.corporation.start_at|| "-"}</Typography>
                    <Typography className="px-2" variant="caption" style={{minWidth:80}}>{props.corporation.end_at}</Typography>
                  </div>
                    <div className="rounded d-flex flex-row ml-2 text-center" style={{backgroundColor:"#FFFFFF"}}>
                    <Typography className="px-2 " variant="caption" style={{minWidth:70}}>{moment(props.corporation.end_at).diff(moment(),"days")>0?moment(props.corporation.end_at).diff(moment(),"days"):0} {props.t("days")}</Typography>
                    </div>
                    <div className="DetailsIcon">
                      <Contact className="ml-2" style={{color:"#3A6C8D"}}/>
                      <div className="Details rounded text-left p-2">
                        <div className="d-flex mb-2">
                          <Email style={{color:"#3A6C8D"}}/>
                          <Typography className="ml-2" variant="body2" >{props.corporation.email||"-"}</Typography>
                        </div>
                        <div className="d-flex mb-2">
                        <Phone style={{color:"#3A6C8D"}}/>
                        <Typography className="ml-2" variant="body2" >{props.corporation.phone1||"-"}</Typography>
                        </div>
                        <div className="d-flex">
                        <Location style={{color:"#3A6C8D"}}/>
                        <Typography className="ml-2" variant="body2" >{props.corporation.address||"-"}</Typography>
                        </div>
                      </div>
                    </div>

                </div>
              </Grid>

          </Grid>


    </div>

  </Grid>
);

export default corporationCard;
