import React, {Component} from 'react'
import {Doughnut} from 'react-chartjs-2'

class DonutChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      colors: [
        '#FF6384', //Red
        '#00CED1', //Blue
        '#32CD32', //Green
        '#9370DB', //Violet
        '#FFCE56', //Yellow
        '#C60072',
        '#a9b3ae', //Grey
        '#7D5630', //Brown
        '#D10000',
        '#52243B',
        '#75292C',
        '#6A8407',
        '#080A97',
        '#4100BA',
      ],
    }
  }

  render(){
    const data = {
      labels: Object.keys(this.props.data || {}),
      datasets: [{
        data: Object.values(this.props.data || {}),
        backgroundColor: [
          ...this.state.colors
        ],
        hoverBackgroundColor: [
          ...this.state.colors
        ]
      }],
    };
    const options = this.props.options

    return (
        <Doughnut data = {data} height = {this.props.height || 400} options ={options}/>
    );
  }
}

export default DonutChart;
