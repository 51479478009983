import {
    GET_INVENTORY_COUPONS_SUCCESS,
    DEACTIVATE_INVENTORY_COUPON_SUCCESS,
    CREATE_INVENTORY_COUPON_SUCCESS
  } from "../actions/actionTypes";
  
  const initialState = {
    coupons: []
  };
  
  const inventoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_INVENTORY_COUPONS_SUCCESS:
        return {
          ...state,
          coupons: action.coupons
        };
      case DEACTIVATE_INVENTORY_COUPON_SUCCESS:
        return {
          ...state,
          coupons: state.coupons.map(c =>
            c.id === action.coupon.id ? action.coupon : c
          )
        };
      case CREATE_INVENTORY_COUPON_SUCCESS:
        console.log('CP: ', action.coupon);
        
        return {
          ...state,
          coupons: state.coupons.unshift(action.coupon) && state.coupons
        };
      default:
        return {
          ...state
        };
    }
  };
  
  export default inventoryReducer;
  