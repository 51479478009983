import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Typography,
  LinearProgress,
  withStyles,
  Hidden
} from "@material-ui/core";
import Person from "@material-ui/icons/Person";
import { purchase, validate_coupon } from "../../store/actions/purchase";
import "./style.css";
import CustomInput from "../ui/CustomInput";
import { withI18n } from "react-i18next";

let products = [
  {
    name: "personalmonth",
    id: 1,
    price: 25,
    text: "Bireysel Aylık",
    couponed_price: 25,
    coupon_prefix: 'BA'
  },
  {
    name: "familymonth",
    id: 3,
    price: 50,
    text: "Aile Aylık",
    couponed_price: 50,
    coupon_prefix: 'AA'
  },
  {
    name: "personalyear",
    id: 2,
    price: 130,
    text: "Bireysel Yıllık",
    couponed_price: 130,
    coupon_prefix: 'BY'
  },

  {
    name: "familyyear",
    id: 4,
    price: 260,
    text: "Aile Yıllık",
    couponed_price: 260,
    coupon_prefix: 'AY'
  }

];

const coupons = ["OYUN2019"];

class Purchase extends Component {
  state = {
    appliedCoupon: "",
    coupon: "",
    showInput: false,
    products: products
  };
  purchase = product_id => {
    const coupon = this.state.appliedCoupon;
    this.props.purchase(product_id, coupon, this.props.userId).then(data => {
      if (data && data.redirect_url) {
        window.location.replace(data.redirect_url);
      }
    });
  };
  handleInput = e => this.setState({ coupon: e.target.value });
  applyCode = code => {
    this.props.validate_coupon(code).then(data => {
      if (data && data.coupon) {

        const isCodeForKolayKampus = ["BA", "BY", "AA", "AY"].includes(code.substring(0,2));

        products.forEach(x => {
          if ( !isCodeForKolayKampus || x.coupon_prefix === code.substring(0, 2)) {
            x.couponed_price = data.coupon.percent
              ? x.price - (x.price * data.coupon.value) / 100
              : x.price - data.coupon.value;
            x.couponed_price = Math.round(x.couponed_price * 100) / 100;
            if (x.couponed_price < 0) x.couponed_price = 0
          }

        });
        this.setState({ products, appliedCoupon: this.state.coupon });
      } else {
        alert("Kupon Bulunamadı.")
      }
    });
  };
  showInput = () => this.setState({ showInput: true });
  render() {
    const { classes } = this.props;

    if (this.props.purchaseLoading)
      return (
        <div className="p-4 mx-2 mb-4 shadow">
          <LinearProgress
            classes={{
              colorPrimary: classes.linearColorPrimary,
              barColorPrimary: classes.linearBarColorPrimary
            }}
          />
          <div className="alert alert-success">
            Ödeme sayfasına yönlendiriliyor{" "}
          </div>
        </div>
      );
    const { showInput, appliedCoupon } = this.state;
    return (
      <div
        className="p-4 mx-2 mb-4 shadow PurchaseContainer"
        style={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "baseline"
        }}
      >
        <Hidden xsDown>
          <div style={{ width: "max-content" }}>
            <Typography
              variant="h5"
              style={{
                color: "#36495D",
                fontWeight: "bold",
                marginBottom: "1em"
              }}
            >
              {" "}
              {this.props.t("signup_griceviz")}
              {" "}
            </Typography>
            <li>Kişisel Egzersiz Programı</li>
            <li>Tüm oyunlara Erişim</li>
            <li>Detaylı İstatistik Raporlar</li>
            <li>Dinamik Raporlama Özelliği</li>
          </div>
        </Hidden>
        <div
          className="ItemsAndCouponContainer"
          style={{
            display: "flex",
            flexDirection:"column",
            
          }}
        >
          {!appliedCoupon && (
            <div
              style={{
                display: "flex",
                // flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#F0F0F0",
                justifyContent:"flex-start",
                borderRadius: 10,
                margin: "0 1em",
                padding: "1em",
                flexGrow: 2
              }}
            >
              <CustomInput
                containerstyle={{
                  padding: ".35em",
                  marginBottom: ".1em",
                  marginRight:10
                }}
                lefticon={
                  <Person style={{ color: "#7a7a7a", marginRight: "5" }} />
                }
                placeholder="Kupon Kodu"
                value={this.state.coupon}
                onChange={this.handleInput}
              />
              <button
                style={{
                  backgroundColor: "white",
                  textAlign: "center",
                  border: "1px solid #38BB9C",
                  padding: ".3em",
                  borderRadius: 50
                }}
                className="PurchaseButton"
                onClick={() => this.applyCode(this.state.coupon)}
              >
                <Typography
                  variant="caption"
                  style={{
                    fontWeight: "bold",
                    fontSize: ".9em",
                    color: "#7b7b7b"
                  }}
                >
                  UYGULA
                </Typography>
              </button>
            </div>
          )}
          <div className={classes.productListContainer}>
            {this.state.products.map(product => {
              const couponForTozos2022 = appliedCoupon && appliedCoupon.substring(0,2) === 'TZ' && appliedCoupon.length ===12;
              const couponBelongsToKanguru = appliedCoupon && ["KGR", "KGM"].includes(appliedCoupon.substring(0,3));
              const kanguruUserShouldNotSeeTheProduct = [1,3].includes(product.id);

              if(couponBelongsToKanguru && kanguruUserShouldNotSeeTheProduct) return null;
              if(couponForTozos2022 && product.name !=="personalyear") return null; // Tozoş'ta ödül olarak kupon dağıttık. Yanlışlıkla diğer paketleri aldıkları için bu filtreyi ekledim.

              return (
                <div
                  className={classes.product}
                  key={product.id}
                >
                  <div style={{ height: "50px" }}>
                    <Typography
                      variant="h6"
                      style={{
                        color: "#36495D",
                        fontWeight: "bold",
                        textAlign: "center",
                        lineHeight: "25px"
                      }}
                    >
                      {product.text}
                    </Typography>
                  </div>

                  {product.couponed_price < product.price && (
                    <span>
                      <span
                        style={{
                          textDecoration: "line-through",
                          textDecorationColor: "red"
                        }}
                      >
                        {product.price}{" "}
                      </span>{" "}
                      yerine
                    </span>
                  )}
                  <span
                    style={{
                      color: "#39718E",
                      fontSize: "1.3em",
                      fontWeight: "bold"
                    }}
                  >
                    {appliedCoupon ? product.couponed_price : product.price} ₺
                  </span>
                  <div
                    style={{
                      width: "80%",
                      height: "25%",
                      backgroundColor: "#FCD767",
                      textAlign: "center",
                      padding: ".3em",
                      borderRadius: 50,
                      justifyContent: "center"
                    }}
                    className="PurchaseButton"
                    onClick={() => this.purchase(product.id)}
                  >
                    <Typography
                      variant="caption"
                      style={{
                        fontWeight: "bold",
                        fontSize: "0.9em",
                        color: "#39718E"
                      }}
                    >
                      {product.couponed_price <= 0 ? "UYGULA" : "SATIN AL"}
                    </Typography>
                  </div>
                </div>
              )
            }





            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  purchaseLoading: state.loading["PURCHASE"]
});
const mapDispatchToProps = dispatch => ({
  purchase: (product_id, coupon, userId) => dispatch(purchase(product_id, coupon, userId)),
  validate_coupon: code => dispatch(validate_coupon(code))
});

const styles = theme => ({
  product: {
    transition: "transform .2s linear",
    "&:hover": {
      transform: "scale(1.1)"
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F0F0F0",
    justifyContent: "space-between",
    borderRadius: 10,
    margin: "0.3em",
    padding: "1em",
    width: 130,
    // minWidth: 300
  },
  productListContainer:{
    display: "flex", 
    flexGrow: 1,
    flexWrap: "wrap",
    justifyContent:"center",
    alignItems:"center" 
  },
  linearColorPrimary: {
    backgroundColor: "#b2dfdb"
  },
  linearBarColorPrimary: {
    backgroundColor: "#00695c"
  },
  buttonLabel: {
    color: "#21728A",
    "&:hover": {
      color: "white"
    }
  },
  buttonRoot: {
    "&:hover": {
      backgroundColor: "#21728A"
    },
    "&:hover $label": {
      color: "white"
    }
  },

  buttonBorder: {
    borderColor: "#21728A",
    borderWidth: 2,
    "&:hover": {
      borderColor: "#21728A",
      borderWidth: 2
    }
  }
  // Reproduce the Facebook spinners.
});


export default connect(mapStateToProps, mapDispatchToProps)(withI18n()(withStyles(styles)(Purchase)));
