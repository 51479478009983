import React from 'react';
import { NavLink } from 'react-router-dom';
import { Card } from "@material-ui/core";

export default (props) =>  <Card className=" shadow p-2 " style={{ overflow: "visible" }}> 
     <NavLink  className="nav-card ml-2" activeClassName="nav-card-active-class" to="/about">Ana Sayfa</NavLink>
        <ul style={{listStyleType:"none" } } >    
           <li><NavLink className="nav-card ml-2" activeClassName="nav-card-active-class"to="/about/chc"> CHC Kuramı </NavLink> </li> 
           <li><NavLink className="nav-card ml-2" activeClassName="nav-card-active-class"to="/about/faq"> Biz Kimiz? </NavLink></li>
           <li><NavLink className="nav-card ml-2" activeClassName="nav-card-active-class"to="/about/Sss"> SSS </NavLink></li>
        </ul>
    
</Card>