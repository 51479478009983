import React,{Component} from 'react';
import {Route } from 'react-router-dom';
import { connect } from 'react-redux';

class PrivateRoute extends Component {
  render(){
    const { roles, ...others} = this.props;
    return roles.find(role => role === this.props.user.role)
    ? <Route {...others} />
    : null
  }
}

const mapStateToProps = state => {
  return {
    user:state.auth.user
  }
}
export default connect(mapStateToProps)(PrivateRoute);