import {
  USERS_REQUEST,
  USERS_SUCCESS,
  USERS_FAIL,
  USER_SUCCESS,
  USER_REQUEST,
  USER_FAIL,
  TRANSACTION_HISTORY_REQUEST,
  TRANSACTION_HISTORY_SUCCESS,
  TRANSACTION_HISTORY_FAIL,
  INVITE_TO_FAMILY_REQUEST,
  INVITE_TO_FAMILY_SUCCESS,
  INVITE_TO_FAMILY_FAIL,
  REMOVE_FROM_FAMILY_REQUEST,
  REMOVE_FROM_FAMILY_FAIL,
  REMOVE_FROM_FAMILY_SUCCESS,
  ADMIN_NOTES_REQUEST,
  ADMIN_NOTES_SUCCESS,
  ADMIN_NOTES_FAIL,
  ADMIN_NOTE_UPDATE_REQUEST,
  ADMIN_NOTE_UPDATE_SUCCESS,
  ADMIN_NOTE_UPDATE_FAIL,
  ADMIN_NOTE_ADD_REQUEST,
  ADMIN_NOTE_ADD_SUCCESS,
  ADMIN_NOTE_ADD_FAIL,
  ADMIN_NOTE_DELETE_REQUEST,
  ADMIN_NOTE_DELETE_SUCCESS,
  ADMIN_NOTE_DELETE_FAIL,
  USERS_TABLE_REQUEST,
  USERS_TABLE_SUCCESS,
  USERS_TABLE_FAIL,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  SEARCH_USERS_REQUEST,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_FAIL,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAIL,
  GET_PROFILE_CARD_GAME_SUMMARY_REQUEST,
  GET_PROFILE_CARD_GAME_SUMMARY_SUCCESS,
  GET_PROFILE_CARD_GAME_SUMMARY_FAIL,
  PLAY_LIMIT_REQUEST,
  PLAY_LIMIT_FAIL,
  PLAY_LIMIT_SUCCESS,
  RESTRICT_GAMES_REQUEST,
  RESTRICT_GAMES_SUCCESS,
  RESTRICT_GAMES_FAIL,
} from './actionTypes';
import {showError} from './';
import api from '../../api';
import axios from '../../axios-instance';
import { signInSuccess } from './auth';


// USERS ACTIONS
const usersRequest = () => {
    return {
        type:USERS_REQUEST
    }
}

const usersSuccess = (users,currentPage,totalPage,total) => {
    return {
        type:USERS_SUCCESS,
        users,
        currentPage,
        totalPage,
        total
    }
}

const usersFail = () => {
    return {
        type:USERS_FAIL
    }
}

const userSuccess = () => {
    return {
        type:USER_SUCCESS
    }
}

const userRequest = () => {
    return {
        type:USER_REQUEST
    }
}

const userFail = () => {
    return {
        type:USER_FAIL
    }
}

const transactionHistoryRequest = () => {
    return {
      type: TRANSACTION_HISTORY_REQUEST
    }
}

const transactionHistorySuccess = () => {
    return {
      type: TRANSACTION_HISTORY_SUCCESS,
    }
}

const transactionHistoryFail = () => {
    return {
      type: TRANSACTION_HISTORY_FAIL,
    }
}

const adminNotesRequest = () => {
    return{
      type: ADMIN_NOTES_REQUEST,
    }
}

const adminNotesSuccess = () => {
    return{
      type: ADMIN_NOTES_SUCCESS,
    }
}

const adminNotesFail = () => {
    return{
      type: ADMIN_NOTES_FAIL,
    }
}

const adminNoteUpdateRequest = () => {
    return{
      type: ADMIN_NOTE_UPDATE_REQUEST,
    }
}

const adminNoteUpdateSuccess = () => {
    return{
      type: ADMIN_NOTE_UPDATE_SUCCESS,
    }
}

const adminNoteUpdateFail = () => {
    return{
      type: ADMIN_NOTE_UPDATE_FAIL,
    }
}

const adminNoteAddRequest = () => {
    return{
      type: ADMIN_NOTE_ADD_REQUEST,
    }
}

const adminNoteAddSuccess = () => {
    return{
      type: ADMIN_NOTE_ADD_SUCCESS,
    }
}

const adminNoteAddFail = () => {
    return{
      type: ADMIN_NOTE_ADD_FAIL,
    }
}

const adminNoteDeleteRequest = () => {
    return{
      type: ADMIN_NOTE_DELETE_REQUEST,
    }
}

const adminNoteDeleteSuccess = () => {
    return{
      type: ADMIN_NOTE_DELETE_SUCCESS,
    }
}

const adminNoteDeleteFail = () => {
    return{
      type: ADMIN_NOTE_DELETE_FAIL,
    }
}

export const getUsers= (page,filters,order,search_text) => {
    return (dispatch,getState) => {
        dispatch(usersRequest());
        return axios.get(api.filtered_user_list,{params:{
            token:getState().auth.token,page:page+1,...filters,order,search_text
        }})
        .then(response=> {
            dispatch(usersSuccess(response.data.users, response.data.page-1,response.data.total_page, response.data.total ));
        })
        .catch(error=>{
            dispatch(showError(error.message));
            dispatch(usersFail());
        });
    }
}


export const getUser = id => {
    return (dispatch,getState) => {
        dispatch(userRequest());
        return axios.get(api.get_user,{params:{
            token:getState().auth.token,
            id
        }})
        .then(res=>{
            dispatch(userSuccess());
            if(id === getState().auth.user.id) {

            dispatch(signInSuccess(getState().auth.token,res.data.user))
            localStorage.setItem("user", JSON.stringify(res.data.user));
        }
            return res.data.user
        })
        .catch(error=> {
            dispatch(userFail());
            dispatch(showError(error.message));
        });
    }
}

export const getTransactionHistory = (id) => {
    return (dispatch, getState) => {
      dispatch(transactionHistoryRequest());
      return axios.get(api.transaction_history, {params: {
        token: getState().auth.token,
        id
      }}).then(res => {
        dispatch(transactionHistorySuccess());
        return res.data
      }).catch(error => {
        dispatch(transactionHistoryFail())
        dispatch(showError(error.message));
      });
    }
}

const inviteToFamilyRequest = ()=>{
    return {
        type:INVITE_TO_FAMILY_REQUEST
    }
}

const inviteToFamilySuccess = user => {
    return {
        type:INVITE_TO_FAMILY_SUCCESS,
        user
    }
}

const inviteToFamilyFail = () => {
    return {
        type:INVITE_TO_FAMILY_FAIL
    }
}



export const inviteToFamily = email => {
    return (dispatch,getState) => {
        dispatch(inviteToFamilyRequest());
        return axios.post(api.invite_to_family,{token:getState().auth.token,email})
        .then(res=>{
            dispatch(inviteToFamilySuccess());
            return res.data;
        })
        .catch(error=>{
            dispatch(inviteToFamilyFail());
            dispatch(showError(error.message));
        })
    }
}

const removeFromFamilyRequest = () => {
    return {
        type:REMOVE_FROM_FAMILY_REQUEST
    }
}

const removeFromFamilySuccess = () => {
    return {
        type:REMOVE_FROM_FAMILY_SUCCESS
    }
}

const removeFromFamilyFail = () => {
    return {
        type:REMOVE_FROM_FAMILY_FAIL
    }
}

export const removeFromFamily = id => {
    return (dispatch,getState) => {
        dispatch(removeFromFamilyRequest());
        return axios.post(api.remove_from_family,{token:getState().auth.token,id})
        .then(response=>{
            dispatch(removeFromFamilySuccess());
            return response;
        })
        .catch(error=>{
            dispatch(removeFromFamilyFail());
            dispatch(showError(error.message));
        });

    }
}

export const getAdminNotes = (id) => {
    return (dispatch, getState) =>  {
      dispatch(adminNotesRequest());
      return axios.get(api.admin_notes, {params: {
        token: getState().auth.token,
        user_id: id
      }}).then(res => {
        dispatch(adminNotesSuccess());
        return res.data
      }).catch(error => {
        dispatch(adminNotesFail());
        dispatch(showError(error.message));
      });
    }
}

export const updateAdminNote = (data) => {
    return (dispatch, getState) => {
      dispatch(adminNoteUpdateRequest());
      return axios.post(api.update_admin_note, {
        token: getState().auth.token,
        ...data
      }).then(res => {
        dispatch(adminNoteUpdateSuccess());
        return res.data
      }).catch(error => {
        dispatch(adminNoteUpdateFail());
        dispatch(showError(error.message));
      });
    }
}

export const deleteAdminNote = (id) => {
    return (dispatch, getState) => {
      dispatch(adminNoteDeleteRequest());
      return axios.post(api.delete_admin_note, {
        token: getState().auth.token,
        id
      }).then(res => {
        dispatch(adminNoteDeleteSuccess());
        return res.data
      }).catch(error => {
        dispatch(adminNoteDeleteFail());
        dispatch(showError(error.message));
      });
    }
}

export const addAdminNote = (data) => {
    return (dispatch, getState) => {
      dispatch(adminNoteAddRequest());
      return axios.post(api.add_admin_note, {
        token: getState().auth.token,
        ...data
      }).then(res => {
        dispatch(adminNoteAddSuccess());
        return res.data
      }).catch(error => {
        dispatch(adminNoteAddFail());
        dispatch(showError(error.message));
      });
    }
}
const usersTableRequest = () =>{
    return {
        type:USERS_TABLE_REQUEST
    }
}
const usersTableSuccess = () =>{
    return {
        type:USERS_TABLE_SUCCESS
    }
}
const usersTableFail = () =>{
    return {
        type:USERS_TABLE_FAIL
    }
}
export const getUsersTable = (filters,order,search_text,locale) => {
    return (dispatch, getState) => {
        dispatch(usersTableRequest());
        return axios.get(api.get_user_table,{params:{
            token:getState().auth.token,...filters,order,search_text,locale
        },responseType:"blob"})
        .then(response=>{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'users.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
            dispatch(usersTableSuccess());
        })
        .catch(error=>{
            dispatch(showError(error.message));
            dispatch(usersTableFail());
        })
    }
}

const addUserRequest = () => {
    return {
        type :ADD_USER_REQUEST
    }
}

const addUserSuccess = user => {
    return {
        type: ADD_USER_SUCCESS,
        user
    }
}

const addUserFail = () => {
    return {
        type:ADD_USER_FAIL
    }
}

export const addUser = user => {
    return (dispatch,getState) => {
        dispatch(addUserRequest());
        axios.post(api.add_user,{
            ...user,token:getState().auth.token
        })
        .then(response=> {
            dispatch(addUserSuccess(response.data))
        })
        .catch(error=>{
            dispatch(addUserFail());
            dispatch(showError(error.message));
        })
    }
}

const searchUsersRequest = () => {
    return {
        type:SEARCH_USERS_REQUEST
    }
}
const searchUsersSuccess = () => {
    return {
        type:SEARCH_USERS_SUCCESS
    }
}

const searchUsersFail = () => {
    return {
        type:SEARCH_USERS_FAIL
    }
}

export const searchUsers= (search_text,page) => {
    return (dispatch,getState) => {
        dispatch(searchUsersRequest());
        return axios.get(api.filtered_user_list,{params:{
            token:getState().auth.token,page,search_text
        }})
        .then(response=> {
            dispatch(searchUsersSuccess());
            return response.data;
        })
        .catch(error=>{
            dispatch(showError(error.message));
            dispatch(searchUsersFail());
        });
    }
}

const editUserRequest = () => {
    return {
        type :EDIT_USER_REQUEST
    }
}

const editUserSuccess = user => {
    return {
        type: EDIT_USER_SUCCESS,
        user
    }
}

const editUserFail = () => {
    return {
        type: EDIT_USER_FAIL
    }
}

export const editUser = (user) => {
    return (dispatch,getState) => {
        dispatch(editUserRequest());
        return axios.post(api.edit_user,{
            ...user, token:getState().auth.token
        })
        .then(response=> {
            dispatch(editUserSuccess(response.data.user));
            return response.data.user
        })
        .catch(error=>{
            dispatch(editUserFail());
            dispatch(showError(error.message));
        })
    }
}

const getProfileCardGameSummaryRequest = () => {
    return {
        type :GET_PROFILE_CARD_GAME_SUMMARY_REQUEST
    }
}

const getProfileCardGameSummarySuccess = () => {
    return {
        type: GET_PROFILE_CARD_GAME_SUMMARY_SUCCESS
    }
}

const getProfileCardGameSummaryFail = () => {
    return {
        type: GET_PROFILE_CARD_GAME_SUMMARY_FAIL
    }
}

export const getProfileCardGameSummary = (user_id) => {
    return (dispatch,getState) => {
        dispatch(getProfileCardGameSummaryRequest());
        return axios.get(api.get_profile_card_game_summary,{params:{
            user_id, token:getState().auth.token
        }})
        .then(response=> {
            dispatch(getProfileCardGameSummarySuccess());
            return response.data
        })
        .catch(error=>{
            dispatch(getProfileCardGameSummaryFail());
            dispatch(showError(error.message));
        })
    }
}

const playLimitRequest = () => ({
    type:PLAY_LIMIT_REQUEST
});

const playLimitFail = () => ({
    type: PLAY_LIMIT_FAIL
});

const playLimitSuccess = () => ({
    type: PLAY_LIMIT_SUCCESS
})

export const setPlayLimits = (playLimits) => (dispatch, getState) => {
    dispatch(playLimitRequest());
    return axios.post(api.set_play_limits,{
        play_limits:playLimits,
        token:getState().auth.token
    }).then(response=> {
        dispatch(playLimitSuccess());
        return response.data;
    }).catch(error=> {
        dispatch(playLimitFail());
    });
}

const restrictGamesRequest = () => ({
    type:RESTRICT_GAMES_REQUEST
});

const restrictGamesSuccess = () => ({
    type:RESTRICT_GAMES_SUCCESS
});

const restrictGamesFail = () => ({
    type: RESTRICT_GAMES_FAIL
});

export const restrictGames = (restrictedGames) => (dispatch, getState) => {
    dispatch(restrictGamesRequest());
    return axios.post(api.restrict_games, {
        data:restrictedGames, token: getState().auth.token
    }).then(response => {
        dispatch(restrictGamesSuccess());
        return response.data;
    }).catch(error=> {
        dispatch(restrictGamesFail());
    })
}