import React, {Component} from 'react';
import {Switch,Route,Redirect,withRouter} from 'react-router-dom';
import {Snackbar} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import Layout from './hoc/Layout';
import Info from './containers/Info';
import Profile from './containers/Info/Profile';
import Login from './containers/Login';
import ResetPassword from './containers/Login/ResetPassword';
import {connect} from 'react-redux';
import {autoSignIn, hideError} from './store/actions';
import {withNamespaces} from 'react-i18next';
import About from './containers/About';
import './App.css';
import UserDataDeletionPage from './containers/PrivacyPolicy/UserDataDeletionInstructions';
import PrivacyPolicyPage from './containers/PrivacyPolicy/PrivacyPolicy';

class App extends Component {
    state = {
        open: true
    }
    
  render() {
    const path = this.props.user && this.props.user.is_corp_admin ? '/info' : '/profile'
    return (
      <>
        {
          this.props.user ?
            <Layout location={this.props.location}>
              <Switch>
                  <Route path="/info" component={Info}/>
                  <Route path="/profile" component={Profile}/>
                  <Route path="/about" component={About} />
                  <Redirect from="/" to={path} />
                  <Route component={()=><p>Not Found</p>} />
              </Switch>
            </Layout>
            :
            <>
              <Switch>
                <Route path="/login" exact component={Login}/>
                <Route path="/user_data_deletion" component={UserDataDeletionPage} />
                <Route path="/privacy_policy" component={PrivacyPolicyPage} />
                <Route path="/reset_password/:email/:validation_code" exact component={ResetPassword}/>
                <Redirect from='*' to="/login"/>
              </Switch>
            </>
       }
      <Snackbar
        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
        autoHideDuration={8000}
        open={!!this.props.errorMessage}
        message={<span>{this.props.errorMessage}</span>}
        onClose={this.props.hideError}
        action={
          [<CloseIcon
            key="close"
            style={{cursor: "pointer", color: "red"}}
            onClick={this.props.hideError}/>]
          }
      />
    </>
   );
  }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        errorMessage: state.error.message
    }
}
const mapDispatchToProps = dispatch => {
    return {
        autoSignIn: () => dispatch(autoSignIn()),
        hideError: () => dispatch(hideError())
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withNamespaces('common')(App)));
