import React, { Component } from "react";
import { connect } from "react-redux";
import { getCorporationListSummary } from "../../../store/actions";
import {
  Grid,
  Card,
  Typography,
  CircularProgress
} from "@material-ui/core";
import CorporationCard from "../../../components/Info/CorporationCard";
import { withI18n } from "react-i18next";
class Corporations extends Component {
  componentDidMount() {
    if (!this.props.corporations[0]) this.props.getCorporationListSummary();
  }
  render() {
    if (this.props.corporationsLoading) return (<Card className="shadow p-4 d-flex justify-content-center "><CircularProgress /></Card>)
    const { t } = this.props;
    return (
      <Card className=" card shadow p-4" style={{overflow:"visible"}}>
        <Typography className="ml-2" variant="h6" gutterBottom>
          {t('corporations')}
        </Typography>
        <Grid container>
          {this.props.corporations.map(corporation => {
            return (
              <CorporationCard
                t={this.props.t}
                key={corporation.id}
                corporation={corporation}
              />
            );
          })}
        </Grid>
      </Card>
    );
  }
}
const mapStateToProps = state => {
  return {
    corporations: state.corporations.corporationsSummary,
    corporationsLoading: state.loading["CORPORATION_LIST_SUMMARY"]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCorporationListSummary: () => dispatch(getCorporationListSummary())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withI18n()(Corporations));
