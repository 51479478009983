import React, {Component} from 'react'
import {withNamespaces} from 'react-i18next';
import {updateAdminNote, addAdminNote, deleteAdminNote} from '../../store/actions/users';
import {connect} from 'react-redux';
import moment from 'moment';

class AdminNote extends Component {
  state = {
    userId: this.props.data.userId,
    id: this.props.data.id ,
    title: this.props.data.title || '',
    text: this.props.data.text || '',
    updateDate: this.props.data.updated_at,
    isDisabled: !this.props.data.userId,
  };

  editNote = () => {
    this.setState(prevState => {return {isDisabled: !prevState.isDisabled}})
  }

  deleteNote = () => {
    this.props.deleteAdminNote(this.state.id).then(data => this.props.onComplete(data, 'delete'));
    this.props.onClose();
  }

  saveNote = () => {
    this.state.userId ?  this.props.addAdminNote({user_id: this.state.userId, title: this.state.title, text: this.state.text}).then(data => this.props.onComplete(data, 'add'))
    : this.props.updateAdminNote({id:this.state.id, title: this.state.title, text: this.state.text}).then(data => this.props.onComplete(data, 'update'))

    this.props.onClose();
  }

  changeHandler = (e) => {
    const {value, name} = e.target;
    this.setState({[name]:value});
  }

  render (){
    const {t} = this.props;
    const {title, text, isDisabled, updateDate} = this.state;
    return(
      <div className = 'modal-dialog modal-dialog-centered' tabIndex="-1">
        <div className = 'modal-content p-4' style = {{borderRadius: '12px', minWidth: '40rem'}}>
          <div className = "align-items-baseline d-flex justify-content-between mb-2">
              <div className= "d-inline-block h5 font-weight-bold mb-0" style = {{color: '#374B59'}}>
                {t('admin_note')}
              </div>
              <div style = {{fontSize: '0.9rem', color: '#8C8C8C'}}>
                {t('creation_date')}: {moment(updateDate).format("DD.MM.YYYY - HH:mm")}
              </div>
          </div>
          <input type = 'text' disabled = {isDisabled} className = 'form-control p-3 my-2' name = 'title' value = {title} onChange = {this.changeHandler} style = {{ backgroundColor: isDisabled ? '#F5F7F9' : '#fff', borderRadius: '5px'}}/>
          <textarea type = 'text' disabled = {isDisabled} className = 'form-control p-3 my-2' name = 'text' value = {text} onChange = {this.changeHandler} style = {{ backgroundColor: isDisabled ? '#F5F7F9' : '#fff', borderRadius: '5px', minHeight: '10rem'}}/>
          <div className = "d-flex my-3">
            <button  type="button" className ="btn btn-info mr-2" style = {{backgroundColor: '#24738C', borderColor: '#24738C', fontWeight: '800'}} onClick = {() => this.editNote()}> {t('edit')} </button>
            <button type="button" className ="btn btn-info" style = {{backgroundColor: '#24738C', borderColor: '#24738C', fontWeight: '800'}} onClick = {() => this.deleteNote()} >{t('delete')}</button>
            {!isDisabled && <button type="button"  className ="btn btn-info ml-auto" style = {{backgroundColor: '#00BC9D ', borderColor: '#00BC9D', fontWeight: '800'}} onClick = {() => this.saveNote()}>{t('save')}</button>}
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return{
    addAdminNote: data => dispatch(addAdminNote(data)),
    deleteAdminNote: id => dispatch(deleteAdminNote(id)),
    updateAdminNote: data => dispatch(updateAdminNote(data)),
  }
}

export default connect(null, mapDispatchToProps)(withNamespaces('common')(AdminNote));
