import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
   root:{
    maxWidth:800,
    margin:"auto"
   },
   padded:{
       paddingLeft:16,
       paddingRight:16
   }
});

const datateam = [
    {
        name: "Emrehan Halıcı",
        position: "ODTÜ Elektrik-Elektronik Mühendisliği",
        image: "/images/about/7479.png"
    },
    {
        name: "Eren Halıcı",
        position: "ODTÜ Elektrik-Elektronik Mühendisliği",
        image: "/images/about/7483.png"
    },
    {
        name: "Yunus Emre Akbaba",
        position: "ODTÜ Bilgisayar Mühendisliği",
        image: "/images/about/7487.png"
    },
    {
        name: "Sefa Kocaağa",
        position: "Hava Harp Okulu Bilgisayar Mühendisliği",
        image: "/images/about/7513.png"
    },
    {
        name: "Yunus Eren Güzel",
        position: "Bilkent Üniversitesi Bilgisayar Mühendisliği",
        image: "/images/about/7509.png"
    },
    {
        name: "Özlem Çağlar",
        position: "Başkent Üniversitesi Yönetim Bilişim Sistemleri",
        image: "/images/about/7505.png"
    },
    {
        name: "Gökhan Bozdağ",
        position: "Çukurova Üniversitesi Bilg. Tek. ve Prog.",
        image: "/images/about/7521.png"
    },
    {
        name: "Murat Şahin",
        position: "Anadolu Ünv. Görsel İşitsel Teknikler ve Medya Yapımcılığı",
        image: "/images/about/7517.png"
    },
    {
        name: "Mahmut Sami Acar",
        position: "Hacettepe Üniversitesi Grafik",
        image: "/images/about/7525.png"
    },
    {
        name: "Nihal Sandıkçı",
        position: "Dokuz Eylül Üniversitesi İİBF",
        image: "/images/about/7503.png"
    },
    {
        name: "Ezgi Karaşin",
        position: "ODTÜ İşletme",
        image: "/images/about/7499.png"
    },
    {
        name: "Meral Ayduğan",
        position: "Anadolu Üniversitesi İktisat",
        image: "/images/about/7495.png"
    },
    {
        name: "Özge Anaç Avnamak",
        position: "ODTÜ Sosyoloji",
        image: "/images/about/7491.png"
    },
    {
        name: "Kader Kahriman",
        position: "Gazi Üniversitesi Edebiyat Öğretmenliği",
        image: "/images/about/7529.png"
    },


]

const dataacademic = [

    {
        name: "Ferhunde Öktem",
        position: "Klinik Psikoloji",
        image: "/images/about/7449.png",
        title:"Prof.Dr."
    },
    {
        name: "Sait Uluç",
        position: "Klinik Psikoloji",
        image: "/images/about/7452.png",
        title:"Doç.Dr."
    },
    {
        name: "Ayşegül Ataman",
        position: "Özel Eğitim",
        image: "/images/about/7446.png",
        title:"Prof.Dr."
    },
    {
        name: "Soner Yıldırım",
        position: "Öğretim Teknolojileri",
        image: "/images/about/7455.png",
        title:"Prof.Dr."
    },
    {
        name: "Uğur Halıcı",
        position: "Yapay Zeka",
        image: "/images/about/7467.png",
        title:"Prof.Dr."
    },
    {
        name: " Oya Yerin Güneri",
        position: "Psikolojik Danışmanlık ve Rehberlik",
        image: "/images/about/7464.png",
        title:"Prof.Dr."
    },
    {
        name: "Yeşim Çapa Aydın",
        position: "Ölçme Değerlendirme",
        image: "/images/about/7458.png",
        title:"Doç.Dr."
    },
    {
        name: "Serap Emil",
        position: "Eğitim Yönetimi",
        image: "/images/about/7461.png",
        title:"Dr.Öğrt. Üyesi"
    }

]
const datatest = [

    {

        name: "Aziz Ateş",
        position: "Odtü Matematik",
        image: "/images/about/7544.png",
        
    },
    {

        name: "Fatih SUlak",
        position: "Atılım Ünv. Matematik",
        image: "/images/about/7545.png",
        title: "Dr. Öğrt. Üyesi"
    },
    {

        name: "Hasan Yurtoğlu",
        position: "ODTÜ İnşaat Mühendisliği",
        image: "/images/about/7546.png",
        
    },
]
function FullWidthGrid(props) {
    const { classes } = props;

    return (
        <div className={classes.root }>
            <div className={classes.padded}>
            <h4 >Biz Kimiz?</h4>
            <p>1985 yılında kurulan <b>HALICI Bilgi İşlem A.Ş.</b>, ülkemizin bilişim ve yazılım alanlarındaki öncü kuruluşlarından biri olup, donanım ve yazılım alanlarında kamu ve özel sektör projelerinden bireysel kullanımlara kadar geniş bir yelpazede ürün ve hizmet geliştirmektedir.</p>
            <p>Uzun yıllara dayanan bilgi birikimi ve tecrübesini, teknoloji ve yenilikteki liderliği ile birleştiren Halıcı, yazılım sektörünün ülkemizdeki gelişimine önemli katkılar sunmuştur.</p>
            <p>Türkiye’nin ilk Teknopark girişimini yapan Halıcı, 2001 yılında ODTÜ-Halıcı Yazılımevi’ni hizmete açmış, Teknoloji Geliştirme Bölgelerinin oluşmasında öncü bir rol üstlenmiştir. Halıcı Eğitim Yazılımları, eğitimin teknolojiyle buluşması ve teknolojinin eğitimde daha verimli kullanılabilmesinde önemli bir yere sahiptir. Halıcı tarafından geliştirilen eğitim yazılımları, Milli Eğitim Bakanlığı’nın Dünya Bankası tarafından desteklenen Temel Eğitim Geliştirme Projesi’nde tercih edilmiş ve öğrencilerin kullanımına sunulmuştur.</p>
            <p>“Çoklu Ortam” teknolojisinin ülkemizde gelişmesi, özellikle eğitim ve oyun alanında daha çok kullanılması için de çalışmalar yapan HALICI, son yıllarda “Etkileşimli Eğitim ve Zeka Oyunları Yazılımları” alanı da dâhil olmak üzere pek çok başarılı projeyi hayata geçirmiştir.</p>
            </div>
            <h4 className={classes.padded}>Ekibimiz</h4>
            <Team data={datateam} />
            <h4 className={classes.padded}>Akademik Kadro</h4>
            <Team data={dataacademic} />
            <h4 className={classes.padded}>Test Ekibi</h4>
            <Team data={datatest} />
        </div>

        
    );
}

FullWidthGrid.propTypes = {
    classes: PropTypes.object.isRequired,
};


const Team = ({data}) => (<Grid
    container
    spacing={32}
    style={{ maxWidth: 800, margin: "auto" }}>
    {
        data.map((person, index) =>
        <Grid item xs={12} sm={6} md={3} key={index} >
                <img src={person.image} alt={person.name} width="100%"/>
                {person.title && <div style={{textAlign:"center", fontSize:16 , color:"#4A4B4B"}}>{person.title}</div>}
                <div style={{textAlign:"center", fontSize:16 , color:"#00A0B4", fontWeight:"bold"}}> {person.name}  </div>
                <div  style={{textAlign:"center", fontSize:12 , color:"#4A4B4B" , margin:"0.3em 3em 1em"}}> {person.position}  </div>
            </Grid>
        )
    }

</Grid>)
    export default withStyles(styles)(FullWidthGrid);