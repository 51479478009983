import React, { Component } from 'react';
import { Card } from '@material-ui/core';
import {
  PagingState,
  CustomPaging,
  DataTypeProvider
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';

const columns = [{ name: "first_name" }]

class FilteredUserList extends Component {
  state = {
    totalCount: 0,
    pageSize: 25,
  }
  nameFormatter = (props) => {
    return (
      <div 
        className={["DivisionSelect",props.row.isActive?"Active":""].join(" ")} onClick={() => this.props.toggleSelect(props.row)}> {` ${props.row.first_name} ${props.row.last_name}`}</div>
    )
  }

  render() {
    const { currentPage, currentPageChange, totalCount, users } = this.props;
    return (
      <Grid
        rows={users}
        columns={columns}>
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={currentPageChange}
          pageSize={30}

        ></PagingState>
        <PagingPanel messages={{ info: "" }} />
        <CustomPaging 
          totalCount={totalCount}
        />
        <DataTypeProvider for={["first_name"]} formatterComponent={this.nameFormatter} />
        <Table />

      </Grid>
    )
  }
}

export default FilteredUserList;