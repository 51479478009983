import React, {PureComponent} from 'react';
import {SortingState} from '@devexpress/dx-react-grid';
import {Grid, Table, TableHeaderRow} from '@devexpress/dx-react-grid-material-ui';

class SortableList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
      ],
      rows:[
      ],
      sorting:[]
    };
  }
  sortingChange = sorting =>  this.setState({sorting})
  rowComponent = (props) => (<Table.Row {...props}/>)

  tableCellComponent = (props) => (<Table.Cell {...props}/>)

  tableComponent = (props) => (<Table.Table {...props}/>)

  tableHeaderCellComponent = (props) => (<TableHeaderRow.Cell {...props}/>)

  getRows = () => {
    const rows = this.props.rows || [];
    const sortingRule = this.state.sorting[0];
    return !sortingRule?rows:
      rows.sort((row1,row2) => sortingRule.direction==="asc"? row1[sortingRule.columnName]-row2[sortingRule.columnName]:
                                                              row2[sortingRule.columnName]-row1[sortingRule.columnName])

  }
  getTotal = (arr) => {
    const total = arr.reduce((acc,row) => {
      return Object.keys(row).reduce((acc,key)=>{
          acc[key] = acc[key] ? acc[key] + (+row[key]) : (+row[key]);
          return acc;
      },acc)
    },{})
    total.name = "total";
    this.props.avgColumns && this.props.avgColumns.forEach(column => {
        total[column] /= arr.length;
    })
    return total;
  }
  render() {
    const { columns,sorting } = this.state;
    const rows = this.getRows();
    const withTotal = [...rows,this.getTotal([...rows])];
    return (
      <Grid
        rows={withTotal}
        columns={this.props.columns || columns}
      >

        {this.props.dataTypeProvider}
        <SortingState
          sorting={sorting}
          onSortingChange={this.sortingChange}
        />
        <Table
          rowComponent = {this.props.rowComponent || this.rowComponent}
          cellComponent = {this.props.tableCellComponent || this.tableCellComponent}
          columnExtensions = {this.props.columnExtensionsTable || null}
          tableComponent={this.props.tableComponent || this.tableComponent}
        />
        <TableHeaderRow
          showSortingControls
          cellComponent = {this.props.tableHeaderCellComponent  || this.tableHeaderCellComponent}
        />
      </Grid>
    );
  }
}

export default SortableList;
