import moment from 'moment';

export const bgColors = [
  '#FF6384', //Red
  '#00CED1', //Blue
  '#32CD32', //Green
  '#9370DB', //Violet
  '#FFCE56', //Yellow
  '#C60072',
  '#a9b3ae', //Grey
  '#FFA500',
  '#7D5630', //Brown
  '#D10000',
  '#00FF7F',
  '#52243B',
  '#EE82EE',
  '#75292C',
  '#1E90FF',
  '#6A8407',
  '#080A97',
  '#4100BA',
  '#129F9F',
  '#FFD700',
  '#00FF7F',
  '#008B8B'
];

export const months = (t) => {
  let arr = [];
  for (let i = 11; i > -1; i--) {
    arr.push(t("months." + moment().locale('en').subtract(i, 'months').format('MMMM')));
  }
  return arr
}

export const days = () => {
  let arr = [];
  for (let i = 14; i > -1; i--) {
    arr.push(moment().subtract(i, 'days').format('DD.MM.YYYY'));
  }
  return arr
}

export const chartConfig = {
  scales: {
    yAxes: [{
      ticks: { beginAtZero: true }
    }],
    xAxes: [
      { ticks: { autoSkip: false } }
    ]
  },
  legend: {
    display: false,
  },
  tooltips: {
    mode: 'index',
    intersect: false,
    callbacks: {
      label: function (tooltipItem, data) {
        var label = data.datasets[tooltipItem.datasetIndex].label || '';
        if (label) { label += ': '; }
        label += tooltipItem.yLabel.toFixed(2);
        return label;
      }
    }
  },
  hover: {
    mode: 'index',
    intersect: false,
    callbacks: {
      label: function (tooltipItem, data) {
        var label = data.datasets[tooltipItem.datasetIndex].label || '';
        if (label) { label += ': '; }
        label += tooltipItem.yLabel.toFixed(2);
        return label;
      }
    }
  }
}