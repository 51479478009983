import html2pdf from 'html2pdf.js';


export const print = ({  }) => {
  
  const element = document.getElementById('toPDF');
  html2pdf(element).save();
  
}

