import React, { Component } from "react";
import { Card } from "@material-ui/core";
import { connect } from "react-redux";
import { addUser } from "../../../../store/actions";
import { formDataUpdate } from "../../../../utils/formValidate";
import { SingleDatePicker, isInclusivelyBeforeDay } from "react-dates";
import moment from "moment";
import { withI18n } from "react-i18next";

class AddUser extends Component {
  state = {
    formElements: {
      first_name: {
        value: "",
        validityRules: { minLength: 1 }
      },
      last_name: {
        value: "",
        validityRules: { minLength: 1 }
      },
      email: {
        value: "",
        validityRules: { isEmail: true }
      },
      premium_end_at: { value: null },
      mail_language: { value:"tr" },
      use_type: {value: 'guest'}
    },
  };

  formElementChangedHandler = event => {
    let { name, value } = event.target;
    this.setState(prevState => {
      return {
        formElements: formDataUpdate(prevState.formElements, value, name)
      };
    });
  };

  dateChangeHandler = date => {
    this.setState(prevState => {
      return {
        formElements: formDataUpdate(
          prevState.formElements,
          date,
          "premium_end_at"
        )
      };
    });
  };

  datePickerFocused = ({ focused }) =>
    this.setState(prevState => {
      return {
        formElements: {
        ...prevState.formElements,
        premium_end_at: {
          ...prevState.formElements["premium_end_at"],
          focused
        }
      }
    };
  });

  addUser = () => {
    const user = {
      first_name: this.state.formElements.first_name.value,
      last_name: this.state.formElements.last_name.value,
      email: this.state.formElements.email.value.trim(),
      mail_language:this.state.formElements.mail_language.value,
      premium_end_at:this.state.formElements.premium_end_at.value
      ? new Date(this.state.formElements.premium_end_at.value._d)
      : new Date(),
      use_type : this.state.formElements.use_type.value
    };
      this.props.addUser(user);
  };

  checkFormValidity = () => {
    const { first_name, last_name, email } = this.state.formElements;
    return [first_name, last_name, email].every(parameter => parameter.isValid) && !this.props.addUserLoading;
  };
  render() {
    const {t} = this.props;
    let membershipTypes = t('membershipType', {returnObjects: true});
    return (
      <Card className="AddUser shadow mb-4 p-4" style={{ overflow: "visible" }}>
        <form onSubmit={e => e.preventDefault()}>
          <div className = "row">
            <div className ='col-7 row'>
              <div className = "col p-1">
                <input
                  type="text"
                  placeholder={t('addUser.first_name')}
                  name="first_name"
                  className={`form-control ${
                    this.state.formElements.first_name.touched
                      ? this.state.formElements.first_name.isValid
                        ? "is-valid"
                        : "is-invalid"
                      : ""
                  }`}
                  onChange={this.formElementChangedHandler}
                  value={this.state.formElements.first_name.value}
                />
              </div>
              <div className = "col p-1">
                <input
                  type="text"
                  placeholder={t('addUser.last_name')}
                  name="last_name"
                  className={`form-control ${
                    this.state.formElements.last_name.touched
                      ? this.state.formElements.last_name.isValid
                        ? "is-valid"
                        : "is-invalid"
                      : ""
                  }`}
                  onChange={this.formElementChangedHandler}
                  value={this.state.formElements.last_name.value}
                />
              </div>
              <div className = "col p-1">
                <input
                  type="text"
                  name="email"
                  placeholder={t('email')}
                  className={`form-control ${
                    this.state.formElements.email.touched
                      ? this.state.formElements.email.isValid
                        ? "is-valid"
                        : "is-invalid"
                      : ""
                  }`}
                  onChange={this.formElementChangedHandler}
                  value={this.state.formElements.email.value}
                />
              </div>
            </div>

            <div className = 'col-5 row' >
               <div className = "col-4 p-1">
                <select
                  className="form-control custom-select"
                  name="use_type"
                  onChange={this.formElementChangedHandler}
                  value={this.state.formElements.use_type.value}
                >
                  {Object.keys(membershipTypes).map(type =>
                      <option key = {type} value = {type}>{membershipTypes[type]}</option>
                  )}
                </select>
              </div>
              <div className = "col-3 p-1">
                <SingleDatePicker
                  focused={this.state.formElements.premium_end_at.focused}
                  onFocusChange={focus =>
                    this.datePickerFocused(focus, "premium_end_at")
                  }
                  numberOfMonths={1}
                  date={this.state.formElements.premium_end_at.value}
                  block
                  small
                  regular
                  displayFormat="DD/MM/YYYY"
                  id="premium_end_at"
                  hideKeyboardShortcutsPanel
                  placeholder={t("corporations_page.end_date")}
                  isOutsideRange={day => isInclusivelyBeforeDay(day, moment())}
                  onDateChange={this.dateChangeHandler}
                />
              </div>
              <div className = 'col-3 p-1' >
                <select
                  className="form-control custom-select"
                  name="mail_language"
                  onChange={this.formElementChangedHandler}
                  value={this.state.formElements.mail_language.value}
                >
                  <option value="en">English</option>
                  <option value="tr">Türkçe</option>
                </select>
              </div>
              <div className =  'col-2 p-1' >
                <button
                  onClick={this.addUser}
                  disabled={!this.checkFormValidity()}
                  className="btn btn-primary ButtonGroupItem btn-block"
                >
                  {t('add')}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    addUserLoading : state.loading['ADD_USER']
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addUser: user => dispatch(addUser(user))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withI18n()(AddUser));
