import {createStore,combineReducers,compose,applyMiddleware} from 'redux';
import thunk from 'redux-thunk';

import authReducer from './reducers/auth';
import loadingReducer from './reducers/loading';
import errorReducer from './reducers/error';
import usersReducer from './reducers/users';
import statisticsReducer from './reducers/statistics';
import corporationsReducer from './reducers/corporations';
import purchaseReducer from './reducers/purchase';
import inventoryReducer from './reducers/inventoryCoupon';

const rootReducer = combineReducers({
    auth:authReducer,
    loading:loadingReducer,
    error:errorReducer,
    users:usersReducer,
    statistics:statisticsReducer,
    corporations:corporationsReducer,
    purchase:purchaseReducer,
    inventory:inventoryReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () =>  createStore(rootReducer,composeEnhancers(applyMiddleware(thunk)));
