import React,{Component} from 'react';
import {List,ListItem,Checkbox,ListItemText, CircularProgress,Divider} from '@material-ui/core';
import Aux from '../../hoc/Aux2';
 import './style.css';

class FilterBar extends Component {

    handleToggle = filter => {
        !this.props.selectedFilters[filter.name]||!this.props.selectedFilters[filter.name].find(option=>option.value===filter.option.value)?
            this.props.onFilterAdd(filter)
            :
            this.props.onFilterRemove(filter)
    }
    render(){
        const {filterKeys,selectedFilters} = this.props;
        return (
        <>
        <br/>
        <div className="btn-group ButtonGroup align-baseline" >
            {filterKeys.map((filter,i)=>(
                <div key={i} className="btn-group" 
                onClick={
                    !filter.options[0]&&filter.getData?
                    filter.getData
                    :
                    null
                    }>
                    <button type="button" className="btn btn-primary dropdown-toggle btn-sm ButtonGroupItem mt-3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {filter.text}
                    </button>
                    <List className="dropdown-menu" style={{width:200}}>
                        {filter.loading&&<CircularProgress/>}
                        {filter.options.map(option=>(
                            <Aux key={option.value} >
                            <ListItem 
                                style={{padding:0}}
                                dense 
                                button 
                                onClick={()=>this.handleToggle({name:filter.name,option,singleSelect:filter.singleSelect})}>
                                <Checkbox
                                    className="py-1"
                                    disableRipple
                                    checked={Boolean(selectedFilters[filter.name]&&selectedFilters[filter.name].find(opt=>opt.value===option.value))}/>
                                <ListItemText primary={option.text} />
                            </ListItem>
                             <Divider light />
                             </Aux>
                        ))
                        }
                    </List>
                </div>))
            }
        </div>
        </>
);
    }
}

export default FilterBar;
