import React from 'react';
import {Chip} from '@material-ui/core';

export default (props) => {

    let style = {
        width:100,
        color:"white",
        fontWeight:"bold",
        backgroundColor:'#3A3A3A',
        ...props.style
    }
    switch(props.value){
        case 'guest':
        style={
            ...style,
            backgroundColor:"#7A7A7A",
        }
        break;
        case 'personal_member':
        case 'family_member' :
        style={
            ...style,
            backgroundColor:"#2DBA9C"
        }
        break;
        case 'school_member':
        style={
            ...style,
            backgroundColor:"#704789"
        }
        break;
        default:;
    }

    return <Chip {...props} style={style}/>
}