import React from 'react';
import { Typography } from '@material-ui/core';
import './style.css';

export default ({ labels, data, img, title, pageBreak }) => (
  <>
    {(title || img) && <div className="SimpleListHeader">
      {img && <img src={img} />}
      {title && <Typography variant="h5">{title}</Typography>}
    </div>}
    <table className="ReportSimpleList" border="1" style={
      pageBreak? {
      pageBreakAfter:"always"
    }:{}}>
      {labels && <thead>
        <tr>
          {labels.map((label, index) => (
            <th key={index}>{label.text}</th>
          ))}
        </tr>
      </thead>}
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            {
              labels.map((label, index) => (
                <td key={index}>{row[label.for]}</td>
              ))
            }</tr>
        ))}
      </tbody>
    </table>
  </>
)