import React, {Component} from 'react';
import {DateRangePicker} from 'react-dates';
import { withI18n } from 'react-i18next';


class CustomDateRangePicker extends Component {
    state={
        focusedInput:"startDate",
        date:[],
        show:false
    }

    onFocusChange=(focusedInput) =>{
        this.setState({ date:focusedInput==="startDate"?[]:[...this.state.date],focusedInput });
      }
    onDatesChange= dates=> {
        this.setState({date:[dates.startDate,dates.endDate]},()=>{
            this.state.date[0]
            &&this.state.date[1]
            &&this.dateSelectHandler()
        })
    }
    dateSelectHandler = () => {
        this.props.onDateSelect(
            {
                name:"date",
                option:{
                    value:[new Date(this.state.date[0].utc().format("MM/DD/YYYY")),new Date(this.state.date[1].utc().format("MM/DD/YYYY"))],
                    text:`${this.state.date[0].format("DD/MM/YYYY")} - ${this.state.date[1].format("DD/MM/YYYY")}`}
            });
        this.toggleShow();

    }
    clearDates= () => this.setState({date:[]});

    toggleShow = () => {
        this.setState(prevState=>{
            return {
                show:!prevState.show
            }
        })
    }
    render(){
        return <div className="btn-group align-baseline ml-2">
            <button type="button" className="btn btn-primary btn-sm dropdown-toggle ButtonGroupItem"
            onClick={this.toggleShow}>{this.props.text}</button>
            { this.state.show&&
            <DateRangePicker
                focusedInput={this.state.focusedInput}
                startDate={this.state.date[0]}
                endDate={this.state.date[1]}
                isOutsideRange= {()=>false}
                startDateId="startDate"
                endDateId="endDate"
                onFocusChange= {this.onFocusChange}
                onDatesChange= {this.onDatesChange}
                hideKeyboardShortcutsPanel
                small
                startDatePlaceholderText = {this.props.t("corporations_page.start_date")}
                endDatePlaceholderText = {this.props.t("corporations_page.end_date")}
            />}
        </div>

    }
}

export default withI18n()(CustomDateRangePicker);

