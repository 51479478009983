import React, {Component} from 'react';
import LoginWrapper from '../LoginWrapper'
import {connect} from 'react-redux';
import Lock from '@material-ui/icons/Lock';
import {withNamespaces} from 'react-i18next';
import {resetPassword} from '../../../store/actions';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import grey from '@material-ui/core/colors/grey';

class ResetPassword extends Component {
  state = {
    email: this.props.match.params.email,
    validationCode: this.props.match.params.validation_code,
    newPassword: '',
    confirmedPassword: '',
    resetSuccesful: false,
  }

  changeHandler = event => {
      this.setState({
          [event.target.name]:event.target.value
      });
  }

  componentDidMount(){
  }

  validate = () => {
    const {newPassword, confirmedPassword} = this.state;
    return newPassword === confirmedPassword;
  }

  submit = (e) => {
    e.preventDefault();
    const {email, validationCode, newPassword} = this.state;
    const creds = {email, newPassword, validationCode}
    let validated = this.validate();
    validated && this.props.resetPassword(creds).then(
      data => {
        if(data && data.result === 'successful') {
          this.setState({resetSuccesful: true});
          setTimeout(() => this.props.history.push('/login'),2000)
        }}
    )
  }

  render(){
    const {t} = this.props;
    return (
      <div>
        <Modal open={!!this.props.loading}>
              <CircularProgress className="Progress" size={80} style={{ color: grey[500] }}/>
        </Modal>
        <LoginWrapper>
          <div style = {{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
            <div style = {{maxWidth: '350px', margin: 'auto'}}>
              {this.state.resetSuccesful && <div style = {{textAlign: 'center', marginBottom: '10px'}}>Şifreniz başarıyla değiştirildi.</div>}
              <form className="LoginForm"  onSubmit = {this.submit}>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text InputIconSpan"><Lock/></span>
                  </div>
                  <input className="form-control LoginInput" name="newPassword" value = {this.state.newPassword} type = "password"
                    onChange={this.changeHandler} placeholder = {t('new-password')}/><br/>
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text InputIconSpan"><Lock/></span>
                  </div>
                    <input className="form-control LoginInput" name="confirmedPassword" value = {this.state.confirmedPassword} type = "password"
                       onChange={this.changeHandler}  placeholder = {t('new-password-confirm')}/><br/>
                </div>
                <button className="btn btn-primary" style = {{width: '100%', background: '#00BC9D', border: '0px',   borderRadius: '8px'}}>{t('send')}</button>
              </form>
            </div>
          </div>
        </LoginWrapper>
      </div>
  )}
}

const mapStateToProps= state => {
    return {
      loading: state.loading["RESET_PASSWORD"]
    }
}
const mapDispatchToProps = dispatch => {
    return {
      resetPassword: (creds) => dispatch(resetPassword(creds))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withNamespaces('common')(ResetPassword));
