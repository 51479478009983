import React, {Component} from 'react';
import {Switch, Route,Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import Users from './Users';
import Games from './Games';
import Coupons from './Coupons';
import InventoryCoupons from './InventoryCoupons';
import Profile from './Profile';
import Gamepad from '@material-ui/icons/Gamepad';
import UserCounts from './UserCounts';
import Corporations from './Corporations';
import AddCorporation from './Corporations/AddCorporation';
import AddUser from './Users/AddUser';
import {withI18n} from 'react-i18next'
import GradientSummaryCard from '../../components/GradientSummaryCard';
import {getSummaryData} from '../../store/actions';
import Corporation from './Corporations/Corporation';
import CorporationEdit from './Corporations/Edit';
import Statistics from '../Statistics/Statistics';
import NavigatorCard from '../../components/Info/NavigatorCard';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';

class Info extends Component {

  componentDidMount(){
    if(this.props.user.role === 1 && !this.props.summary) this.props.getSummaryData()
  }
  render() {
    const {t} = this.props;
    const summary = this.props.summary || {}
    return (<div className=".Content">
      <div className="row">

      {/* Parçalar hardcoded, degismesi lazim */}

        <div className="col-md-3 col-sm-4">
        <GradientSummaryCard sm
          description={this.props.t('lastAddedGame')+": Parçalar"}
          title={t('summary.game_count')}
          value={summary.game_count||24}
          icon={<Gamepad/>}/>
        <NavigatorCard basePath={this.props.match.path} location = {this.props.location} role={this.props.user.role}/></div>
        <div className="col-md-9 col-sm-8 mb-5">
          <div>
            <Switch>
              <PrivateRoute roles={[1]} path={this.props.match.path+ "/users/new"} component={AddUser}/>
              <Route path={this.props.match.path + "/users/:id"} component={Profile}/>
              <PrivateRoute roles={[1,101]} exact path={this.props.match.path + "/users"} component={Users}/>
              <PrivateRoute roles={[1]} exact path={this.props.match.path + "/games"} component={Games}/>
              <PrivateRoute roles={[1]} exact path={this.props.match.path + "/coupons"} component={Coupons}/>
              <PrivateRoute roles={[1]} exact path={this.props.match.path + "/inventory_coupons"} component={InventoryCoupons}/>
              <Route exact path={this.props.match.path + "/statistics/:user_id"} component={Statistics}/>
              <Route exact path={this.props.match.path + "/statistics"} component={Statistics}/>
              <PrivateRoute roles={[1]} exact path={this.props.match.path + "/userCounts"} component={UserCounts}/>
              <Route exact path={this.props.match.path + "/corporations"} component={Corporations}/>
              <Route exact path={this.props.match.path + "/corporations/new"} component={AddCorporation}/>
              <Route exact path={this.props.match.path + "/corporations/:id"} component={Corporation}/>
              <Route exact path={this.props.match.path + "/corporations/:id/edit"} component={CorporationEdit}/>
              
              <Redirect to={this.props.match.path+ "/corporations"}/>   
            
            </Switch>
          </div>
        </div>
      </div>
    </div>);
  }
}
const mapStateToProps = state => {
  return {
    summary:state.statistics.summary,
    user: state.auth.user
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getSummaryData: () => dispatch(getSummaryData())
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withI18n()(Info));
