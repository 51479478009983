import AppStoreTr from './appstore.png';
import AppStoreEn from './appstore-en.png';
import GooglePlayTr from './googleplay.png';
import GooglePlayEn from './googleplay-en.png';
import BrainquireTr from './lg-login.png';
import BrainquireEn from './lg-login-en.png';
import PhoneTr from './phone-lg-full.png';
import PhoneEn from './phone-lg-full-en.png';

export default {
  AppStoreTr,
  AppStoreEn,
  GooglePlayTr,
  GooglePlayEn,
  BrainquireTr,
  BrainquireEn,
  PhoneTr,
  PhoneEn
};
