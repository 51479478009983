import React, { Component } from "react";
import {
  Button,
  Card,
  Typography,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
  Collapse
} from "@material-ui/core";
import { withI18n } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PersonAdd from "@material-ui/icons/PersonAdd";
import Delete from "@material-ui/icons/DeleteForever";
import AccountCircle from '@material-ui/icons/AccountCircle';
import AddCorpUser from "../../../../../components/Info/AddCorpUser";
import { inventoryURL } from '../../../../../environment';
import { setPlayLimits, restrictGames } from "../../../../../store/actions";
import './style.css';

class CorpUserList extends Component {
  state = {
    addUserToggle: false,
    playLimitMode:false,
    playLimits:[],
    gameRestrictions:[],
    selectedGame:'',
    playLimitForAllUsers:''
  };

  static getDerivedStateFromProps(props, state){
    
    if(props.selectedDivisionIndex !== state.selectedDivisionIndex || props.users.length !== state.playLimits.length)
    {  
      return {
      selectedDivisionIndex:props.selectedDivisionIndex,
      playLimits:props.users.map(user=>({
      user_id:user.id,
      value:user.play_limit || ""
      })),
      gameRestrictions:props.users.map(user =>({
        user_id:user.id,
        games:user.restricted_games.slice()
      }))
    }
  }
    return null;
  }

  resetPlayLimits = () => this.setState({
    playLimits:this.props.users.map(user=>({
    user_id:user.id,
    value:user.play_limit || ""
    }))
  });

  resetGameRestrictions = () => this.setState({
    gameRestrictions:this.props.users.map(user =>({
      user_id:user.id,
      games:user.restricted_games.slice()
    }))
  })

  getDefaultPlayLimits = () => {
    this.setState({
      playLimits: this.props.users.map(user=>({
        user_id:user.id,
        value:user.play_limit || 0
      }))
    });
  }
  handlePlayLimit = (value, index) => this.setState(prevState=> ({
    playLimitForAllUsers:"",
    playLimits:prevState.playLimits.map((userLimit, userIndex) => {
      if(userIndex === index)
      return {
        ...userLimit,
        value
      }
      return userLimit;
    })
  }));
  handlePlayLimitForAllUsers = event => {
    const value = +event.target.value || '';
    this.setState(prevState=>({
      playLimitForAllUsers:value,
      playLimits:prevState.playLimits.map(userLimit=> ({
        ...userLimit, value
      }))
    }))
  }
  setPlayLimits = () => {
    const { selectedDivisionIndex } = this.props;

    this.props.setPlayLimits(this.state.playLimits.map(userLimit => ({...userLimit, value:userLimit.value||null}) ))
      .then(result => {
        if(result)
        this.props.onSetPlayLimitComplete(selectedDivisionIndex ,this.state.playLimits);
      });
  }
  restrictGames = () => {
    const { selectedDivisionIndex } = this.props;
    this.props.restrictGames(this.state.gameRestrictions)
    .then(result => {
      if(result)
      this.props.onRestrictionComplete(selectedDivisionIndex, this.state.gameRestrictions);
    })
  };

  openPlayLimitMode = () => this.setState({playLimitMode:true},
    ()=> {
      if(this.state.gameRestrictionMode) this.closeGameRestrictionMode();
    }
    );
  closePlayLimitMode = () => this.setState(({playLimitMode:false}),
    ()=> {
      if(!this.state.playLimitMode) this.resetPlayLimits();
  });
  openGameRestrictionMode = () => this.setState({gameRestrictionMode:true},
    ()=> {
      if(this.state.playLimitMode) this.closePlayLimitMode()
    });
  closeGameRestrictionMode = () => this.setState({gameRestrictionMode: false},
  () => {
    if(!this.state.gameRestrictionMode) this.resetGameRestrictions();
  });


  isRestricted = index => this.state.gameRestrictions[index].games.includes(this.state.selectedGame)

  restrictOrAllowSelected = index => {
    const games = this.state.gameRestrictions[index].games.slice();
    const isRestricted = games.includes(this.state.selectedGame);
    this.setState(prevState=> ({
      gameRestrictions:prevState.gameRestrictions.map((userRestrictions, i) => {
        if(index!==i)
        return userRestrictions;
        return {
          ...userRestrictions,
          games: isRestricted? games.filter(gameName=> gameName!==this.state.selectedGame) : [...games, this.state.selectedGame]
        }
      })
    }))
  }
  restrictAllForSelectedGame = () => {
    this.setState(prevState => ({
      gameRestrictions:prevState.gameRestrictions.map((userRestrictions)=>{
        return {
          ...userRestrictions,
          games:userRestrictions.games.includes(this.state.selectedGame)?userRestrictions.games:[...userRestrictions.games,this.state.selectedGame]
        }
      } )
    }))
  }
  allowAllForSelectedGame = () => {
    this.setState(prevState=> ({
      gameRestrictions:prevState.gameRestrictions.map((userRestrictions)=> {
        return {
          ...userRestrictions,
          games:userRestrictions.games.filter(gameName=> gameName!==this.state.selectedGame)
        }
      })
    }))
  }
  isAllSelected = () => this.state.gameRestrictions.every(userRestrictions => userRestrictions.games.includes(this.state.selectedGame));
  addUser = () => {
    this.props.create();
  }
  render() {
    const { t, reportClick,restrictionManage,divReportName,title } = this.props;
    const { playLimitMode, playLimits, playLimitForAllUsers, gameRestrictionMode, selectedGame } = this.state;
    const addDeleteEnabled = !this.props.disable && ! playLimitMode && !gameRestrictionMode;
    let games, gameNames = [];
    const allSelected = this.isAllSelected();
    if(restrictionManage)
    {
      games = t('games',{returnObjects:true});
      gameNames = Object.keys(games);
      gameNames = gameNames.slice(0,gameNames.length-1);
    }   
    return (
      <Card className="shadow ml-4 p-4 position-relative" style={{overflow:"auto"}}>
        <Typography variant="h6" className="d-inline">
          {this.props.title}
        </Typography>
        {addDeleteEnabled && <IconButton
          disableTouchRipple
          onClick={() =>
            this.setState(prevState => {
              return { addUserToggle: !prevState.addUserToggle };
            })
          }
        >
          <PersonAdd />
          
        </IconButton>
        }
        {title === t('corporation_info.corp_admins') && (reportClick && <Button variant="outline-info" onClick={reportClick}> {t("see_corporation_report")} </Button> )}
        {title === t('corporation_info.corp_div_admins') && (reportClick && <Button variant="outline-info" onClick={reportClick}> {divReportName} {t("see_div_report")}  </Button> )}

        {
          restrictionManage && 
          <>
          <div className={`form-inline border border-${playLimitMode?'secondary float-right':'light'} rounded p-1 d-inline-flex mr-2 mb-2  ${playLimitMode?"RestrictionBarActive":""} `}>
            <button onClick={this.openPlayLimitMode}  className="btn btn-outline-secondary btn-sm" disabled={playLimitMode}>{t('playLimit')}</button>
            {playLimitMode && 
              <>
              <div className="form-group ml-2 form-group-sm">
                <input  className="form-control form-control-sm" placeholder={t('minutesPerDay')} value={playLimitForAllUsers} onChange={this.handlePlayLimitForAllUsers}/>
              </div>
                <button className="btn btn-danger ml-2 btn-sm" onClick={this.closePlayLimitMode} style={{backgroundColor:"#f6707b", borderColor:"#f6707b"}}>{t('cancel')}</button>
                <button className="btn btn-info ml-2 btn-sm" onClick={this.setPlayLimits}>{t('save')}</button>
              </>
            }
            </div>
            <div className={`form-inline border border-${gameRestrictionMode?'secondary float-right':'light'} rounded p-1 d-inline-flex mr-2 mb2 ${gameRestrictionMode?"RestrictionBarActive":""}`}>
              <button className="btn btn-outline-secondary btn-sm" onClick={this.openGameRestrictionMode} disabled={gameRestrictionMode}>{t('gameRestriction')}</button>
                { gameRestrictionMode &&  
                <>
                <div className="form-group ml-2 form-group-sm">                
                    <select className="form-control custom-select custom-select-sm" placeholder={t('chooseGame')}
                    value={this.state.selectedGame} 
                    onChange={(e)=>this.setState({selectedGame:e.target.value})}>
                        <option value="">{t('chooseGame')}</option>
                        {gameNames.map(gameName => <option key={gameName} value={gameName}> {games[gameName].name} </option>)}
                      </select>        
                </div>    
                <button className={`btn btn${allSelected?"":"-outline"}-secondary ml-2 btn-sm`}
                  disabled={!selectedGame}
                  onClick={allSelected?this.allowAllForSelectedGame:this.restrictAllForSelectedGame}>
                  {t(allSelected?'deselectAll':'selectAll')}</button>
                <button className="btn btn-danger ml-2 btn-sm" onClick={this.closeGameRestrictionMode} style={{backgroundColor:"#f6707b", borderColor:"#f6707b"}}>{t('cancel')}</button>
                <button className="btn btn-info ml-2 btn-sm" onClick={this.restrictGames} >{t('save')}</button>
                </>
                }
            </div>
            

          
          </>
        }
        <Collapse in={this.state.addUserToggle && addDeleteEnabled} >
          <AddCorpUser add={this.props.create} />
        </Collapse>
        <Table style={{ tableLayout: "auto" }}>
          <colgroup>
            <col style={{ width: "5%", }} />
            <col style={{ width: '25%' }} />
            <col style={{ width: '25%' }} />
            <col style={{ width: '35%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '5%' }} />
          </colgroup>
          <TableHead>
            <TableRow
              style={{
                backgroundColor: "#eee",
                borderRadius: 20,
                height: "initial"
              }}
            >
              <TableCell style={{ borderBottomWidth: 0, height: "initial", textAlign: "center" }}>
                PP
              </TableCell>
              <TableCell style={{ borderBottomWidth: 0, height: "initial", textAlign: "center" }}>
                {t('users_list.columns.first_name').toUpperCase()}
              </TableCell>
              <TableCell style={{ borderBottomWidth: 0, height: "initial", textAlign: "center" }}>
                {t('email').toUpperCase()}
              </TableCell>
              <TableCell style={{ borderBottomWidth: 0, height: "initial", textAlign: "center" }}>
                {t('division').toUpperCase()}
              </TableCell>
              {playLimitMode && <TableCell style={{ borderBottomWidth: 0, height: "initial", textAlign: "center", minWidth:110 }}>
                Oyun Limiti
              </TableCell>}
              {addDeleteEnabled && <TableCell style={{ borderBottomWidth: 0, height: "initial", textAlign: "center" }}>
                {t('delete').toUpperCase()}
              </TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.users.map((user, index) => (
              <TableRow key={user.id} hover 
                style={gameRestrictionMode && selectedGame ?{backgroundColor:this.isRestricted(index)?"#ff000033":"#00bf0057", cursor:"pointer"}:{}} 
                onClick={gameRestrictionMode && selectedGame? ()=>this.restrictOrAllowSelected(index):()=>{}}> 
                <TableCell>
                  {user.image_file_name ? <img
                    width="50"
                    height="50"
                    src={user.image}
                    style={{ borderRadius: "50%", objectFit: "cover" }}
                    alt='userfile'
                  /> : <AccountCircle style={{ width: 50, height: 50, color: "#BBC1C4" }} />}
                </TableCell>
                <TableCell  >
                  {
                    this.props.inventory?
                      <a href={`${inventoryURL}/report/${user.id}?token=${this.props.token}`} 
                      style={{ color: "#7B7B7B", fontWeight: "bold" }}
                      target="_blank" rel="noopener noreferrer">{user.first_name + " " + user.last_name}</a>
                      :
                      <Link to={`/info/users/${user.id}`}
                      style={{ color: "#7B7B7B", fontWeight: "bold" }}>
                        {user.first_name + " " + user.last_name}
                      </Link>
                  }
                </TableCell >
                <TableCell style={{ color: "#7B7B7B" }}>{user.email}
                  
                </TableCell>
                <TableCell style={{ color: "#7B7B7B" }}>{user.divisions.join(", ")}</TableCell>
                {playLimitMode && <TableCell style={{ color: "#7B7B7B" }} >
                  <input type="number" value={playLimits[index].value } 
                  onChange={e=> this.handlePlayLimit(+e.target.value || '', index)} 
                  style={{maxWidth:60}}/></TableCell>}
                {addDeleteEnabled && <TableCell>
                  <IconButton onClick={() => this.props.delete(user.id)}>
                    <Delete style={{ color: "#F6707B" }}
                    />
                  </IconButton>
                </TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {(this.props.loading || ((this.props.playLimitLoading || this.props.restrictGamesLoading) && this.props.restrictionManage )) &&
          <div style={{ position: "absolute", left: 0, top: 0, width: "100%", height: "100%", backgroundColor: "rgba(255,255,255,.5)", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
          </div>
        }
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
  playLimitLoading: state.loading['PLAY_LIMITS'],
  restrictGamesLoading: state.loading['RESTRICT_GAMES']
})
const mapDispatchToProps = dispatch => ({
  setPlayLimits: (data) => dispatch(setPlayLimits(data)),
  restrictGames: (data) => dispatch(restrictGames(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withI18n()(CorpUserList));
