import React, { Component } from "react";
import {connect} from 'react-redux';
import {
  Card,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Collapse
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import EmailIcon from "@material-ui/icons/Email";
import "./style.css";
import { formDataUpdate } from "../../../utils/formValidate";
import {inviteToFamily, removeFromFamily} from '../../../store/actions';
import { withI18n } from "react-i18next";

class FamilyCard extends Component {
  state = {
    showInput: false,
    form: {
        email:{
            value: "",
            isValid: false,
            touched: false,
            validityRules: {
              isEmail: true
            }
        }

    }
  };
  toggleInput = () =>
    this.setState(prevState => {
      return { showInput: !prevState.showInput };
    });
    emailChangeHandler = event => {
        const {value}= event.target;
      this.setState(prevState=>{
          return {
              ...prevState,
              form:formDataUpdate(prevState.form,value,"email")
          }
      })
    }
    invite = () => {
      this.props.inviteToFamily(this.state.form.email.value)
      .then(response=>{
        if(response&&response.user)
        this.props.onFamilyMemberAdd(response.user);
      });

    }
    remove = id => {
      this.props.removeFromFamily(id)
        .then(response=> {
          if(response) this.props.onFamilyMemberRemove(id);
        })
    }
    checkAuth = () => {
      return this.props.authUser.is_family_administrator && this.props.family.find(user=>user.user_id===this.props.authUser.id)
    }
  render() {
    const family=this.props.family||[];
    const {t} = this.props;
    return (
      <Card className="p-4 mx-2 mb-4 shadow">
        <div className="d-flex justify-content-between align-items-baseline">
          <p className="h5 text-dark">
            Aile Yönetimi {`(${family.length}/5)`}
          </p>
        {this.checkAuth()&&<button
            disabled={family.length>4}
            className="btn btn-secondary btn-sm"
            onClick={this.toggleInput}
          >
            {t('add')}
          </button>}
        </div>
        <Collapse in={this.state.showInput}>
          <div className="input-group my-2">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <EmailIcon />
              </span>
            </div>
            <input type="text"
            onChange={this.emailChangeHandler}
            className={`text-center form-control ${this.state.form.email.touched?this.state.form.email.isValid?"is-valid":"is-invalid":""}`}
                value={this.state.form.email.value} />
            <div className="input-group-append">
              <span
                className={`input-group-text mail-add ${this.state.form.email.isValid&&(!this.props.inviteToFamilyLoading)?"":"mail-add-disabled"}` }
                onClick={this.state.form.email.isValid?this.invite:()=>{}}
              >
                <AddIcon />
              </span>
            </div>
          </div>
        </Collapse>
        <Table style={{ tableLayout: "auto" }}>
          <TableBody>
            {family.map(member => (
              <TableRow
                key={member.user.id}
                hover
                className="FamilyMemberListRow"
              >
                <TableCell className="FamilyMemberListCell Member-Name">
                  {member.user.first_name+ " "+member.user.last_name}
                </TableCell>
                <TableCell className="FamilyMemberListCell Member-Mail">
                  ({member.user.email || member.user.fb_email})
                </TableCell>
                {this.checkAuth()&& <TableCell className="FamilyMemberListCell">
                  <IconButton
                    className="FamilyMemberRemoveButton"
                    disabled = {this.props.removeFromFamilyLoading}
                    onClick={() => this.remove(member.user.id)}
                  >
                    <DeleteIcon color={this.props.removeFromFamilyLoading?"disabled":"error"} />
                  </IconButton>
                </TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    authUser:state.auth.user,
    inviteToFamilyLoading : state.loading['INVITE_TO_FAMILY'],
    removeFromFamilyLoading : state.loading['REMOVE_FROM_FAMILY']
  }
}
const mapDispatchToProps = dispatch => {
  return {
    inviteToFamily: (email) => dispatch(inviteToFamily(email)),
    removeFromFamily : id => dispatch(removeFromFamily(id))
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(withI18n()(FamilyCard));
