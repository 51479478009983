import React from "react";
import { NavLink } from "react-router-dom";
import "./style.css";
import { withNamespaces } from "react-i18next";
import { connect } from 'react-redux';


const NavigatorCard = props => {
  const { t, basePath, role } = props;
  return (
    <div
      className="card shadow bg-white rounded px-4 py-4"
      style={{ width: "100%" }}
    >
      {(role === 1 || role === 101) && (
        <div
          className="card-container"
          style={{
            display: "flex",
            justifyContent: "space-between",
            cursor: "pointer"
          }}
        >
          <NavLink
            className="nav-card"
            activeClassName="nav-card-active-class"
            to={basePath + "/users"}
          >
            <h6
              className="d-inline-block"
              data-toggle="collapse"
              href="#users"
              role="button"
              aria-expanded="false"
              aria-controls="users"
            >
              {t("users")}
            </h6>
          </NavLink>
          {role === 1 && (
            <h6
              className="d-inline-block text-right justify-content-end"
              data-toggle="collapse"
              href="#users"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              +
            </h6>
          )}
        </div>
      )}

      {role === 1 && (
        <>
          <div className="collapse card-container" id="users">
            <ul className="list-group list-group-flush">
              <NavLink
                className="nav-card ml-2"
                activeClassName="nav-card-active-class"
                to={basePath + "/users/new"}
              >
                <li style={{ listStyle: "none" }}>{t("add_user")}</li>
              </NavLink>

              {/* <NavLink className = "nav-card ml-2" activeClassName = "nav-card-active-class" to={this.props.basePath + '/addUserGroup'}>
            <li style = {{listStyle: 'none'}}>{t('add_multiple_user')}</li>
          </NavLink>
          <NavLink className = "nav-card ml-2" activeClassName = "nav-card-active-class" to={this.props.basePath + '/addCorpSchool'}>
            <li style = {{listStyle: 'none'}}>{t('add_corp_and_school')}</li>
          </NavLink>
          <NavLink className = "nav-card ml-2" activeClassName = "nav-card-active-class"to={this.props.basePath + '/gift'}>
            <li style = {{listStyle: 'none'}}>{t('gift_code')}</li>
          </NavLink> */}
            </ul>
          </div>
          <div
            className="card-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer"
            }}
          >
            <NavLink
              className="nav-card"
              activeClassName="nav-card-active-class"
              to={basePath + "/games"}
            >
              <h6>{t("play_counts")}</h6>
            </NavLink>
          </div>
          <div
            className="card-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer"
            }}
          >
            <NavLink
              className="nav-card"
              activeClassName="nav-card-active-class"
              to={basePath + "/userCounts"}
            >
              <h6>{t("user_counts")}</h6>
            </NavLink>
          </div>
          <div
            className="card-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer"
            }}
          >
            <NavLink
              className="nav-card"
              activeClassName="nav-card-active-class"
              to={basePath + "/coupons"}
            >
              <h6>{t("coupons.text")}</h6>
            </NavLink>
          </div>
          <div
            className="card-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer"
            }}
          >
            <NavLink
              className="nav-card"
              activeClassName="nav-card-active-class"
              to={basePath + "/inventory_coupons"}
            >
              <h6>{t("inventory_coupons.text")}</h6>
            </NavLink>
          </div>

        </>
      )}

      {/* if there no corp to show do not render unless role->admin */}      
      {
        ( (role === 1) || (props.corporations && props.corporations[0]) ) && (
          
      <div
        className="card-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer"
        }}
      >
        <NavLink
          className="nav-card"
          activeClassName="nav-card-active-class"
          to={basePath + "/corporations"}
        >
          <h6
            className="d-inline-block"
            data-toggle="collapse"
            href="#corporations"
            role="button"
            aria-expanded="false"
            aria-controls="users"
          >
            {t("corporationManagement")}
          </h6>
        </NavLink>
        {role === 1 && (
          <h6
            className="d-inline-block text-right justify-content-end"
            data-toggle="collapse"
            href="#corporations"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            +
          </h6>
        )}
      </div>)

      }
      
      {/* admin can add new corp only */}
      {role === 1 && (
        <div className="collapse card-container" id="corporations">
          <ul className="list-group list-group-flush">
            <NavLink
              className="nav-card ml-2"
              activeClassName="nav-card-active-class"
              to={basePath + "/corporations/new"}
            >
              <li style={{ listStyle: "none" }}>
                {t("corporations_page.add_new_corp")}
              </li>
            </NavLink>
          </ul>
        </div>
      )}



      {/* below is open to everyone */}

      <div
        className="card-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer"
        }}
      >
        <NavLink
          className="nav-card"
          activeClassName="nav-card-active-class"
          to={basePath + "/statistics"}
        >
          <h6>{t("statistics.text")}</h6>
        </NavLink>
      </div>      
    </div>
  );
};


const mapStateToProps = state => {
  return {
    corporations: state.corporations.corporationsSummary,
  }
}


export default connect(mapStateToProps)(withNamespaces("common")(NavigatorCard));
