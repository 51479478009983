import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FindInPage, PieChart, Category, ViewList, Search } from '@material-ui/icons';
import CustomInput from '../../components/ui/CustomInput';
import { withNamespaces } from 'react-i18next';
import { Card, Typography, CircularProgress, IconButton } from '@material-ui/core';
import { getUserComparisonInfo, getUsers } from '../../store/actions';
import Chip from '@material-ui/core/Chip';
import { Bar } from 'react-chartjs-2';
import { bgColors, chartConfig, days, months } from './chartOptions';
import FilteredUserList from './FilteredUserList';
import './style.css';

class CompareUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUsers: [],
      userFilterText: '',
      selectedChartOption: '',
      selectedCategoryOption: 'total',
      selectedGameOption: '',
      chartData: {},
      rawData: [],
    }
    this.chartOptions = props.t('statistics.chart_options', { returnObjects: true });
    this.categoryGamesOptions = props.t('category_games', { returnObjects: true });
  }

  componentDidMount() {
    this.props.getUsers(0);

  }

  getUsers = (page) => {
    this.props.getUsers(page, this.state.userFilterText.trim() || null)
  }
  filterUsers = () => {
    this.getUsers(0);
  }

  changeHandler = (e) => {
    let { name, value } = e.target;
    if (name === 'selectedChartOption')
      this.setState({
        [name]: value,
        selectedCategoryOption: 'total',
        selectedGameOption: 'total',
      }, () => this.getUserInfo());
    else if (name === 'selectedCategoryOption') {
      let isMonthly = ['monthly_game_counts', 'monthly_game_scores'].includes(this.state.selectedChartOption);
      let datasets = [];
      if (value === 'total') {
        datasets = this.state.rawData.map((user, index) => {
          return {
            label: `${index + 1} - ${user.first_name} ${user.last_name}`,
            data: (() => {
              let result = [];
              for (let i = 0; i < Object.values(user.data)[0].length; i++) {
                result[i] = Object.values(user.data).reduce((acc, cur) => { return acc = acc + cur[i] }, 0)
              }
              return result;
            })(),
            backgroundColor: bgColors[user.id % bgColors.length]
          }
        })
      } else {
        datasets = this.state.rawData.map((user, index) => {
          return {
            label: `${index + 1} - ${user.first_name} ${user.last_name}`,
            data: (() => {
              let result = [];
              for (let i = 0; i < Object.values(user.data)[0].length; i++) {
                let keys = Object.keys(user.data).filter(key => Object.keys(this.categoryGamesOptions[value].games).includes(key))
                let values = keys.map(key => user.data[key])
                result[i] = values.reduce((acc, cur) => { return acc = acc + cur[i] }, 0)
              }
              return result;
            })(),
            backgroundColor: bgColors[user.id % bgColors.length]
          }
        })
      }
      this.setState(prevState => {
        return {
          [name]: value,
          chartData: {
            labels: isMonthly ? months(this.props.t) : days(),
            datasets: datasets,
            borderWidth: 1
          }
        }
      })
    } else if (name === 'selectedGameOption') {
      let isMonthly = ['monthly_game_counts', 'monthly_game_scores'].includes(this.state.selectedChartOption);
      let datasets = [];
      if (value === 'total') {
        datasets = this.state.rawData.map((user, index) => {
          return {
            label: `${index + 1} - ${user.first_name} ${user.last_name}`,
            data: (() => {
              let result = [];
              for (let i = 0; i < Object.values(user.data)[0].length; i++) {
                let keys = Object.keys(user.data).filter(key => Object.keys(this.categoryGamesOptions[this.state.selectedCategoryOption].games).includes(key))
                let values = keys.map(key => user.data[key])
                result[i] = values.reduce((acc, cur) => { return acc = acc + cur[i] }, 0)
              }
              return result;
            })(),
            backgroundColor: bgColors[user.id % bgColors.length]
          }
        })
      } else {
        datasets = this.state.rawData.map((user, index) => {
          return {
            label: `${index + 1} - ${user.first_name} ${user.last_name} ${user.id}`,
            data: user.data[value],
            backgroundColor: bgColors[user.id % bgColors.length]
          }
        })
      }
      this.setState(prevState => {
        return {
          [name]: value,
          chartData: {
            labels: isMonthly ? months(this.props.t) : days(),
            datasets: datasets,
            borderWidth: 1
          }
        }
      })
    }
    else { this.setState({ [name]: value }) }
  }

  handleDelete = (index) => {
    this.setState(prevState => {

      return {
        selectedUsers: prevState.selectedUsers.filter((_user, i) => index !== i),
        chartData: {
          ...prevState.chartData,
          datasets: prevState.chartData.datasets.filter((dataset, i) => index !== i)
        }
      }
    })
  }

  getUserInfo = () => {
    let { selectedUsers, selectedChartOption } = this.state;
    let { t } = this.props
    if (selectedUsers.length < 1) return;
    let isMonthly = ['monthly_game_counts', 'monthly_game_scores'].includes(selectedChartOption);
    let isDaily = ['daily_game_counts', 'daily_game_scores'].includes(selectedChartOption);

    selectedUsers && this.props.getUserComparisonInfo(selectedUsers.map(user => user.id), selectedChartOption).then(res => {
      if (!res || !res.length > 0) return;
      if (isMonthly || isDaily) {
        this.setState({
          rawData: res,
          chartData: {
            labels: isMonthly ? months(t) : days(),
            datasets: res.map((user, index) => {
              return {
                label: `${index + 1} - ${user.first_name} ${user.last_name}`,
                data: (() => {
                  let result = [];
                  for (let i = 0; i < Object.values(user.data)[0].length; i++) {
                    result[i] = Object.values(user.data).reduce((acc, cur) => {
                      return acc = acc + cur[i]
                    }, 0)
                  }
                  return result;
                })(),
                backgroundColor: bgColors[user.id % bgColors.length]
              }
            }),
            borderWidth: 1
          }
        })
      }
      else {
        this.setState({
          rawData: res,
          chartData: {
            labels: Object.keys(res[0].data).map(data => this.props.t('chart_labels.' + data)),
            datasets: res.map((user, index) => {
              return {
                label: `${index + 1} - ${user.first_name} ${user.last_name}`,
                data: Object.values(user.data),
                backgroundColor: bgColors[user.id % bgColors.length]
              }
            }),
            borderWidth: 1
          }
        })
      }
    })
  }
  toggleSelectUser = (user) => {
    this.setState(prevState => {
      return {
        selectedUsers: prevState.selectedUsers.find(selectedUser=> selectedUser.id === user.id) ?
          prevState.selectedUsers.filter(_user => _user.id !== user.id) : [...prevState.selectedUsers, user]
      }
    })
  }
  
  render() {
    // if (this.props.usersLoading) return (<Card className="shadow p-4 d-flex justify-content-center col-8"><CircularProgress /></Card>);
    let { t, lng, currentPage, total, users } = this.props;
    let { chartOptions, categoryGamesOptions, getUsers } = this;
    let { userFilterText, selectedUsers, selectedChartOption, chartData, selectedCategoryOption, selectedGameOption } = this.state;


    users = users.map(user=>selectedUsers.find(s_user=>user.id===s_user.id)?{...user,isActive:true}:user)

    let isMonthly = ['monthly_game_counts', 'monthly_game_scores'].includes(selectedChartOption);
    let isDaily = ['daily_game_counts', 'daily_game_scores'].includes(selectedChartOption);
    return (
      <div className="row CompareUsers">
        <div className='col-3'>
          <Card className="shadow p-3 DivisionSelectContainer" style={{height:650}}>
            <div className="d-flex justify-content-between">
              <Typography variant="h6">{t('users')}</Typography>
              <CustomInput
                lefticon={<FindInPage style={{ color: "#7A7A7A" }} />}
                placeholder={t('searchUser')}
                value={userFilterText}
                onChange={e => this.setState({ userFilterText: e.target.value })}
                containerstyle={{ maxWidth: "50%", backgroundColor: "#F0F0F0", borderWidth: 0 }} />
                <IconButton onClick={()=>this.filterUsers()}>
                  <Search />
                </IconButton> 
            </div>
            <FilteredUserList
              currentPage={currentPage}
              totalCount={total}
              users={users}
              currentPageChange={getUsers}
              toggleSelect={this.toggleSelectUser}
            />
            {this.props.usersLoading&&<div style={{position:"absolute",left:0,top:0,width:"100%",height:"100%",backgroundColor:"rgba(255,255,255,.5)",display:"flex",justifyContent:"center",alignItems:"center"}}>
                        <CircularProgress></CircularProgress>
              </div>}
            </Card>
        </div>
        <div className='CompareUsers col-9'>
          <Card className="shadow p-3 position-relative">
            {this.props.loading &&
              <div style={{ position: "absolute", left: 0, top: 0, width: "100%", height: "100%", backgroundColor: "rgba(255,255,255,.5)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress />
              </div>}
            <Typography variant="h6">{t('statistics.charts')}</Typography>
            <div className="d-flex mt-2">
              <button className="btn btn-primary btn-sm mx-1 ButtonGroupItem" style={{ height: '2.7em' }}
                onClick={this.getUserInfo}>{t('refresh')}</button>
              <div className="input-group mb-2 mr-2" style={{ width: '40%' }}>
                <div className="input-group-prepend">
                  <span className='input-group-text border-right-0 bg-white'>
                    <PieChart />
                  </span>
                </div>
                <select
                  className='form-control custom-select border-left-0 bg-white'
                  style={{ textAlignLast: 'center' }}
                  name="selectedChartOption"
                  onChange={this.changeHandler}
                  value={selectedChartOption}
                >
                  {Object.keys(chartOptions).map(option =>
                    <option key={option} value={option}>{chartOptions[option]}</option>
                  )}
                </select>
              </div>
              {(isMonthly || isDaily) &&
                <div className="input-group mb-2 mr-2" style={{ width: '25%' }}>
                  <div className="input-group-prepend">
                    <span className='input-group-text border-right-0 bg-white'>
                      <ViewList />
                    </span>
                  </div>
                  <select
                    className='form-control custom-select border-left-0 bg-white'
                    style={{ textAlignLast: 'center' }}
                    name="selectedCategoryOption"
                    onChange={this.changeHandler}
                    value={selectedCategoryOption}
                  >
                    <option key={'total'} value='total'>{t('total').toUpperCase()}</option>
                    {Object.keys(categoryGamesOptions).map(option =>
                      <option key={option} value={option}>{categoryGamesOptions[option].text}</option>
                    )}
                  </select>
                </div>
              }
              {(isMonthly || isDaily) && (selectedCategoryOption !== 'total') &&
                <div className="input-group mb-2" style={{ width: '25%' }}>
                  <div className="input-group-prepend">
                    <span className='input-group-text border-right-0 bg-white'>
                      <Category />
                    </span>
                  </div>
                  <select
                    className='form-control custom-select border-left-0 bg-white'
                    style={{ textAlignLast: 'center' }}
                    name="selectedGameOption"
                    onChange={this.changeHandler}
                    value={selectedGameOption}
                  >
                    <option key={'total'} value='total'>{t('total').toUpperCase()}</option>
                    {Object.keys(categoryGamesOptions[selectedCategoryOption].games).map(option =>
                      <option key={option} value={option}>{categoryGamesOptions[selectedCategoryOption].games[option]}</option>
                    )}
                  </select>
                </div>
              }
            </div>
            <div className='d-flex justify-concent-center flex-wrap my-2'>
              {selectedUsers.map((user, index) =>
                <Chip className='mr-2 mb-2' key={user.id}
                  style={{ color: 'white', background: bgColors[user.id % bgColors.length] }}
                  label={`${user.first_name} ${user.last_name}`}
                  onDelete={() => this.handleDelete(index)}
                  onClick={() => this.handleDelete(index)}
                />)}
            </div>
            <div>
              <Bar data={chartData} options={chartConfig} />
            </div>
          </Card>
        </div>
      </div>
    )
  }
}



const mapStateToProps = state => {
  return {
    loading: state.loading['GET_USER_COMPARISON_INFO'],
    usersLoading: state.loading["USERS"],
    users: state.users.users,
    currentPage: state.users.currentPage,
    totalPage: state.users.totalPage,
    total: state.users.total
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUserComparisonInfo: (user_ids, type) => dispatch(getUserComparisonInfo(user_ids, type)),
    getUsers: (page, search_text) => dispatch(getUsers(page, null, null, search_text))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('common')(CompareUsers));
