import {
  GAME_PLAY_COUNT_SUCCESS,
  NEW_USERS_COUNT_SUCCESS,
  USER_COUNTS_SUCCESS,
  SUMMARY_DATA_SUCCESS,
} from '../actions/actionTypes';
const initialState = {
  playCount: 0,
  summary:null
}

const statisticsReducer = (state = initialState, action) => {
  switch(action.type){
    case GAME_PLAY_COUNT_SUCCESS:
      return {
        ...state,
        gamePlayCount: action.value
      }
    case NEW_USERS_COUNT_SUCCESS:
      return {
        ...state,
        newUserCounts: action.value
      }
    case USER_COUNTS_SUCCESS:
      return {
        ...state,
        userCounts: action.value
      }
    case SUMMARY_DATA_SUCCESS:
      return {
        ...state,
        summary:action.summary
      }
    default:
    return {
      ...state
    }
  }
}

export default statisticsReducer;
