import {
  PURCHASE_REQUEST,
  PURCHASE_SUCCESS,
  PURCHASE_FAIL,
  VALIDATE_COUPON_REQUEST,
  VALIDATE_COUPON_SUCCESS,
  VALIDATE_COUPON_FAIL,
  GET_COUPONS_REQUEST,
  GET_COUPONS_SUCCESS,
  GET_COUPONS_FAIL,
  DEACTIVATE_COUPON_REQUEST,
  DEACTIVATE_COUPON_SUCCESS,
  DEACTIVATE_COUPON_FAIL,
  CREATE_COUPON_REQUEST,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_FAIL,
} from "./actionTypes";
import axios from "../../axios-instance";
import api from "../../api";

const purchaseRequest = () => ({
  type: PURCHASE_REQUEST,
});

const purchaseSuccess = () => ({
  type: PURCHASE_SUCCESS,
});

const purchaseFail = () => ({
  type: PURCHASE_FAIL,
});

const validateCouponRequest = () => ({
  type: VALIDATE_COUPON_REQUEST,
});

const validateCouponSuccess = () => ({
  type: VALIDATE_COUPON_SUCCESS,
});

const validateCouponFail = () => ({
  type: VALIDATE_COUPON_FAIL,
});

const couponsRequest = () => ({
  type: GET_COUPONS_REQUEST,
});

const couponsSuccess = (coupons) => ({
  type: GET_COUPONS_SUCCESS,
  coupons,
});

const couponsFail = () => ({
  type: GET_COUPONS_FAIL,
});

const deactivateCouponsRequest = () => ({
  type: DEACTIVATE_COUPON_REQUEST,
});

const deactivateCouponSuccess = (coupon) => ({
  type: DEACTIVATE_COUPON_SUCCESS,
  coupon,
});

const deactivateCouponFail = () => ({
  type: DEACTIVATE_COUPON_FAIL,
});

const createCouponRequest = () => ({
  type: CREATE_COUPON_REQUEST,
});

const createCouponSuccess = (coupon) => ({
  type: CREATE_COUPON_SUCCESS,
  coupon,
});

const createCouponFail = () => ({
  type: CREATE_COUPON_FAIL,
});

export const purchase = (product_id, coupon, user_id) => (dispatch, getState) => {
  dispatch(purchaseRequest());
  return axios
    .post(api.purchase, {
      user_id,
      product_id,
      coupon,
      token: getState().auth.token,
    })
    .then((res) => {
      dispatch(purchaseSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      dispatch(purchaseFail());
    });
};

export const deactivate_coupon = (coupon_id) => (dispatch, getState) => {
  dispatch(deactivateCouponsRequest());
  return axios
    .post(api.deactivate_coupon, {
      coupon_id,
      token: getState().auth.token,
    })
    .then((res) => {
      dispatch(deactivateCouponSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      dispatch(deactivateCouponFail());
    });
};

export const create_coupon = (coupon) => (dispatch, getState) => {
  dispatch(createCouponRequest());
  return axios
    .post(api.create_coupon, {
      code: coupon.code,
      value: coupon.value,
      percent: coupon.percent,
      source: coupon.source,
      source_id: coupon.source_id,
      valid_from: coupon.valid_from,
      valid_till: coupon.valid_till,
      use_count: coupon.use_count,
      token: getState().auth.token,
    })
    .then((res) => {

      dispatch(createCouponSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      dispatch(createCouponFail());
    });
};

export const validate_coupon = (code) => (dispatch, getState) => {
  dispatch(validateCouponRequest());
  return axios
    .get(api.validate_coupon, {
      params: { code, token: getState().auth.token },
    })
    .then((res) => {
      dispatch(validateCouponSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      dispatch(validateCouponFail());
    });
};

export const get_coupons = (filters) => (dispatch, getState) => {
  dispatch(couponsRequest());
  return axios
    .get(api.filter_coupons, {
      params: { filters, token: getState().auth.token },
    })
    .then((res) => {
      dispatch(couponsSuccess(res.data.coupons));
      return res.data.coupons;
    })
    .catch((error) => {
      dispatch(couponsFail());
    });
};
