import React from "react";

export default () => (
  <div>
    {" "}
    <h4> SSS </h4>
    <p>
      Bu bölümde GriCeviz Yönetim Paneli’ne ilişkin sıkça sorulan soruların
      cevaplarını bulabilirsiniz. GriCeviz’le ilgili sıkça sorulan soruları
      incelemek için web sitemizi ziyaret edebilirsiniz.{" "}
    </p>
      <ol>
        <li>
          <b>Yönetim Paneli nedir ve ne işe yarıyor?</b>{" "}
          <p>
            Yönetim Paneli üzerinden profil bilgilerinizi güncelleyebilir,
            üyelik yönetimi yapabilir ve istatistiklerinize ulaşabilirsiniz.
            Dilerseniz gelişim raporunuzu ayrıntılı olarak indirebilirsiniz.
          </p>
        </li>
        <li>
          <b>Yönetim Paneli’ne tüm kullanıcılar erişebilir mi? </b>
          <p>
            Yönetim Paneli’ne tüm kullanıcılarımız erişebilir, profil
            işlemlerini gerçekleştirebilir ve kısıtlı olarak istatistiklerini
            görebilirler. Ayrıntılı gelişim takibi ve raporlamalar için
            kullanıcının “Üye” statüsünde olması gerekmektedir.{" "}
          </p>
        </li>
        <li>
          <b>Neden Yönetim Paneli’ne ihtiyaç duyuyoruz?</b>
          <p>
            GriCeviz’deki gelişiminizi, uygulama içinden de takip edebilirsiniz.
            Ancak daha fazla ayrıntı görmek ve rapor indirmek isterseniz Yönetim
            Paneli’ni ziyaret edebilirsiniz. Yönetim Paneli; Aile, Kurum ve Okul
            Üyelikleri gibi toplu kullanımlarda, kullanıcıların takibini
            kolaylaştırmak için geliştirilmiştir
          </p>
        </li>
        <li>
          <b>
            Aile Üyeliği satın aldım, ailemdeki diğer kullanıcıları nasıl
            ekleyebilirim?
          </b>
          <p>
            Aile Üyeliği satın aldığınızda, Yönetim Paneli’ndeki profil
            sayfanızda yeni kullanıcıları ekleyebileceğiniz sekme açılır. Diğer
            aile üyelerinin mail adreslerini girerek onlara davet
            gönderebilirsiniz. Yalnız unutmayın, ekleyeceğiniz kullanıcının o
            mail hesabına ait bir GriCeviz hesabının önceden oluşturulmuş olması
            gerekmektedir.
          </p>
        </li>
      </ol>
  </div>
);
