import {
  GAME_PLAY_COUNT_FAIL,
  GAME_PLAY_COUNT_REQUEST,
  GAME_PLAY_COUNT_SUCCESS,
  NEW_USERS_COUNT_FAIL,
  NEW_USERS_COUNT_REQUEST,
  NEW_USERS_COUNT_SUCCESS,
  USER_COUNTS_REQUEST,
  USER_COUNTS_FAIL,
  USER_COUNTS_SUCCESS,
  SUMMARY_DATA_REQUEST,
  SUMMARY_DATA_SUCCESS,
  SUMMARY_DATA_FAIL,
  GET_USER_COMPARISON_INFO_REQUEST,
  GET_USER_COMPARISON_INFO_SUCCESS,
  GET_USER_COMPARISON_INFO_FAIL,
  ALL_CATEGORY_STATISTICS_REQUEST,
  ALL_CATEGORY_STATISTICS_FAIL,
  ALL_CATEGORY_STATISTICS_SUCCESS,
} from './actionTypes'
import {showError} from './'
import axios from '../../axios-instance'
import api from '../../api'

const gamePlayCountRequest = () => {
  return {
    type: GAME_PLAY_COUNT_REQUEST
  }
}

const gamePlayCountSuccess = (value) => {
  return {
    type: GAME_PLAY_COUNT_SUCCESS,
    value
  }
}

const gamePlayCountFail = () => {
  return {
    type: GAME_PLAY_COUNT_FAIL,
  }
}

const newUsersCountRequest = () => {
  return {
    type: NEW_USERS_COUNT_REQUEST
  }
}

const newUsersCountSuccess = (value) => {
  return {
    type: NEW_USERS_COUNT_SUCCESS,
    value
  }
}

const newUsersCountFail = () => {
  return {
    type: NEW_USERS_COUNT_FAIL
  }
}

const userCountsRequest = () => {
  return {
    type: USER_COUNTS_REQUEST
  }
}

const userCountsSuccess = (value) => {
  return {
    type: USER_COUNTS_SUCCESS,
    value
  }
}

const userCountsFail = () => {
  return {
    type: USER_COUNTS_FAIL
  }
}

export const getGamePlayCount = (filter,user_id) => {
  return (dispatch, getState) => {
    dispatch(gamePlayCountRequest());
    axios.get(api.filtered_game_play_counts, {params:{token: getState().auth.token, ...filter,user_id}}).then(res => {
      dispatch(gamePlayCountSuccess(res.data))
    }).catch(error =>{
      dispatch(showError(error.message));
      dispatch(gamePlayCountFail());
    })
  }
}

export const getNewUserCounts = () => {
  return (dispatch, getState) => {
    dispatch(newUsersCountRequest());
    axios.get(api.get_new_user_counts, {params: {token: getState().auth.token}}).then(res => {
      dispatch(newUsersCountSuccess(res.data));
    }).catch(error => {
      dispatch(showError(error.message));
      dispatch(newUsersCountFail());
    })
  }
}


export const getUserCounts = (date) => {
  return (dispatch,getState) => {
    dispatch(userCountsRequest());
    axios.get(api.get_user_counts, {params:{token: getState().auth.token, date: new Date(date._d)}}).then(res => {
      dispatch(userCountsSuccess(res.data));
    }).catch(error => {
      dispatch(showError(error.message));
      dispatch(userCountsFail());
    })
  }
}

const getSummaryDataRequest = () => {
  return {
    type:SUMMARY_DATA_REQUEST
  }
}

const getSummaryDataSuccess = summary => {
  return {
    type:SUMMARY_DATA_SUCCESS,
    summary
  }
}

const getSummaryDataFail = () => {
  return {
    type:SUMMARY_DATA_FAIL
  }
}

export const getSummaryData = () => {
  return (dispatch,getState) => {
    dispatch(getSummaryDataRequest());
    axios.get(api.admin_home_page_cards,{
      params:{token:getState().auth.token}
    }).then(res=>dispatch(getSummaryDataSuccess(res.data)))
      .catch(error=>{
        dispatch(getSummaryDataFail());
        dispatch(showError(error.message));
      })
  }
}

const getUserComparisonInfoRequest = () => {
  return {
    type:GET_USER_COMPARISON_INFO_REQUEST
  }
}

const getUserComparisonInfoSuccess = () => {
  return {
    type:GET_USER_COMPARISON_INFO_SUCCESS
  }
}

const getUserComparisonInfoFail = () => {
  return {
    type:GET_USER_COMPARISON_INFO_FAIL
  }
}

export const getUserComparisonInfo = (user_ids, type) => {
  return(dispatch, getState) => {
    dispatch(getUserComparisonInfoRequest());
    return axios.get(api.get_user_comparison_info, {
      params: {
        token: getState().auth.token,
        user_ids,
        type
      }
    }).then(res => {
      dispatch(getUserComparisonInfoSuccess());
      return res.data
    })
    .catch(error => {
      dispatch(getUserComparisonInfoFail());
      dispatch(showError(error.message));
    })
}
}

const getAllCategoryStatisticsRequest = () => ({
  type:ALL_CATEGORY_STATISTICS_REQUEST
});

const getAllCategoryStatisticsFail = () => ({
  type:ALL_CATEGORY_STATISTICS_FAIL
});

const getAllCategoryStatisticsSuccess = () => ({
  type:ALL_CATEGORY_STATISTICS_SUCCESS
});

export const getAllCategoryStatistics = (user_id) => (dispatch, getState) => {
  dispatch(getAllCategoryStatisticsRequest());
  return axios.get(api.all_category_statistics,{
    params:{
      token: getState().auth.token,
      user_id
    }
  }).then(response=> {
    dispatch(getAllCategoryStatisticsSuccess());
    return response.data;
  }).catch(error=>{
    dispatch(getAllCategoryStatisticsFail());
  })
}