import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Collapse, IconButton, CircularProgress } from "@material-ui/core";
import Android from "@material-ui/icons/Android";
import Email from "@material-ui/icons/Email";
import CardMembership from "@material-ui/icons/CardMembership";
import Cake from "@material-ui/icons/Cake";
import School from "@material-ui/icons/School";
import DateRange from "@material-ui/icons/DateRange";
import Work from "@material-ui/icons/Work";
import AddAPhoto from "@material-ui/icons/AddAPhoto";
import WC from "@material-ui/icons/WcTwoTone";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Key from "@material-ui/icons/VpnKey";
import Domain from "@material-ui/icons/Domain";
import MembershipTypeChip from '../../MembershipTypeChip'
import moment from "moment";
import { SingleDatePicker, isInclusivelyBeforeDay } from "react-dates";
import "./style.css";
import { withI18n } from "react-i18next";
import { formDataUpdate } from "../../../utils/formValidate";
import { changePassword, editUser } from "../../../store/actions";
import SvgIcon from '@material-ui/core/SvgIcon';

class ProfileCard extends Component {
  state = {
    editMode: false,
    birthDateFocus: false,
    premiumEndAtFocus: false,
    changePasswordMode: false,
    image:null,
    formData: {
      user_id: '',
      use_type: '',
      premium_end_at: moment(new Date(this.props.user.premium_end_at)),
      birth_date: null,
      education: "",
      occupation: "",
      gender: "",
    },
    changePassword: {
      currentPassword: {
        isValid: false,
        value: "",
        touched: false,
        validityRules: {
          minLength: 6
        }
      },
      password: {
        isValid: false,
        value: "",
        touched: false,
        validityRules: {
          minLength: 6
        }
      },
      confirmPassword: {
        isValid: false,
        value: "",
        touched: false,
        validityRules: {
          equalTo: ""
        }
      }
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    if (this.props.user) {
      this.updateForm();
      this.setState({image:this.props.user.image})
    }
  }

  onPasswordChange = (event) => {
    const { value, name } = event.target;
    this.setState(prevState => {
      return {
        ...prevState,
        changePassword: formDataUpdate(prevState.changePassword, value, name)
      }
    })
  }
  isauthorized = () => {
    const { authUser, user } = this.props;
    return user && authUser && ( user.id === authUser.id || authUser.role === 1 )
  }
  updateForm = () => {
    const { birth_date, education, occupation, gender, use_type, premium_end_at, id } = this.props.user;
    this.setState({
      formData: {
        user_id: id,
        use_type: use_type || '',
        premium_end_at: moment(new Date(premium_end_at)),
        birth_date: birth_date ? moment(birth_date) : null,
        education: education || "",
        occupation: occupation || "",
        gender: gender || ""
      }
    });
  };

  editModeChange = () => {
    if (this.state.editMode) {
      this.updateForm();
    }
    this.setState(prevState => {
      return { editMode: !prevState.editMode };
    });
  };

  updateProfile = () => {
    if (this.state.formData.premium_end_at) {
      let premium_end_at = this.state.formData.premium_end_at.format("YYYY-MM-DD");
      let user_id = this.props.authUser.role === 1 ? this.state.formData.user_id : null;
      this.props.editUser({ ...this.state.formData, premium_end_at, user_id }).then(user => {
        user && this.setState(prevState => { return { editMode: false } }) && this.props.update(user)
      })
    }
  };

  inputChange = event => {
    const { name, value } = event.target;
    this.setState(prevState => {
      return {
        formData: {
          ...prevState.formData,
          [name]: value
        }
      };
    });
  };

  dateFocusChange = ({ focused }, field) => this.setState({ [field]: focused })

  dateChange = (date, field) => {
    this.setState(prevState => {
      return {
        formData: {
          ...prevState.formData,
          [field]: date
        }
      };
    });
  };

  changePassword = () => {
    const old_password = this.state.changePassword.currentPassword.value;
    const new_password = this.state.changePassword.password.value;
    this.props.changePassword({ old_password, new_password })
      .then(response => {
        if (response) this.setState({ changePasswordMode: false });
      })
  }
  profileImageChange = (e) => {
    let { name, value, files } = e.target;
    if (files) {
      const reader = new FileReader();
      reader.onload = event => {
        this.setState({imageLoading:true})
        let user_id = this.props.authUser.role === 1 ? this.state.formData.user_id : null;
        this.props.editUser({image:event.target.result, user_id}).then(user => {
          this.setState({image:user?user.image:this.state.image,imageLoading:false})
      })}
      reader.readAsDataURL(files[0]);
      reader.onerror = () => this.setState({imageLoading:false})
    }
  }
  render() {
    const { user, t, authUser } = this.props;
    const { editMode, birthDateFocus, premiumEndAtFocus, formData, changePasswordMode, changePassword, image, imageLoading } = this.state;
    const { editModeChange, updateProfile, inputChange, dateChange, dateFocusChange, goBack } = this;
    const { education, occupation, gender, birth_date, use_type, premium_end_at } = formData;
    const { email } = user;
    const dayDiff = moment(new Date(this.props.user.premium_end_at)).diff(moment(), "days");
    const image_file_name = image || require("../../../assets/images/missing.png");
    const membershipTypes = t('membershipType', { returnObjects: true });
    return (
      <Card className="ProfileCard shadow p-4 mx-2 mb-4" style={{ overflow: "visible" }}>
        <span className="ProfileCard-GoBack p-4" onClick={goBack}>
          <ArrowBack />
        </span>
        {/* <p className="h4 mb-4">{t("profile.user_profile")}</p> */}
        <div className="ProfileCard-Image-Container">

          <img
            className={["ProfileCard-Image",imageLoading?"loading":""].join(" ")}
            src={image_file_name}
            alt="profile pic"
            style={{ objectFit: "cover" }}
          />
            {this.isauthorized()?
            imageLoading?
            <CircularProgress style={{position:"absolute"}}/>
            :
            <><label
            className="AddImageLabel"
            htmlFor="image-input"><AddAPhoto fontSize="large" />
          </label>
          <input 
            type="file" accept="image/x-png,image/jpeg" 
            id="image-input" 
            onChange={this.profileImageChange}
            style={{ display: "none" }} /></>:null
            }
        </div>

        <p className="h5 mt-4 font-weight-bold">
          {user.first_name + " " + user.last_name}
        </p>

        <MembershipTypeChip
        className="mb-2"
          label={t(`membershipType.${user.membership_type}`)}
          value={user.membership_type}
        />
        
        {user.is_premium && <p className="text-monoscope mb-2" style={{ fontSize: "1.1em" }}>
          <span style={{ color: "#627180" }}>{t("renewalDate")}:</span>
          <span className="font-weight-bold">
            {moment(new Date(this.props.user.premium_end_at)).format("DD/MM/YYYY")} {`${dayDiff > 0 ? dayDiff : 0} ${t("days")}`}
          </span>
        </p>}
        {user.corporations[0] && <div className="CorpList">
            <Domain />
          <ul>{user.corporations.map(corp => <li key={corp.id}>{corp.name}</li>)}</ul>
        </div>}
        <form onSubmit={e => e.preventDefault()}>
          {authUser.role === 1 && <div>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <span className={`input-group-text border-right-0 ${editMode && 'bg-white'}`}>
                  <CardMembership />
                </span>
              </div>
              <select
                className="form-control custom-select border-left-0"
                style={{ textAlignLast: 'center' }}
                name="use_type"
                onChange={this.inputChange}
                value={use_type}
                disabled={!editMode}
              >
                {Object.keys(membershipTypes).map(type =>
                  <option key={type} value={type}>{membershipTypes[type]}</option>
                )}
              </select>
            </div>

            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <span className={`input-group-text border-right-0 ${editMode && 'bg-white'}`}>
                  <DateRange />
                </span>
              </div>
              <div style={{ width: "calc(100% - 50px)", fontFamily: "sans-serif" }}>
                <SingleDatePicker
                  focused={premiumEndAtFocus}
                  onFocusChange={value => dateFocusChange(value, 'premiumEndAtFocus')}
                  numberOfMonths={1}
                  disabled={!editMode}
                  date={premium_end_at}
                  block
                  small
                  regular
                  displayFormat="DD/MM/YYYY"
                  id="premium_end_at"
                  hideKeyboardShortcutsPanel
                  placeholder={t("unspecified")}
                  onDateChange={date => dateChange(date, "premium_end_at")}
                />
              </div>
            </div>
          </div>}
          <div className="input-group mb-2">
            <div className="input-group-prepend">
              <span className='input-group-text border-right-0'>
                <Email />
              </span>
            </div>
            <input
              type="text"
              className={`form-control text-center border-left-0 ${user.platform && 'border-right-0'}`}
              disabled
              value={email || user.fb_email}
            />
            {user.platform && <div className="input-group-append">
              <span className={`input-group-text border-left-0`}>
                {user.platform === "android" ? <Android style={{ color: "#A4C639" }} /> : user.platform === "ios" ? (<SvgIcon viewBox="0 0 24 24">
                  <path fill="#7d7d7d" d="M18.71,19.5C17.88,20.74 17,21.95 15.66,21.97C14.32,22 13.89,21.18 12.37,21.18C10.84,21.18 10.37,21.95 9.1,22C7.79,22.05 6.8,20.68 5.96,19.47C4.25,17 2.94,12.45 4.7,9.39C5.57,7.87 7.13,6.91 8.82,6.88C10.1,6.86 11.32,7.75 12.11,7.75C12.89,7.75 14.37,6.68 15.92,6.84C16.57,6.87 18.39,7.1 19.56,8.82C19.47,8.88 17.39,10.1 17.41,12.63C17.44,15.65 20.06,16.66 20.09,16.67C20.06,16.74 19.67,18.11 18.71,19.5M13,3.5C13.73,2.67 14.94,2.04 15.94,2C16.07,3.17 15.6,4.35 14.9,5.19C14.21,6.04 13.07,6.7 11.95,6.61C11.8,5.46 12.36,4.26 13,3.5Z" />
                </SvgIcon>) : ""}
              </span>
            </div>}
          </div>
          <div className="input-group mb-2">
            <div className="input-group-prepend">
              <span className={`input-group-text border-right-0 ${editMode && 'bg-white'}`}>
                <Cake />
              </span>
            </div>
            <div style={{ width: "calc(100% - 50px)", fontFamily: "sans-serif" }}>
              <SingleDatePicker
                focused={birthDateFocus}
                onFocusChange={value => dateFocusChange(value, 'birthDateFocus')}
                numberOfMonths={1}
                disabled={!editMode}
                date={birth_date}
                block
                small
                regular
                displayFormat="DD/MM/YYYY"
                id="date"
                hideKeyboardShortcutsPanel
                placeholder={t("unspecified")}
                isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                onDateChange={date => dateChange(date, "birth_date")}
              />
            </div>
          </div>
          <div className="input-group mb-2">
            <div className="input-group-prepend">
              <span className={`input-group-text border-right-0 ${editMode && 'bg-white'}`}>
                <School />
              </span>
            </div>
            <select
              className="form-control custom-select border-left-0"
              disabled={!editMode}
              style={{ textAlignLast: "center" }}
              name="education"
              onChange={inputChange}
              value={education || ""}
            >
              <option value="">{t("unspecified")}</option>
              {t("educationFilter.options", { returnObjects: true }).map(
                option => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                )
              )}
            </select>
          </div>
          <div className="input-group mb-2">
            <div className="input-group-prepend">
              <span className={`input-group-text border-right-0 ${editMode && 'bg-white'}`}>
                <Work />
              </span>
            </div>
            <select
              className="form-control custom-select border-left-0"
              disabled={!editMode}
              style={{ textAlignLast: "center" }}
              name="occupation"
              onChange={inputChange}
              value={occupation || ""}
            >
              <option value="">{t("unspecified")}</option>
              {t("occupationsFilter.options", { returnObjects: true }).map(
                option => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                )
              )}
            </select>
          </div>
          <div className="input-group mb-2">
            <div className="input-group-prepend">
              <span className={`input-group-text border-right-0 ${editMode && 'bg-white'}`}>
                <WC />
              </span>
            </div>
            <select
              className="form-control custom-select border-left-0"
              style={{ textAlignLast: "center" }}
              disabled={!editMode}
              name="gender"
              onChange={inputChange}
              value={gender || ""}
            >
              <option value="">{t("unspecified")}</option>
              <option value="male">Erkek</option>
              <option value="female">Kadın</option>
            </select>
          </div>
          <div className="input-group mb-2 justify-content-end">
            {this.isauthorized() &&<button
              className="btn btn-primary btn-sm mx-1 ButtonGroupItem"
              onClick={editModeChange}
            >
              {editMode ? t('cancel') : t('edit')}
            </button>}
            {editMode && (
              <button
                className="btn btn-primary btn-sm mx-1 ButtonGroupItem"
                onClick={updateProfile}
              >
                {t('save')}
              </button>
            )}
            {(authUser.id === this.props.user.id) && <button
              className="btn btn-primary btn-sm mx-1 ButtonGroupItem"
              onClick={() =>
                this.setState(prevState => {
                  return {
                    changePasswordMode: !prevState.changePasswordMode
                  };
                })
              }
            >
              {t('changePassword')}
            </button>}
          </div>
        </form>
        <Collapse in={changePasswordMode}>
          <form
            className="border rounded p-4"
            onSubmit={e => e.preventDefault()}
          >
            <div className="input-group mb-2 ">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <Key />
                </span>
              </div>
              <input
                value={changePassword.currentPassword.value}
                onChange={this.onPasswordChange}
                type="password"
                className={`text-center form-control ${changePassword.currentPassword.touched ? changePassword.currentPassword.isValid ? "is-valid" : "is-invalid" : ""}`}
                name="currentPassword"
                placeholder="Current Password"
              />
            </div>
            <div className="input-group mb-2 ">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <Key />
                </span>
              </div>
              <input
                value={changePassword.password.value}
                onChange={this.onPasswordChange}
                type="password"
                className={`text-center form-control ${changePassword.password.touched ? changePassword.password.isValid ? "is-valid" : "is-invalid" : ""}`}
                name="password"
                placeholder="New Password"
              />
            </div>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <Key />
                </span>
              </div>
              <input
                value={changePassword.confirmPassword.value}
                onChange={this.onPasswordChange}
                type="password"
                className={`text-center form-control ${changePassword.confirmPassword.touched ? changePassword.confirmPassword.isValid ? "is-valid" : "is-invalid" : ""}`}
                name="confirmPassword"
                placeholder="Repeat Password"
              />
            </div>
            <div className="input-group mb-2 justify-content-end">
              <button
                className="btn btn-primary btn-sm ButtonGroupItem"
                onClick={() => this.setState({ changePasswordMode: false })}
              >
                {t('cancel')}
              </button>
              <button className="btn btn-primary btn-sm ButtonGroupItem ml-2"
                onClick={this.changePassword}
                disabled={!(this.state.changePassword.password.isValid &&
                  this.state.changePassword.confirmPassword.isValid &&
                  this.state.changePassword.currentPassword.isValid &&
                  !this.props.changePasswordLoading
                )}>
                {t('change')}
              </button>
            </div>
          </form>
        </Collapse>
      </Card>
    );
  }
}
const mapStateToProps = state => {
  return {
    authUser: state.auth.user,
    changePasswordLoading: state.loading['CHANGE_PASSWORD'],
    // role: state.auth
  };
};
const mapDispatchToProps = dispatch => {
  return {
    changePassword: ({ old_password, new_password }) => dispatch(changePassword({ old_password, new_password })),
    editUser: (user) => dispatch(editUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withI18n()(ProfileCard));
