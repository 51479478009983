import React, { Component } from 'react';
import { Modal, TextField, List, ListItem, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { searchUsers } from '../../store/actions';

class SearchUser extends Component {
    state= {
        users:[],
        page:null,
        totalPage:1,
        searchText:""
    }

    handleClose = () => this.props.close();

    searchUsers = (page) => this.props.searchUsers(this.state.searchText,page)
        .then((data)=>data&&this.setState({users:data.users,totalPage:data.total_page,page:+data.page}));

    select = (user) => {
        this.props.select(user);
        this.props.close();
    }
    render () {
        return(
            <Modal 
                open
                onClose = {this.handleClose}
                >
                <div style={{backgroundColor:"white",maxWidth:500,margin:"auto",marginTop:"15vh"}}>
                    <div className="d-flex flex-column align-items-center p-4">
                        <div>
                        <TextField 
                            placeholder="Enter name or email"
                            value = {this.state.searchText}
                            onChange = {e => this.setState({searchText:e.target.value})}
                        />
                        <button className="btn btn-primary ml-4" onClick={()=>this.searchUsers(1)}>Search</button>
                        {
                            this.state.totalPage>1?
                            (<div className="btn-toolbar" role="toolbar">
                                <div className="btn-group" role="group">
                                {
                                    [...new Array(this.state.totalPage)].map((_,page)=>(
                                        <button 
                                            key={page} 
                                            className="btn btn-secondary" 
                                            disabled={page+1===this.state.page}
                                            onClick={()=>this.searchUsers(page+1)}>{page+1}
                                            </button>
                                    ))
                                }
                                </div>
                            </div>
                            ):null
                            
                        }
                    </div><List style={{maxHeight:"65vh",overflowY:"auto",width:"100%"}}>
                    {   
                        this.state.users.map(user=>(
                            
                                <ListItem key={user.id} button onClick={()=>this.select(user)}> 
                                    <Typography> {user.first_name+" "+user.last_name}</Typography>
                                    <Typography> - {user.email||user.fb_email}</Typography>
                                    <span></span>
                                </ListItem>
                           
                        ))
                    } </List>
                    </div>
                    </div>
            </Modal>)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        searchUsers : (searchText,page) =>  dispatch(searchUsers(searchText,page))
    }
}

export default connect(null,mapDispatchToProps)(SearchUser);
