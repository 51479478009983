export const filterObjectToQuery=(filters)=> {
    return Object.keys(filters)
    .filter(filterType=>filters[filterType].length>0)
    .reduce((acc,filterType)=>{
        acc[filterType]=filters[filterType].map(option=>option.value);
        if(filterType==="date") {acc["from"] =acc[filterType][0][0];acc["to"] =acc[filterType][0][1];}
        if(filterType==="corporation_id") acc[filterType]=acc[filterType][0]
        return acc;
    },{})
}

export const filterKeyGenerator = (keys) => {
    return keys.map(key=>{
        return {
            
        }
    })
}