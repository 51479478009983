import React,{Component} from 'react';
import {connect} from 'react-redux';
import {signIn, fbSignIn, forgotPassword} from '../../store/actions';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import grey from '@material-ui/core/colors/grey';
import Email from '@material-ui/icons/Email';
import Lock from '@material-ui/icons/Lock';
import './style.css';
import {withNamespaces} from 'react-i18next';
import FacebookLogin from 'react-facebook-login';
import LoginWrapper from './LoginWrapper';

class Login extends Component {
  state={
      email:"",
      password:"",
      forgot: false,
      forgotEmail: "",
      forgotSuccess: false,
  }

  changeHandler = event => {
      this.setState({
          [event.target.name]:event.target.value
      });
  }
    render(){
    const {t} = this.props;
    const {forgot} = this.state;
    return(
      <div>
        <Modal open={!!this.props.loading}>
              <CircularProgress className="Progress" size={80} style={{ color: grey[500] }}/>
        </Modal>
        <LoginWrapper>
            {forgot ?
              <div style = {{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <div style = {{maxWidth: '350px', margin: 'auto'}}>
                  <form className="LoginForm">
                    <div className = "my-4" style = {{textAlign: 'center', fontWeight: 400, color: '#888888'}}>{this.state.forgotSuccess && t('forgotPassword-success')}</div>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text LoginInputIconSpan"><Email/></span>
                      </div>
                        <input className="form-control LoginInput" name="forgotEmail" value = {this.state.forgotEmail} onChange={this.changeHandler} type="email" placeholder = {t('email')}/><br/>
                    </div>
                    <button className="btn btn-primary" style = {{width: '100%', background: '#00BC9D', border: '0px',   borderRadius: '8px'}}
                         onClick={(e)=>{e.preventDefault(); this.state.forgotEmail &&  this.props.forgotPassword(this.state.forgotEmail).then(res => !res.error && this.setState({forgotSuccess: true}))}}>{t('send')}</button>
                  </form>
                </div>
                <div className = "my-3" style = {{textAlign: 'center', color: '#888888', position: 'absolute', bottom: 0, cursor: 'pointer'}}
                  onClick = {() => this.setState({forgot:false})}>
                 {t('login')}
                </div>
             </div>
           : <div style = {{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
              <div style = {{width: '340px', margin: 'auto'}}>
                <form className="LoginForm">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text LoginInputIconSpan"><Email/></span>
                    </div>
                      <input className="form-control LoginInput" name="email" onChange={this.changeHandler} value = {this.state.email} type="email" placeholder = {t('email')}/><br/>
                  </div>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text LoginInputIconSpan"><Lock/></span>
                    </div>
                    <input className="form-control LoginInput" name="password" onChange={this.changeHandler} value = {this.state.password} type="password" placeholder={t('password')}/><br/>
                  </div>
                  <button className="btn btn-primary" style = {{width: '100%', background: '#00BC9D', border: '0px',   borderRadius: '8px'}}
                       onClick={(e)=>{e.preventDefault();this.props.signIn(this.state.email, this.state.password)}}>{t('login')}</button>
                </form>
                <div className = "my-3" style = {{textAlign: 'center', color: '#888888'}}>
                  ya da
                </div>
                <FacebookLogin
                  appId="123797858331210"
                  fields="first_name, last_name, gender ,email,picture"
                  language = {this.props.lng === 'tr' ? 'tr_TR' : 'en_US'}
                  textButton = {this.props.lng === 'tr' ? 'Facebook ile Giriş' : 'Login with Facebook'}
                  cssClass = "FacebookButton"
                  callback={user => {user.id && this.props.fbSignIn(user)}} />
              </div>
              <div className = "my-3" style = {{textAlign: 'center', color: '#888888', position: 'absolute', bottom: 0, cursor: 'pointer'}}
                 onClick = {() => this.setState({forgot:true})}>
                {t('forgotPassword')}
              </div>
          </div>}
        </LoginWrapper>
      </div>)
    }
}

const mapStateToProps= state => {
    return {
        user:state.auth.user,
        loading: state.loading["SIGN_IN"] || state.loading["FB_SIGN_IN"] || state.loading["FORGOT_PASSWORD"]
    }
}
const mapDispatchToProps = dispatch => {
    return {
        signIn: (email,password)=> dispatch(signIn(email,password)),
        fbSignIn: user=> dispatch(fbSignIn(user)),
        forgotPassword: email => dispatch(forgotPassword(email)),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(withNamespaces('common')(Login));
