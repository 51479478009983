import React, { Component } from "react";
import {
  Card,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import { cities } from "../../../../strings/values";
import {
  addCorporation,
  createCorpPrivilageCheck,
} from "../../../../store/actions";
import { SingleDatePicker, isInclusivelyBeforeDay } from "react-dates";
import moment from "moment";
import SearchUser from "../../../../components/SearchUser/SearchUser";
import "./style.css";
import { withI18n } from "react-i18next";
import { formDataUpdate } from "../../../../utils/formValidate";

import AddAPhoto from "@material-ui/icons/AddAPhoto";
import Search from "@material-ui/icons/Search";

class AddCorporation extends Component {
  state = {
    userSearchToggle: false,
    maxCorpSize: -1,
    formElements: {
      image: {
        value: "",
      },
      name: {
        value: "",
        validityRules: {
          minLength: 2,
        },
      },
      address: {
        value: "",
        isValid: false,
        touched: false,
        validityRules: {
          minLength: 1,
        },
      },
      city: {
        value: "1",
      },
      email: {
        value: "",
        validityRules: {
          isEmail: true,
        },
      },
      phone1: {
        value: "",
        validityRules: {
          minLength: 7,
        },
      },
      phone2: {
        value: "",
        validityRules: {},
      },
      start_at: {
        value: null,
        focused: false,
        validityRules: {
          required: true,
        },
      },
      end_at: {
        value: null,
        focused: false,
        validityRules: {
          required: true,
        },
      },
      user_id: {
        value: "",
        validityRules: {
          min: 1,
        },
      },
      c_type: {
        value: "school_member",
      },
      size: {
        value: 0,
        validityRules: {
          min: 1,
        },
      },
      inventory_permission:{
        value: false
      }
    },
  };
  componentDidMount() {
    if (this.props.role !== 1) {
      this.props.createCorpPrivilageCheck().then((size) => {
        if (size) {
          this.dateChangeHandler(moment(), "start_at");
          this.dateChangeHandler(moment(), "end_at");
          this.formElementChangedHandler({
            target: { name: "size", value: size },
          });
        } else alert("you are not authorized to add");
      });
    }
  }
  openUserSearch = () => {
    this.setState({ userSearchToggle: true });
  };
  closeUserSearch = () => {
    this.setState({ userSearchToggle: false });
  };
  selectUser = (user) => {
    this.formElementChangedHandler({
      target: { name: "user_id", value: +user.id },
    });
  };
  formElementChangedHandler = (event) => {
    let { name, value, files } = event.target;
    if (files) {
      const reader = new FileReader();
      reader.onload = (event) =>
        this.setState((prevState) => {
          return {
            formElements: {
              ...prevState.formElements,
              image: {
                ...this.state.formElements.image,
                data: event.target.result,
              },
            },
          };
        });
      reader.readAsDataURL(files[0]);
    }

    this.setState((prevState) => {
      return {
        formElements: formDataUpdate(prevState.formElements, value, name),
      };
    });
  };

  dateChangeHandler = (date, name) => {
    this.setState((prevState) => {
      return {
        formElements: formDataUpdate(prevState.formElements, date, name),
      };
    });
  };
  datePickerFocused = ({ focused }, name) =>
    this.setState((prevState) => {
      return {
        formElements: {
          ...prevState.formElements,
          [name]: {
            ...prevState.formElements[name],
            focused,
          },
        },
      };
    });
  addCorporation = () => {
    const corporation = {
      name: this.state.formElements.name.value,
      address: this.state.formElements.address.value,
      city: cities[this.state.formElements.city.value],
      email: this.state.formElements.email.value,
      phone1: this.state.formElements.phone1.value,
      phone2: this.state.formElements.phone2.value,
      start_at: this.state.formElements.start_at.value
        ? new Date(this.state.formElements.start_at.value._d)
        : null,
      end_at: this.state.formElements.end_at.value
        ? new Date(this.state.formElements.end_at.value._d)
        : null,
      user_id: +this.state.formElements.user_id.value,
      c_type: this.state.formElements.c_type.value,
      image: this.state.formElements.image.data,
      size: +this.state.formElements.size.value,
      inventory: this.state.formElements.inventory_permission.value
    };
    this.props.addCorporation(corporation).then((corporation) => {
      if (corporation) this.props.history.goBack();
    });
  };
  checkFormValidity = () => {
    const {
      email,
      name,
      size,
      start_at,
      end_at,
      phone1,
    } = this.state.formElements;
    return [email, name, size, start_at, end_at, phone1].every(
      (parameter) => parameter.isValid
    );
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Card
          className="shadow p-4 AddCorporation"
          style={{ overflow: "visible", maxWidth: 500 }}
        >
          <Typography variant="h6" className="text-center">
            {t("corporations_page.add_new_corp")}
          </Typography>
          <form
            className="mt-4 mx-auto"
            style={{ width: "95%", maxWidth: 600, textAlign: "center" }}
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="my-4">
              <div
                style={{
                  width: 300,
                  height: 300,
                  borderRadius: "50%",
                  backgroundColor: "#eee",
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px solid #7d7d7d",
                  backgroundImage: `url(${this.state.formElements.image.data})`,
                  backgroundSize: "cover",
                }}
              >
                <label htmlFor="file-input">
                  <AddAPhoto
                    style={{
                      width: 70,
                      height: 70,
                      cursor: "pointer",
                      color: this.state.formElements.image.data
                        ? "rgba(255,255,255,0.3)"
                        : "#3f3f3f",
                    }}
                  />
                </label>

                <input
                  type="file"
                  id="file-input"
                  style={{ display: "none" }}
                  name="image"
                  onChange={this.formElementChangedHandler}
                  value={this.state.formElements.image.value}
                  accept="image/x-png,image/gif,image/jpeg"
                />
              </div>
            </div>
            <div className="input-group mb-2">
              <input
                type="text"
                className={`text-center form-control ${
                  this.state.formElements.name.touched
                    ? this.state.formElements.name.isValid
                      ? "is-valid"
                      : "is-invalid"
                    : ""
                }`}
                placeholder={t("corporations_page.corp_name")}
                name="name"
                onChange={this.formElementChangedHandler}
                value={this.state.formElements.name.value}
              />
            </div>
            <div className="input-group mb-2">
              <input
                type="text"
                className="form-control text-center"
                placeholder={t("corporations_page.address")}
                name="address"
                onChange={this.formElementChangedHandler}
                value={this.state.formElements.address.value}
              />
            </div>
            <div className="input-group mb-2">
              <select
                className="form-control custom-select"
                style={{ textAlignLast: "center" }}
                name="city"
                onChange={this.formElementChangedHandler}
                value={this.state.formElements.city.value || ""}
              >
                {Object.keys(cities).map((cityCode) => (
                  <option key={cityCode} value={cityCode}>
                    {cities[cityCode]}
                  </option>
                ))}
              </select>
            </div>

            <div className="input-group mb-2">
              <input
                type="email"
                className={`text-center form-control ${
                  this.state.formElements.email.touched
                    ? this.state.formElements.email.isValid
                      ? "is-valid"
                      : "is-invalid"
                    : ""
                }`}
                placeholder={t("corporations_page.email")}
                name="email"
                onChange={this.formElementChangedHandler}
                value={this.state.formElements.email.value}
              />
            </div>
            <div className="input-group mb-2">
              <input
                type="text"
                className="form-control text-center"
                placeholder={t("corporations_page.phone") + " 1"}
                name="phone1"
                onChange={this.formElementChangedHandler}
                value={this.state.formElements.phone1.value}
              />
            </div>
            <div className="input-group mb-2">
              <input
                type="text"
                className="form-control text-center"
                placeholder={t("corporations_page.phone") + " 2"}
                name="phone2"
                onChange={this.formElementChangedHandler}
                value={this.state.formElements.phone2.value}
              />
            </div>

            {this.props.role === 1 && (
              <>
                <div className="input-group mb-2">
                  <div
                    title="Kurum üyelerinin griceviz premium üyelik başlangıcı."
                    style={{
                      width: "100%",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <SingleDatePicker
                      focused={this.state.formElements.start_at.focused}
                      onFocusChange={(focus) =>
                        this.datePickerFocused(focus, "start_at")
                      }
                      numberOfMonths={1}
                      date={this.state.formElements.start_at.value}
                      block
                      small
                      regular
                      displayFormat="DD/MM/YYYY"
                      id="start_at"
                      hideKeyboardShortcutsPanel
                      placeholder={t("corporations_page.start_date")}
                      isOutsideRange={(day) =>
                        isInclusivelyBeforeDay(day, moment())
                      }
                      onDateChange={(date) =>
                        this.dateChangeHandler(date, "start_at")
                      }
                    />
                  </div>
                  
                </div>
                <div className="input-group mb-2">
                  <div
                    style={{
                      width: "100%",
                      fontFamily: "sans-serif",
                    }}
                    title="Kurum üyelerinin griceviz premium üyelik bitişi."
                  >
                    <SingleDatePicker
                      focused={this.state.formElements.end_at.focused}
                      onFocusChange={(focus) =>
                        this.datePickerFocused(focus, "end_at")
                      }
                      numberOfMonths={1}
                      date={this.state.formElements.end_at.value}
                      block
                      small
                      regular
                      displayFormat="DD/MM/YYYY"
                      id="end_at"
                      hideKeyboardShortcutsPanel
                      placeholder={t("corporations_page.end_date")}
                      isOutsideRange={(day) =>
                        isInclusivelyBeforeDay(day, moment())
                      }
                      onDateChange={(date) =>
                        this.dateChangeHandler(date, "end_at")
                      }
                    />
                  </div>
                </div>
                <div className="input-group mb-2">
                  <input
                    type="number"
                    className={`text-center form-control ${
                      this.state.formElements.user_id.touched
                        ? this.state.formElements.user_id.isValid
                          ? "is-valid"
                          : "is-invalid"
                        : ""
                    }`}
                    placeholder={t("user") + " id"}
                    name="user_id"
                    onChange={this.formElementChangedHandler}
                    value={this.state.formElements.user_id.value}
                    disabled
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <Search
                        onClick={this.openUserSearch}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-2">
                  <select
                    className="form-control custom-select"
                    style={{ textAlignLast: "center" }}
                    name="c_type"
                    onChange={this.formElementChangedHandler}
                    value={this.state.formElements.c_type.value}
                  >
                    <option value="school_member">
                      {t("corporations_page.corp_type.school_member")}
                    </option>
                  </select>
                </div>
                <div className="input-group mb-2">
                  <input
                    type="number"
                    className={`text-center form-control ${
                      this.state.formElements.size.touched
                        ? this.state.formElements.size.isValid
                          ? "is-valid"
                          : "is-invalid"
                        : ""
                    }`}
                    placeholder={t("corporations_page.size")}
                    name="size"
                    onChange={this.formElementChangedHandler}
                    value={this.state.formElements.size.value}
                  />
                </div>
                <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.formElements.inventory_permission.value}
                        onChange={e=>this.setState({formElements:{...this.state.formElements, inventory_permission:{value:e.target.checked} }})}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Kurum Kullanıcıları İçin Meslekilgi Yetkisi"
                  />
              </>
            )}
            <div className="input-group mb-2 justify-content-end">
              <button
                className="btn btn-primary mx-1 ButtonGroupItem"
                onClick={this.addCorporation}
                disabled={!this.checkFormValidity()}
              >
                {this.props.t("save")}
              </button>
            </div>
          </form>
        </Card>
        {this.state.userSearchToggle && (
          <SearchUser close={this.closeUserSearch} select={this.selectUser} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    addCorporationLoading: state.loading["ADD_CORPORATION"],
    role: state.auth.user.role,
    createCorpPrivilageCheckLoading:
      state.loading["CREATE_CORP_PRIVILAGE_CHECK"],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCorporation: (corporation) => dispatch(addCorporation(corporation)),
    createCorpPrivilageCheck: () => dispatch(createCorpPrivilageCheck()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withI18n()(AddCorporation));
