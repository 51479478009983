import React from 'react';
import { Typography } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import './style.css';


const orderByCategoriesList = ({ labels, data, img, title, t }) => {
  const categoryGames = t('category_games', { returnObjects: true });
  const categories = Object.keys(categoryGames);

  const games = categories.reduce((acc, category) => {
    acc[category] = Object.keys(categoryGames[category].games).reduce((acc,game)=>{
      acc[game] = data[game];
      return acc;
    },{})
    return acc;
  }, {});

  
  console.log(data);
  console.log("games",games);
  console.log("categories",categories);
  return (
    <>
      <div>
        <img src={img} />
        <Typography variant="h3">{title}</Typography>
      </div>
      <table className="OrderByCategoryList ReportSimpleList" cellspacing="0" border="1" style={{
        pageBreakInside: "auto",
        pageBreakAfter: "always",
        border: "1px solid #ccc",  
        borderCollapse: "collapse",
        backgroundColor: "white"
      }}>
        <thead style={{ display: "table-header-group",}}>
          <tr style={{ pageBreakInside: "avoid", pageBreakAfter: "auto"}}>
            {labels.map(label => <th key={label.for}>{label.text}</th>)}
          </tr>
        </thead>
        <tbody>
          {
            categories.map((category, cIndex) => (
              <React.Fragment key={cIndex}>{
                Object.keys(games[category]).map((game, gIndex) => (
                  <React.Fragment key={gIndex}>
                    {
                      (games[category][game][0]&&games[category][game]||[false]).map((u, uIndex) => (
                         <tr key={uIndex} style={{ pageBreakInside: "avoid", pageBreakAfter: "auto"}}>
                          {
                            gIndex === 0 && uIndex === 0 && <td rowSpan={Object.keys(games[category]).reduce((acc,game)=>acc+(games[category][game] ? games[category][game].length : 1),0)} className="CategoryName" style={{backgroundColor:"white",border: "1px solid black",}}>
                            <div> {t(`category_games.${category}.text`)}</div>
                          </td>}
                          {uIndex === 0 &&
                          <td valign="top" rowSpan={games[category][game].length||1} style={{backgroundColor:"white",border: "1px solid #ccc", }}> {t(`games.${game}.name`)}</td>}
                          
                          {u?
                          <>
                          <td style={{border: "1px solid #ccc", pageBreakInside: "avoid"}}>{u.user_name}</td>
                          <td style={{border: "1px solid #ccc", pageBreakInside: "avoid"}}>{u.score}</td>
                          </>
                          :<td colSpan="2" style={{border: "1px solid #ccc",}}>Oyun Bilgisi Yok</td>
                          }
                        </tr>
                      ))
                    }
                  </ React.Fragment>
                ))
              }</React.Fragment>
            ))
          }
        </tbody>
      </table>
    </>
  );
}

export default withNamespaces('common')(orderByCategoriesList);