import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CircularProgress, Chip, IconButton, Collapse } from '@material-ui/core';
import { SelectionState, PagingState, CustomPaging, SortingState, DataTypeProvider } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, TableSelection, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import { Link } from 'react-router-dom';
import SelectedFiltersBar from '../../../components/SelectedFiltersBar';
import CustomInput from '../../../components/ui/CustomInput';
import FilterBar from '../../../components/FilterBar';
import DateRangePicker from '../../../components/DateRangePicker';
import AddUser from './AddUser';
import MembershipTypeChip from '../../../components/MembershipTypeChip';
import { getUsers, getCorporationListForFilter, getUsersTable } from '../../../store/actions';
import { filterObjectToQuery } from '../../../utils/helpers';
import Person from '@material-ui/icons/Person';
import Mood from '@material-ui/icons/Mood';
import MoodBad from '@material-ui/icons/MoodBad';
import Android from '@material-ui/icons/Android';
import Attachment from '@material-ui/icons/Attachment';
import PersonAdd from '@material-ui/icons/PersonAdd';
import SvgIcon from '@material-ui/core/SvgIcon';
import GradientSummaryCard from '../../../components/GradientSummaryCard';
import { withNamespaces } from 'react-i18next';

const columns = t => [
    { name: "first_name", title: t('users_list.columns.first_name').toUpperCase() },
    { name: "membership_type", title: t("users_list.columns.use_type").toUpperCase() },
    { name: "created_at", title: t("users_list.columns.created_at").toUpperCase() },
    { name: "premium_start_at", title: t("users_list.columns.premium_start_at").toUpperCase() },
    { name: "premium_end_at", title: t("users_list.columns.premium_end_at").toUpperCase() },
    { name: "last_played_at", title: t("users_list.columns.last_played_at").toUpperCase() },
    { name: "platform", title: t("users_list.columns.platform").toUpperCase() },
    //{name:"manage",title:" "},
];
const columnExtensions = [
    { columnName: "first_name", align: "left" },
    { columnName: "membership_type", align: "center" },
    { columnName: "created_at", align: "center", width: 150 },
    { columnName: "premium_start_at", align: "center", width: 150 },
    { columnName: "premium_end_at", align: "center", width: 150 },
    { columnName: "last_played_at", align: "center", width: 150 },
    { columnName: "platform", align: "center", width: 150 },
    //  {columnName:"manage",align:"right"}
]

const sortingStateColumnExtensions= [
    { columnName: 'last_played_at', sortingEnabled: false },
  ]
class UserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selection: [],
            filters: [],
            order: [{ columnName: 'created_at', direction: 'desc' }],
            search_text: "",
            addUserToggle: false
        }
        this.datePicker = React.createRef();
        this.userList = React.createRef();
    }
    componentDidMount() {
        if (!this.props.users[0])
            this.getUsers(0);
    }
    getUsers = (page) => {
        const order = {
            column: this.state.order[0].columnName,
            direction: this.state.order[0].direction
        }
        this.props.getUsers(page, this.state.filters, order, this.state.search_text || null);
    }
    changeSelection = selection => this.setState({ selection });
    getUsersTable = () => {
        const order = {
            column: this.state.order[0].columnName,
            direction: this.state.order[0].direction
        }
        this.props.getUsersTable(this.state.filters, order, this.state.search_text || null, this.props.lng);
    }
    filterAddedHandler = filter => {
        const filters = this.state.filters[filter.name] || null;
        let newFilters;
        if (filters && filter.name !== "date" && !filter.singleSelect) newFilters = [...filters, filter.option];
        else newFilters = [filter.option];
        const division = {};
        if (filter.name === "corporation_id") division.division_id = []
        this.setState({
            filters: {
                ...this.state.filters,
                [filter.name]: newFilters,
                ...division
            }
        });
    }
    nameFormatter = (props) => {
        const image_file_name = props.row.image_file_name ? props.row.image : require("../../../assets/images/missing.png");

        return (
            <><img width="35" height="35" src={image_file_name} style={{ borderRadius: "50%", objectFit: "cover" }} alt={props.row.first_name} />
                <Link to={`${this.props.match.url}/${props.row.id}`} style={{ color: "#7B7B7B", fontWeight: "bold" }}> {` ${props.row.first_name} ${props.row.last_name}`}</Link>
            </>
        )
    }
    membershipFormatter = ({ value, row }) => <MembershipTypeChip label={this.props.t(`membershipType.${value}`)+(row.is_family_administrator?' *':'')} value={value} />
    platformFormatter = ({ value }) => {
        return value === "android" ? <Android style={{ color: "#A4C639" }} /> : value === "ios" ? (<SvgIcon viewBox="0 0 24 24">
            <path fill="#7d7d7d" d="M18.71,19.5C17.88,20.74 17,21.95 15.66,21.97C14.32,22 13.89,21.18 12.37,21.18C10.84,21.18 10.37,21.95 9.1,22C7.79,22.05 6.8,20.68 5.96,19.47C4.25,17 2.94,12.45 4.7,9.39C5.57,7.87 7.13,6.91 8.82,6.88C10.1,6.86 11.32,7.75 12.11,7.75C12.89,7.75 14.37,6.68 15.92,6.84C16.57,6.87 18.39,7.1 19.56,8.82C19.47,8.88 17.39,10.1 17.41,12.63C17.44,15.65 20.06,16.66 20.09,16.67C20.06,16.74 19.67,18.11 18.71,19.5M13,3.5C13.73,2.67 14.94,2.04 15.94,2C16.07,3.17 15.6,4.35 14.9,5.19C14.21,6.04 13.07,6.7 11.95,6.61C11.8,5.46 12.36,4.26 13,3.5Z" />
        </SvgIcon>) : ""
    }
    lastPlayedAtFormatter = ({value}) => value ? new Date(value).toLocaleDateString(this.props.lng): "";
    filterRemovedHandler = filter => {
        const newFilters = this.state.filters[filter.name].filter(option => option.value !== filter.option.value);
        const division = {};
        if (filter.name === "corporation_id") division.division_id = []
        this.setState({
            filters: {
                ...this.state.filters,
                [filter.name]: newFilters,
                ...division
            }
        }, () => {
            if (filter.name === "date")
                this.datePicker.current.clearDates();
        });
    }
    changeOrder = order => {
        this.setState({ order }, () => this.getUsers(0));

    }
    addUserToggleHandler = () => {
        this.setState(prevState => {
            return { addUserToggle: !prevState.addUserToggle }
        });
    }
    render() {
        const { t, users } = this.props;
        const summary = this.props.summary || {};
        const { filters, selection } = this.state;
        const filterKeys = [
            t('platformFilter', { returnObjects: true })
        ];
        if (this.props.role !== 101) {
            filterKeys.unshift(t('membershipTypeFilter', { returnObjects: true }))
            filterKeys.push({
                ...t('corporationFilter', { returnObjects: true }),
                singleSelect: true,
                loading: this.props.corpsLoading,
                getData: this.props.getCorporationListForFilter,
                options: this.props.corporations
            });
            if (this.state.filters["corporation_id"] && this.state.filters["corporation_id"][0]) {
                filterKeys.push({
                    ...t("divisionFilter", { returnObjects: true }),
                    options: this.state.filters["corporation_id"][0].divisions
                })
            }
        }
        const filterKeysInd = [
            t('occupationsFilter', { returnObjects: true }),
            t('genderFilter', { returnObjects: true }),
            t('educationFilter', { returnObjects: true })
        ];
        return (
            <>
                {this.props.role === 1 && <div className="row" >
                    <div className="col-md-4">
                        <GradientSummaryCard title={t('summary.total_user_count')} value={summary.total_user_count || 0} icon={<Person />}
                        />
                    </div>
                    <div className="col-md-4">
                        <GradientSummaryCard style={{ background: "#20A88B" }} waveColor="#26B496" title={t('summary.all_time_subscription_uniq_user_count')}
                            value={summary.all_time_subscription_uniq_user_count || 0} icon={<Mood />} />
                    </div>
                    <div className="col-md-4">
                        <GradientSummaryCard style={{ background: "#E76D76" }} waveColor="#F4737C" title={t('summary.today_played_uniq_user_count')}
                            value={summary.today_played_uniq_user_count || 0} icon={<MoodBad />} />
                    </div>
                </div>}
                <Collapse in={this.state.addUserToggle}>
                    <AddUser isAdmin={true} />
                </Collapse>
                <div className="row card shadow d-block p-4" style={{ position: "relative", width: "100%", margin: "auto" }} ref={this.userList}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <p className="h5 d-inline m-0 p-0 mr-4">{t('users')} <IconButton onClick={this.addUserToggleHandler}><PersonAdd /></IconButton> </p>
                            <Chip style={{ background: "linear-gradient(to right, #159957, #155799)", fontWeight: "normal", color: "white" }}
                                label={`${t('filter_result')}:${this.props.total}`} />
                            <SelectedFiltersBar filters={this.state.filters} onFilterRemove={this.filterRemovedHandler} />

                            <FilterBar filterKeys={filterKeys} onFilterAdd={this.filterAddedHandler} onFilterRemove={this.filterRemovedHandler}
                                selectedFilters={filters} />
                            <DateRangePicker onDateSelect={this.filterAddedHandler} ref={this.datePicker} text={t('date')} />
                            <FilterBar filterKeys={filterKeysInd} onFilterAdd={this.filterAddedHandler} onFilterRemove={this.filterRemovedHandler}
                                selectedFilters={filters} />
                            <button className="btn btn-primary btn-sm ButtonGroupItem" style={{ marginLeft: ".5em" }} onClick={() => this.getUsers(0)}>{t('filter')}</button>
                        </div>
                        <div className="justify-content-end">
                            <div className="row mr-4 mb-2">
                                <CustomInput lefticon={<Person style={{ color: "#7a7a7a" }} />} placeholder={t('searchUser')} value={this.state.search_text} onChange={e => this.setState({ search_text: e.target.value })} />
                            </div>
                            <div className="row">
                                <button className="btn btn-light rounded" style={{ color: "#7b7b7b" }} disabled={this.props.usersTableLoading} onClick={this.getUsersTable}><Attachment /><span>Export To Excel</span></button>
                            </div>
                        </div>
                    </div>
                    <Grid columns={columns(t)} rows={users}>
                        <SelectionState selection={selection} onSelectionChange={this.changeSelection} />
                        <PagingState currentPage={this.props.currentPage} onCurrentPageChange={(page) => { this.getUsers(page); window.scrollTo({ top: this.userList.current.offsetTop, behavior: "smooth" }) }}
                            pageSize={30}
                        />
                        <DataTypeProvider for={["first_name"]} formatterComponent={this.nameFormatter} />
                        <DataTypeProvider for={["membership_type"]} formatterComponent={this.membershipFormatter} />
                        {/* <DataTypeProvider for={["manage"]} formatterComponent={this.manageFormatter} /> */}
                        <DataTypeProvider for={["platform"]} formatterComponent={this.platformFormatter} />
                        <DataTypeProvider for={["last_played_at"]} formatterComponent={this.lastPlayedAtFormatter} />
                        <CustomPaging totalCount={this.props.total} />
                        <Table columnExtensions={columnExtensions} />
                        <SortingState sorting={this.state.order} onSortingChange={this.changeOrder} columnExtensions={sortingStateColumnExtensions}/>
                        <TableHeaderRow showSortingControls />
                        <PagingPanel />
                        <TableSelection />
                    </Grid>
                    {this.props.usersLoading && <div style={{ position: "absolute", left: 0, top: 0, width: "100%", height: "100%", backgroundColor: "rgba(255,255,255,.5)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CircularProgress></CircularProgress>
                    </div>}
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        users: state.users.users,
        currentPage: state.users.currentPage,
        totalPage: state.users.totalPage,
        total: state.users.total,
        usersLoading: state.loading["USERS"],
        usersTableLoading: state.loading["USERS_TABLE"],
        corporations: state.corporations.corporationsForFilter,
        corpsLoading: state.loading["CORPORATION_LIST_FOR_FILTER"],
        summary: state.statistics.summary,
        role: state.auth.user.role
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getUsers: (page, filters, order, search_text) => dispatch(getUsers(page, filterObjectToQuery(filters || {}), order, search_text)),
        getUsersTable: (filters, order, search_text, locale) => dispatch(getUsersTable(filterObjectToQuery(filters || {}), order, search_text, locale)),
        getCorporationListForFilter: () => dispatch(getCorporationListForFilter())
    }

}
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('common')(UserList));