import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Sidebar from '../../components/About/Sidebar';
import FAQ from '../../components/About/SubPages/FAQ';
import CHC from '../../components/About/SubPages/CHC';
import About from '../../components/About';
import SSS from '../../components/About/SubPages/SSS';
import { Card } from "@material-ui/core";
import './style.css';
export default () => (<div className="row">
    <div className="col-md-3 col-lg-2 col-sm-4"><Sidebar/></div> 
    <div className="col-md-9 col-lg-10 col-sm-8 mb-5 "> 
      <Card className=" shadow AboutPages" style={{ overflow: "visible" }}>  
      <Switch>
             <Route exact path="/about/chc" component={CHC}/>
            <Route exact path="/about/faq" component={FAQ}/>
            <Route exact path="/about" component={About}/>
            <Route exact path="/about/sss" component={SSS}/>
        </Switch>
        </Card>
    </div>
</div>);
