import React, { Component } from "react";
import { connect } from "react-redux";
import "./style.css";
import categoryImages from "../../../assets/images/Categories";
import { multiplePagePrint } from "../../../utils/multiplePagePrint";
import { getUserComparisonInfo } from "../../../store/actions";
import { withNamespaces } from "react-i18next";
import {
  bgColors,
  chartConfig,
  days,
  months,
} from "../../Statistics/chartOptions";
import { Bar, Line } from "react-chartjs-2";
import { t } from "i18next/dist/commonjs";
import logo from "../../../assets/images/rapor-logo.png";
import brain from "../../../assets/images/rt-brain.png";
import RadarChart from "../../../components/Charts/RadarChart";
import moment from "moment";
import CakeIcon from "@material-ui/icons/Cake";
import { Avatar } from "@material-ui/core";

const Row = ({ children, style, className }) => {
  return (
    <div
      style={{ display: "flex", alignItems: "center", width: "100%", ...style }}
      className={className}
    >
      {children}
    </div>
  );
};

const Column = ({ children, style, className }) => {
  return (
    <div
      className={className}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...style,
      }}
    >
      {children}
    </div>
  );
};

const gricevizLogo = <img className="gricevizLogo" src={logo} />;

const brainImage = (
  <div className="brainImageBox">
    <img className="brainImage" src={brain} />
    <Column>
      <div className="brainImageText">Kullanıcı Raporu</div>
      <div className="headerDate">
        {moment(new Date()).format("DD.MM.YYYY")}
      </div>
    </Column>
  </div>
);

const generateSubHeader = (txt) => {
  return <div className="subHeader">{txt}</div>;
};

const generatePageFooter = ({ userId, authUserName, pageNo }) => {
  return (
    <Row className="pageFooter">
      <Row className="footerLeftItemRow">
        <div className="footerLeftItem">
          <b>User Id: </b>
          {userId}
        </div>
        <div className="footerLeftItem">
          <b>Rapor Tarihi: </b>
          {moment(new Date()).format("L")}
        </div>
        <div className="footerLeftItem">
          <b>Raporu Alan Kişi: </b>
          {authUserName}
        </div>
      </Row>

      <div className="footerPageNo">
        <b>Sayfa: </b>
        {pageNo + "/4"}
      </div>
    </Row>
  );
};

const generateAvatar = (url) => {
  // avatar'ın aslında paramatre olarak gelen url'den çekilmesi lazım. Ekrana resim düzgün geliyor ama html2pdf ile pdf'e aktarırken 
  // cors policy vırt zırt hatası yüzünden pdf'e aktarılmıyor. Bir türlü düzeltemedim, bunun için proxy server filan kurmak gerekebilir.
  // aynı durum material icon'ları çekerken de oluyor. Harici bir url'den çekilen tüm resimler pdf'e aktarırken cors hatasına sebep oluyor.
  // TODO: url'deki resmi pdf'e kopyala.
  return (
    <img
      className="avatar"
      //crossOrigin="anonymous"
      src={ require("../../../assets/images/missing.png")
        
      }
    />
  );
};

export class UserReport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.categories = props.t("category_games", { returnObjects: true });
  }

  generateCategoryBox = (ctgName) => {
    const ctg = this.categories[ctgName];

    const rawData = this.state.rawData;
    if (!rawData) return null;

    const chartData = this.convertRawDataToChartData(rawData, ctg);
    const iconPath = categoryImages[ctgName];
    return (
      <Row
        style={{ alignItems: "flex-start" }}
        className="categoryBox"
        key={ctgName + "_category_box"}
      >
        <Column
          className="categoryIconAndFeaturesRow"
          style={{ alignItems: "flex-start" }}
        >
          <Row>
            <img src={iconPath} className="categoryIcon" />
            <div className="categoryName">{ctg.text}</div>
          </Row>

          <>
            {ctg.skills.map((skill) => {
              return (
                <>
                  <div className="featureName" key={skill.text + "_1"}>
                    <span style={{ fontSize: "18pt", marginRight: 2 }}>
                      &#183;
                    </span>
                    {skill.text}
                  </div>
                  {skill.subSkills.map((subSkill) => {
                    return (
                      <div
                        className="subFeature"
                        key={subSkill + "_" + skill.text}
                      >
                        {"- " + subSkill}
                      </div>
                    );
                  })}
                </>
              );
            })}
          </>
        </Column>

        <div className="scoreProgressDiv">
          <Line data={chartData} options={chartConfig} />
        </div>
      </Row>
    );
  };

  convertRawDataToChartData = (rawData, ctg) => {
    const datasets = rawData.map((user, index) => {
      return {
        data: (() => {
          let result = [];
          for (let i = 0; i < Object.values(user.data)[0].length; i++) {
            let keys = Object.keys(user.data).filter((key) =>
              Object.keys(ctg.games).includes(key)
            );
            let values = keys.map((key) => user.data[key]);
            result[i] = values.reduce((acc, cur) => {
              return (acc = acc + cur[i]);
            }, 0);
          }

          return result;
        })(),
        backgroundColor: bgColors[user.id % bgColors.length],
        borderColor: bgColors[user.id % bgColors.length],
        fill: false,
      };
    });

    let labels = months(this.props.t);

    return {
      labels,
      datasets: datasets,
      borderWidth: 1,
    };
  };

  componentDidMount() {
    this.props
      .getUserComparisonInfo([this.props.user.id], "monthly_game_scores")
      .then((res) => {
        this.setState({ rawData: res });
      });

    this.props
      .getUserComparisonInfo([this.props.user.id], "level_distribution")
      .then((res) => {
        this.setState({ gameLevelData: res });
      });
  }

  exportToPdfButton = (
    <button
      onClick={() =>
        multiplePagePrint({
          settings: {},
          pageIdArray: ["tum_sayfalar"], // ["page-1", "page-2", "page-3", "page-4"],
          fileName: this.props.user
            ? "GriCeviz Rapor (" +
              this.props.user.first_name +
              " " +
              this.props.user.last_name +
              ")"
            : "Griceviz Rapor",
        })
      }
    >
      PDF indir
    </button>
  );

  generatePageDiv = (pageNo, items) => {
    const user = this.props.user || { id: "??" };
    const authUser = this.props.authUser;
    return (
      <div className="onePageDiv" id={"page-" + pageNo}>
        {brainImage}
        <Row
          style={{
            alignItems: "flex-start",
            justifyContent: "space-between",
            marginBottom: "30px",
          }}
        >
          {gricevizLogo}
          {brainImage}
        </Row>
        {items}
        {generatePageFooter({
          userId: user.id,
          authUserName: authUser.first_name + " " + authUser.last_name,
          pageNo: pageNo,
        })}
      </div>
    );
  };

  render() {
    const user = this.props.user || { first_name: "Kullanıcı çekilemedi" };
    const {
      statsListByCategory,
      radarData,
      statsListByGameForReport,
    } = this.props;

    const gameLevelData = this.state.gameLevelData;

    const userName = (
      <div className="name">{user.first_name + " " + user.last_name}</div>
    );

    const birthDate = (
      <div className="birthDate">
        {/* <CakeIcon className="birthDateIcon" /> */}
        {user.birth_date}
      </div>
    );

    const radar = (
      <div className="radarBox">
        <div className="headerBigText">Ağırlıklı Puan Dağılımı</div>
        <RadarChart data={radarData} />
      </div>
    );

    const playCountsByCategory = (
      <div className="playCountsByCategory">
        <div className="headerBigText">Oynama Sayıları</div>
        {statsListByCategory}
      </div>
    );

    const gameLeveles = gameLevelData && (
      <div className="gameLevelsBox">
        <div className="headerBigText">Oyun Seviye Durumu</div>
        {
          <Bar
            data={{
              labels: Object.keys(gameLevelData[0].data).map((data) =>
                this.props.t("chart_labels." + data)
              ),
              datasets: gameLevelData.map((user, index) => {
                return {
                  label: `${index + 1} - ${user.first_name} ${user.last_name}`,
                  data: Object.values(user.data),
                  backgroundColor: bgColors[user.id % bgColors.length],
                  backgroundColor: bgColors[user.id % bgColors.length],
                  borderColor: bgColors[user.id % bgColors.length],
                  fill: true,
                };
              }),
              borderWidth: 1,
            }}
            options={chartConfig}
          />
        }
      </div>
    );

    return (
      <div className="container">
        {this.exportToPdfButton}

        <div id="tum_sayfalar" className="allPages">
          {this.generatePageDiv(1, [
            //avatar
            generateAvatar(user.image),
            userName,
            birthDate,
            radar,
          ])}

          {this.generatePageDiv(2, [
            generateSubHeader("Kategorilere Göre Puan Gelişimi"),
            this.generateCategoryBox("attention"),
            this.generateCategoryBox("logic"),
            this.generateCategoryBox("memory"),
          ])}

          {this.generatePageDiv(3, [
            this.generateCategoryBox("numeric"),
            this.generateCategoryBox("visual"),
            this.generateCategoryBox("words"),
          ])}

          {this.generatePageDiv(4, [
            playCountsByCategory,
            // statsListByGameForReport,
            gameLeveles,
          ])}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserComparisonInfo: (user_ids, type) =>
      dispatch(getUserComparisonInfo(user_ids, type)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("common")(UserReport));
