import React, { Component } from "react";
import { connect } from "react-redux";
import {
  FindInPage,
  PieChart,
  Category,
  ViewList,
  Ballot,
  BallotOutlined,
  ClearAll,
  DeleteSweep,
  DeleteSweepOutlined,
} from "@material-ui/icons";
import CustomInput from "../../components/ui/CustomInput";
import { withNamespaces } from "react-i18next";
import { Card, Typography, CircularProgress } from "@material-ui/core";
import {
  getUserComparisonInfo,
  getCorporationDetails,
  getDivisionUsers,
} from "../../store/actions";
import Chip from "@material-ui/core/Chip";
import { Bar, Line } from "react-chartjs-2";
import { bgColors, chartConfig, days, months } from "./chartOptions";
import "./style.css";

class CompareUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      divisions: [],
      searchText: "",
      selectedDivisionIndex: -1,
      selectedUsers: [],
      divisionFilterText: "",
      userFilterText: "",
      selectedChartOption: "",
      selectedCategoryOption: "total",
      selectedGameOption: "",
      chartData: {},
      rawData: [],
    };
    this.chartOptions = props.t("statistics.chart_options", {
      returnObjects: true,
    });
    this.categoryGamesOptions = props.t("category_games", {
      returnObjects: true,
    });
    this.allGames = Object.values(
      props.t("category_games", { returnObjects: true })
    ).reduce((acc, category) => {
      return {
        ...acc,
        ...category.games,
      };
    }, {});
  }

  componentDidMount() {
    if (!(this.props.family && this.props.self) && this.props.corporation_id)
      this.props
        .getCorporationDetails(this.props.corporation_id)
        .then((corporation) =>{
          this.setState({
            divisions: corporation ? corporation.divisions : [],
          });
        }
         
        );

    this.setState({
      selectedChartOption: Object.keys(this.chartOptions)[0],
      selectedCategoryOption: "total",
      selectedGameOption: "total",
    });
    if (this.props.self)
      this.setState(
        {
          selectedUsers: [
            this.props.user_id
              ? { id: this.props.user_id }
              : { ...this.props.authUser },
          ],
        },
        this.getUserInfo
      );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.corporation_id !== this.props.corporation_id &&
      this.props.corporation_id
    )
      this.props
        .getCorporationDetails(this.props.corporation_id)
        .then((corporation) =>
          this.setState({
            divisions: corporation ? corporation.divisions : [],
            selecetedDivisionIndex: 0,
          })
        );
  }

  changeHandler = (e) => {
    let { name, value } = e.target;

    if (name === "selectedChartOption") {
      //------------------------------------------- CHART TİPİ DEĞİŞTİYSE
      this.setState(
        {
          [name]: value,
          selectedCategoryOption: "total",
          selectedGameOption: "total",
        },
        () => this.getUserInfo()
      );
    } else if (name === "selectedCategoryOption") {
      // ------------------------------------------- GÖSTERİLECEK  KATEGORİ DEĞİŞTİYSE
      const isTimeData = Array.isArray(
        Object.values(this.state.rawData[0].data)[0]
      );
      const { selectedChartOption } = this.state;
      let isMonthly = ["monthly_game_counts", "monthly_game_scores"].includes(
        selectedChartOption
      );
      let datasets = [];
      if (value === "total") {
        datasets = this.state.rawData.map((user, index) => {
          return {
            label: `${index + 1} - ${user.first_name} ${user.last_name}`,
            data: (() => {
              let result = [];
              if (isTimeData)
                for (let i = 0; i < Object.values(user.data)[0].length; i++) {
                  result[i] = Object.values(user.data).reduce((acc, cur) => {
                    return (acc = acc + cur[i]);
                  }, 0);
                }
              else result = Object.values(user.data);

              return result;
            })(),
            backgroundColor: bgColors[user.id % bgColors.length],
            borderColor: bgColors[user.id % bgColors.length],
            fill: !isTimeData,
          };
        });
      } else {
        datasets = this.state.rawData.map((user, index) => {
          return {
            label: `${index + 1} - ${user.first_name} ${user.last_name}`,
            data: (() => {
              let result = [];
              if (isTimeData)
                for (let i = 0; i < Object.values(user.data)[0].length; i++) {
                  let keys = Object.keys(user.data).filter((key) =>
                    Object.keys(
                      this.categoryGamesOptions[value].games
                    ).includes(key)
                  );
                  let values = keys.map((key) => user.data[key]);
                  result[i] = values.reduce((acc, cur) => {
                    return (acc = acc + cur[i]);
                  }, 0);
                }
              else result = [user.data[value]];
              return result;
            })(),
            backgroundColor: bgColors[user.id % bgColors.length],
            borderColor: bgColors[user.id % bgColors.length],
            fill: !isTimeData,
          };
        });
      }
      let labels = [];
      if (isTimeData) {
        labels = isMonthly ? months(this.props.t) : days();
      } else {
        labels =
          value === "total"
            ? Object.keys(this.state.rawData[0].data).map((item) =>
                this.props.t("chart_labels." + item)
              )
            : [this.props.t("chart_labels." + value)];
      }

      this.setState((prevState) => {
        return {
          [name]: value,
          chartData: {
            labels,
            datasets: datasets,
            borderWidth: 1,
          },
        };
      });
    } else if (name === "selectedGameOption") {
      // ------------------------------------------- GÖSTERİLECEK OYUN DEĞİŞTİYSE -----
      const isTimeData = Array.isArray(
        Object.values(this.state.rawData[0].data)[0]
      );
      let isMonthly = ["monthly_game_counts", "monthly_game_scores"].includes(
        this.state.selectedChartOption
      );
      let datasets = [];
      if (value === "total") {
        datasets = this.state.rawData.map((user, index) => {
          return {
            label: `${index + 1} - ${user.first_name} ${user.last_name}`,
            data: (() => {
              let result = [];
              if (isTimeData)
                for (let i = 0; i < Object.values(user.data)[0].length; i++) {
                  let keys = Object.keys(user.data).filter((key) =>
                    Object.keys(
                      this.categoryGamesOptions[
                        this.state.selectedCategoryOption
                      ].games
                    ).includes(key)
                  );
                  let values = keys.map((key) => user.data[key]);
                  result[i] = values.reduce((acc, cur) => {
                    return (acc = acc + cur[i]);
                  }, 0);
                }
              else result = Object.values(user.data);
              return result;
            })(),
            backgroundColor: bgColors[user.id % bgColors.length],
            borderColor: bgColors[user.id % bgColors.length],
            fill: !isTimeData,
          };
        });
      } else {
        datasets = this.state.rawData.map((user, index) => {
          return {
            label: `${index + 1} - ${user.first_name} ${user.last_name} ${
              user.id
            }`,
            data: isTimeData ? user.data[value] : [user.data[value]],
            backgroundColor: bgColors[user.id % bgColors.length],
            borderColor: bgColors[user.id % bgColors.length],
            fill: !isTimeData,
          };
        });
      }
      let labels = [];
      if (isTimeData) {
        labels = isMonthly ? months(this.props.t) : days();
      } else {
        labels =
          value === "total"
            ? Object.keys(this.state.rawData[0].data).map((item) =>
                this.props.t("chart_labels." + item)
              )
            : [this.props.t("chart_labels." + value)];
      }
      this.setState((prevState) => {
        return {
          [name]: value,
          chartData: {
            labels,
            datasets: datasets,
            borderWidth: 1,
          },
        };
      });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleDelete = (index) => {
    this.setState((prevState) => {
      return {
        selectedUsers: prevState.selectedUsers.filter(
          (_user, i) => index !== i
        ),
        chartData: {
          ...prevState.chartData,
          datasets: prevState.chartData.datasets.filter(
            (dataset, i) => index !== i
          ),
        },
      };
    });
  };

  getUserInfo = () => {
    let { selectedUsers, selectedChartOption } = this.state;
    let { t } = this.props;
    if (selectedUsers.length < 1) return;
    let isMonthly = ["monthly_game_counts", "monthly_game_scores"].includes(
      selectedChartOption
    );
    let isDaily = ["daily_game_counts", "daily_game_scores"].includes(
      selectedChartOption
    );
    this.setState({
      selectedCategoryOption: "total",
      selectedGameOption: "total",
    });
    selectedUsers &&
      this.props
        .getUserComparisonInfo(
          selectedUsers.map((user) => user.id),
          selectedChartOption
        )
        .then((res) => {
          if (!res || !res.length > 0) return;

          if (isMonthly || isDaily) {
            this.setState({
              rawData: res,
              chartData: {
                labels: isMonthly ? months(t) : days(),
                datasets: res.map((user, index) => {
                  return {
                    label: `${index + 1} - ${user.first_name} ${
                      user.last_name
                    }`,
                    data: (() => {
                      let result = [];
                      for (
                        let i = 0;
                        i < Object.values(user.data)[0].length;
                        i++
                      ) {
                        result[i] = Object.values(user.data).reduce(
                          (acc, cur) => {
                            return (acc = acc + cur[i]);
                          },
                          0
                        );
                      }
                      return result;
                    })(),
                    backgroundColor: bgColors[user.id % bgColors.length],
                    borderColor: bgColors[user.id % bgColors.length],
                    fill: !(isMonthly || isDaily),
                  };
                }),
                borderWidth: 1,
              },
            });
          } else { // --------------------------------- GÖSTERİLECEK CHART, ZAMANDAN BAĞIMSIZ DATA İÇERİYORSA
            this.setState({
              rawData: res,
              chartData: {
                labels: Object.keys(res[0].data).map((data) =>
                  this.props.t("chart_labels." + data)
                ),
                datasets: res.map((user, index) => {
                  return {
                    label: `${index + 1} - ${user.first_name} ${
                      user.last_name
                    }`,
                    data: Object.values(user.data),
                    backgroundColor: bgColors[user.id % bgColors.length],
                    backgroundColor: bgColors[user.id % bgColors.length],
                    borderColor: bgColors[user.id % bgColors.length],
                    fill: !(isMonthly || isDaily),
                  };
                }),
                borderWidth: 1,
              },
            });
          }
        });
  };
  selectMultipleUsers = (users) => {
    this.setState((prevState) => {
      return {
        selectedUsers: this.isSelectedAll(users)
          ? prevState.selectedUsers.filter(
              (user) => !users.some((_user) => user.id === _user.id)
            )
          : [
              ...prevState.selectedUsers,
              ...users.filter(
                (user) =>
                  !prevState.selectedUsers.some((_user) => user.id === _user.id)
              ),
            ],
      };
    });
  };
  isSelectedAll = (users) => {
    return users.every((user) =>
      this.state.selectedUsers.some((_user) => user.id === _user.id)
    );
  };

  fetchDivisionUsers = (division, index) => {
    this.props.getDivisionUsers({ division_id: division.id }).then(res => {
      const oldCount = (this.state.divisions[index].users).length;
      const newCount = res.length;
      if (oldCount !== newCount) {
        let newDivs = this.state.divisions;
        newDivs[index].users = res;
        this.setState({ divisions: newDivs });
      }

    });
  }

  onDivisionClick = (division, index) => {
    this.setState({ selectedDivisionIndex: index });

    // bu division'ın user listesi henüz çekilmediyse çekelim:
    const divisionData = this.state.divisions[index];
    const userCount = divisionData.users.length;
    const countThatCameFromServer = divisionData.user_count;

    if (userCount !== countThatCameFromServer)
      this.fetchDivisionUsers(division, index);
  }

  render() {
    if (this.props.corporationDetailsLoading)
      return (
        <Card className="shadow p-4 d-flex justify-content-center col-8">
          <CircularProgress />
        </Card>
      );
    let { t, lng } = this.props;
    let { chartOptions, categoryGamesOptions } = this;
    let {
      userFilterText,
      divisionFilterText,
      selectedDivisionIndex,
      selectedUsers,
      selectedChartOption,
      chartData,
      selectedCategoryOption,
      selectedGameOption,
      divisions,
    } = this.state;
    const divisionsFiltered = (divisions || []).filter((div) =>
      div.name
        .toLocaleLowerCase(lng)
        .includes(divisionFilterText.toLocaleLowerCase(lng))
    );

    let users = [];
    //TODO: Check if divs and users empty

    const { self, family, corps } = this.props;

    if (self) {
      users = [{ ...this.props.authUser }];
    } else if (family) {
      users = (this.props.authUser.family || []).map(
        (familyMember) => familyMember.user
      );
    } else if (corps) {
      users =
        divisions && divisions[selectedDivisionIndex]
          ? divisions[selectedDivisionIndex].users
          : [];
    }

    users = users.filter((user) =>
      (user.first_name + user.last_name)
        .toLocaleLowerCase(lng)
        .includes(userFilterText.toLocaleLowerCase(lng))
    );

    let isMonthly = ["monthly_game_counts", "monthly_game_scores"].includes(
      selectedChartOption
    );
    let isDaily = ["daily_game_counts", "daily_game_scores"].includes(
      selectedChartOption
    );
    let isGameSelectable = ["level_distribution", "game_scores"].includes(
      selectedChartOption
    );
    let isCategorySelectable = ["category_scores"].includes(
      selectedChartOption
    );
    const Chart = isDaily || isMonthly ? Line : Bar;
    const SelectAllIcon = this.isSelectedAll(users) ? Ballot : BallotOutlined;

    const corporationChooser = this.props.corps && (
      <div className="col-4">
        <Card className="shadow p-3 DivisionSelectContainer mb-4">
          <div className="d-flex justify-content-between">
            <Typography variant="h6">{t("divisions")}</Typography>
            <CustomInput
              lefticon={<FindInPage style={{ color: "#7A7A7A" }} />}
              placeholder={t("searchDivision")}
              value={divisionFilterText}
              onChange={(e) =>
                this.setState({ divisionFilterText: e.target.value })
              }
              containerstyle={{
                maxWidth: "50%",
                backgroundColor: "#F0F0F0",
                borderWidth: 0,
              }}
            />
          </div>
          <div>
            {divisionsFiltered.map((division, index) => (
              <div
                key={division.id}

                className={
                  "DivisionSelect" +
                  (this.state.selectedDivisionIndex === index ? " Active" : "")
                }
                onClick={() => {
                  this.onDivisionClick(division, index);
                  // this.setState({ selectedDivisionIndex: index });
                }}
              >
                <Typography
                  style={{
                    color:
                      this.state.selectedDivisionIndex === index
                        ? "white"
                        : "#7a7a7a",
                  }}
                  variant="subtitle2"
                >
                  {division.name}
                </Typography>
                <div>
                  <Typography
                    style={{
                      color:
                        this.state.selectedDivisionIndex === index
                          ? "white"
                          : "#7a7a7a",
                    }}
                    variant="subtitle2"
                  >
                    {division.user_count}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </Card>
      </div>
    );

    const divisionAndUserChooser = !this.props.self && (
      <div className="col-4">
         
        <Card className="shadow p-3 DivisionSelectContainer mb-4">
          <div className="d-flex justify-content-between">
            <Typography variant="h6">{t("users")}</Typography>

            <CustomInput
              lefticon={<FindInPage style={{ color: "#7A7A7A" }} />}
              placeholder={t("searchUser")}
              value={userFilterText}
              onChange={(e) =>
                this.setState({ userFilterText: e.target.value })
              }
              containerstyle={{
                maxWidth: "50%",
                backgroundColor: "#F0F0F0",
                borderWidth: 0,
              }}
            />
            <SelectAllIcon
              style={{ cursor: "pointer" }}
              onClick={() => this.selectMultipleUsers(users)}
            />
          </div>

          <div>
          {this.props.isFetchingDivisionUsers && <div style={{textAlign:"center"}}><CircularProgress/></div> }
            {users.map((user, index) => (
              <div
                key={user.id}
                className={
                  "DivisionSelect" +
                  (selectedUsers.some((_user) => user.id === _user.id)
                    ? " Active"
                    : "")
                }
                onClick={() =>
                  this.setState((prevState) => {
                    return {
                      selectedUsers: prevState.selectedUsers.includes(user)
                        ? prevState.selectedUsers.filter(
                            (_user) => _user !== user
                          )
                        : [...prevState.selectedUsers, user],
                    };
                  })
                }
              >
                <Typography
                  style={{
                    color: selectedUsers.some((_user) => user.id === _user.id)
                      ? "white"
                      : "#7a7a7a",
                  }}
                  variant="subtitle2"
                >{`${user.first_name} ${user.last_name}`}</Typography>
              </div>
            ))}
          </div>
        </Card>
      </div>
    );

    const loadingIndicator = this.props.loading && (
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(255,255,255,.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );

    const statsFiltrationRow = (
      <div className="d-flex mt-2">
        <button
          className="btn btn-primary btn-sm mx-1 ButtonGroupItem"
          style={{ height: "2.7em" }}
          onClick={this.getUserInfo}
        >
          {t("refresh")}
        </button>
        <div className="input-group mb-2 mr-2" style={{ width: "40%" }}>
          <div className="input-group-prepend">
            <span className="input-group-text border-right-0 bg-white">
              <PieChart />
            </span>
          </div>
          <select
            className="form-control custom-select border-left-0 bg-white"
            style={{ textAlignLast: "center" }}
            name="selectedChartOption"
            onChange={this.changeHandler}
            value={selectedChartOption}
          >
            {Object.keys(chartOptions).map((option) => (
              <option key={option} value={option}>
                {chartOptions[option]}
              </option>
            ))}
          </select>
        </div>
        {this.state.rawData[0] &&
          (isCategorySelectable || isMonthly || isDaily) && (
            <div className="input-group mb-2 mr-2" style={{ width: "25%" }}>
              <div className="input-group-prepend">
                <span className="input-group-text border-right-0 bg-white">
                  <ViewList />
                </span>
              </div>
              <select
                className="form-control custom-select border-left-0 bg-white"
                style={{ textAlignLast: "center" }}
                name="selectedCategoryOption"
                onChange={this.changeHandler}
                value={selectedCategoryOption}
              >
                <option key={"total"} value="total">
                  {t(isMonthly || isDaily ? "total" : "all").toLocaleUpperCase(
                    this.props.lng
                  )}
                </option>
                {Object.keys(categoryGamesOptions).map((option) => (
                  <option key={option} value={option}>
                    {categoryGamesOptions[option].text}
                  </option>
                ))}
              </select>
            </div>
          )}
        {this.state.rawData[0] &&
          (((isMonthly || isDaily) && selectedCategoryOption !== "total") ||
            isGameSelectable) && (
            <div className="input-group mb-2" style={{ width: "25%" }}>
              <div className="input-group-prepend">
                <span className="input-group-text border-right-0 bg-white">
                  <Category />
                </span>
              </div>
              <select
                className="form-control custom-select border-left-0 bg-white"
                style={{ textAlignLast: "center" }}
                name="selectedGameOption"
                onChange={this.changeHandler}
                value={selectedGameOption}
              >
                <option key={"total"} value="total">
                  {t(isMonthly || isDaily ? "total" : "all").toLocaleUpperCase(
                    this.props.lng
                  )}
                </option>
                {Object.keys(
                  isGameSelectable
                    ? this.allGames
                    : categoryGamesOptions[selectedCategoryOption].games
                ).map((option) => (
                  <option key={option} value={option}>
                    {isGameSelectable
                      ? this.allGames[option]
                      : categoryGamesOptions[selectedCategoryOption].games[
                          option
                        ]}
                  </option>
                ))}
              </select>
            </div>
          )}
        {!this.props.self &&
          this.state.selectedUsers[0] && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flexGrow: 1,
              }}
            >
              <DeleteSweepOutlined
                style={{ cursor: "pointer" }}
                color="error"
                fontSize="large"
                onClick={() => this.setState({ selectedUsers: [] })}
              />
            </div>
          )}
      </div>
    );

    const selectedUsersRow = !this.props.self && (
      <div className="d-flex justify-concent-center flex-wrap my-2">
        {selectedUsers.map((user, index) => (
          <Chip
            className="mr-2 mb-2"
            key={user.id}
            style={{
              color: "white",
              background: bgColors[user.id % bgColors.length],
            }}
            label={`${user.first_name} ${user.last_name}`}
            onDelete={() => this.handleDelete(index)}
            onClick={() => this.handleDelete(index)}
          />
        ))}
      </div>
    );

    return (
      <>
        {corporationChooser}
        {divisionAndUserChooser}

        <div className="CompareUsers col-12">
          <Card className="shadow p-3 position-relative">
            {loadingIndicator}
            <Typography variant="h6">{t("statistics.text")}</Typography>
            {statsFiltrationRow}
            {selectedUsersRow}
            <Chart data={chartData} options={chartConfig} />
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    divsAndUsers: state.corporations.divsAndUsers,
    loading: state.loading["GET_USER_COMPARISON_INFO"],
    corporationDetailsLoading: state.loading["CORPORATION_DETAILS"],
    authUser: state.auth.user,
    isFetchingDivisionUsers: state.corporations.isFetchingDivisionUsers

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserComparisonInfo: (user_ids, type) =>
      dispatch(getUserComparisonInfo(user_ids, type)),
    getCorporationDetails: (corporation_id) =>
      dispatch(getCorporationDetails(corporation_id)),
    getDivisionUsers: (params) => dispatch(getDivisionUsers(params)),

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("common")(CompareUsers));
