import {SIGN_IN_SUCCESS, SIGN_OUT ,FB_SIGN_IN_SUCCESS, EDIT_USER_SUCCESS} from '../actions/actionTypes';

const initialState = {
    user:null,
    token:null
}

const authReducer = (state=initialState,action) =>{
    switch(action.type){
        case SIGN_IN_SUCCESS:
        const { user, token } = action;
        return {
            ...state,
            user,
            token
        }
        case SIGN_OUT:
        return {
            ...state,
            user:null,
            token:null
        }
        case FB_SIGN_IN_SUCCESS:
        return {
            ...state,
            ...action,
        }
        case EDIT_USER_SUCCESS:
        return {
            ...state,
            user: action.user.id === state.user.id ? action.user : state.user
        }
        default:
        return state;
    }
}

export default authReducer;
