import React, { Component } from 'react';
import { Card } from '@material-ui/core';

class JoinFamily extends Component {
  
  componentDidMount(){
    setTimeout(()=>this.props.history.push("/"),5000)
  }
  render() {
    const result = new URLSearchParams(this.props.location.search).get("result") === "success";    

    return (
      <Card className="m-4 p-4">
        <div className={`alert alert-${result? "success":"danger"}`} role="alert">
          <b>{`${result? "Başarılı":"Hata"} Ana sayfaya yönlendiriliyorsunuz...`} </b>
        </div>
      </Card>
    )
  }
}

export default  JoinFamily;