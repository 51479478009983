import React from 'react';
import { Radar } from 'react-chartjs-2';
import { withNamespaces } from 'react-i18next';



const options = {
  defaultFontFamily: "Open Sans",
  startAngle: 90,
  // elements: {
  //     line: {
  //         borderJoinStyle: "round"
  //     }
  // },            
  legend: {
    display: true,
    position:"bottom"
  },
  scale: {
    pointLabels: {
      fontSize: 15,
      // lineHeight:2,
      //fontColor:data.map(item=> item.color),
    },
    angleLines: {
      display: true,
    },
    gridLines: {},
    ticks: {
      display: false,
      min: 0
    }
  },
  tooltips: {
    enabled: false
  }
};
const RadarChart = ({ data, t }) => {
  const chartData = {
    labels: Object.keys(data.filter).map(item => t(`category_games.${item}.text`)),
    datasets: [
      {
        data: Object.values(data.this_user).map(item => +item.toFixed(2)),
        backgroundColor: "rgba(255, 0, 0, 0.4)",
        label: "Siz",
        pointRadius: 0,
        borderColor: "red",
        borderWidth: 1
      }, {
        data: Object.values(data.filter).map(item => +item.toFixed(2)),
        backgroundColor: "rgba(0, 255, 255, 0.4)",
        label: "Ortalama",
        pointRadius: 0,
        borderColor: "rgb(0,255,255)",
        borderWidth: 1
      },
    ]
  }

  return (<Radar data={chartData} options={options} aspectRatio={1} />);
}

export default withNamespaces("common")(RadarChart);