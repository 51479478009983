import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createMuiTheme, MuiThemeProvider, CssBaseline } from '@material-ui/core'
import configureStore from './store/configureStore'
import './index.css';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18next from './i18next';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'typeface-roboto-multilang/latin-ext.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { setInterceptors } from './axios-instance';
import { autoSignIn } from './store/actions';
import JoinFamily from './containers/JoinFamily/JoinFamily';

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
        h6: {
            color: "#38495C"
        }
    },
});

const store = configureStore();

store.dispatch(autoSignIn());

setInterceptors(store);

const app = (
    <I18nextProvider i18n={i18next}>
        <BrowserRouter>
            <Provider store={store}>
                <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Switch>
                        <Route path="/join_family" exact component={JoinFamily} />
                        <Route path="/" component={App} />
                    </Switch>
                </MuiThemeProvider>
            </Provider>
        </BrowserRouter>
    </I18nextProvider>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
