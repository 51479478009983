import React from 'react';

export default () => (<ul className="ReportDescription" style={{
  fontSize:".9em",
  marginTop: "1em",
  listStyle:"none",
  paddingLeft:0,
  pageBreakAfter:"always"
}}>
  <li> <span style={{marginRight:"1em"}}>✓</span> Rapordaki veriler kullanicilarin ilk kayit gununden itibaren raporun cekildigi gune kadarki gelisimi ve guncel durumunu icerir.</li>
  <li> <span style={{marginRight:"1em"}}>✓</span> Raporda yer alan veriler kullanicinin GriCeviz Bilissel Beceri ve Zihinsel Gelisim Platformu'nda yer alan oyunlardaki performansina göre olusturulmustur.</li>
  <li> <span style={{marginRight:"1em"}}>✓</span> Cattel-Horn-Carroll Zeka Kurami'na göre gelistirilen GriCeviz Bilissel Beceri ve Zihinsel Gelisim Platformu'ndaki tum oyun ve kategoriler, bu kuram cercevesinde belirlenmis kazanimlarin elde edilmesi, kullanicinin hangi alanlarda ustunluk ya da eksikliginin oldugunun tespit edilmesine yönelik olarak tasarlanmistir.</li>
  <li> <span style={{marginRight:"1em"}}>✓</span> Kullanicinin kategori bazindaki gelisimi ve bu gelisim dogrultusunda elde ettigi kazanimlar, raporda paylasilmaktadir. CHC Kurami ve kazanimlarla ilgili detayli bilgi icin www.griceviz.com 'u ziyaret edebilirsiniz.</li>
  <li> <span style={{marginRight:"1em"}}>✓</span> Rapordan elde edilen veriler PDR uzmanlari esliginde  degerlendirilmesi tavsiye edilir.</li>
  <li> <span style={{marginRight:"1em"}}>✓</span> GriCeviz Bilissel Beceri ve Zihinsel Gelisim Platformu'ndan elde edilen rapor ile GriCeviz MeslekIlgi Modulu raporu, kullanici eger ögrenci ise okulundaki bir PDR Uzmani tarafindan her iki raporu birlikte degerlendirilmesi tavsiye edilir. Bu veriler birbirleriyle iliskilendirilebilir ve 15-18 yas araligindaki kullanicilar icin mesleki yönelimin tespit edilmesi icin yardimci bir arac olarak kullanilabilir.</li>
  <li> <span style={{marginRight:"1em"}}>✓</span> GriCeviz MeslekIlgi Modulu hakkinda ayrintili bilgi icin <a href="http://www.griceviz.com">www.griceviz.com</a> 'u ziyaret edebilirsiniz.</li>
  <li> <span style={{marginRight:"1em"}}>✓</span> Birey ve Sinif/Bölum bazinda ayrintili rapor almak icin Yönetim Paneli'ni kullanabilirsiniz.</li>
</ul>)