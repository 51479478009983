import {
  USERS_SUCCESS,
  TRANSACTION_HISTORY_SUCCESS,
  EDIT_USER_SUCCESS,
  ADD_USER_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
    users:[],
    currentPage:0,
    totalPage:0,
    total:0
}

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case USERS_SUCCESS:
      return {
        ...state,
        ...action
      }
    case TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
      }
    case EDIT_USER_SUCCESS : {
      return {
        ...state,
        users:state.users.map(user=>{
          if(user.id === action.user.id) return action.user;
          return user;
        })
      }
    }
    case ADD_USER_SUCCESS : {
      return {
        ...state,
        users:[action.user,...state.users]
      }
    }
    default:
      return {
        ...state
      }
  }
}

export default usersReducer;
