import React from 'react';
import {Chip} from '@material-ui/core';

const selectedFiltersBar = props => (
    <div className = "d-inline">
        {Object.keys(props.filters).map(key=>{
            return props.filters[key].map(option=>(
            <Chip key={option.value} label={option.text} onDelete={()=>props.onFilterRemove({name:key,option})}/>
            ))
        })}
    </div>
)

export default selectedFiltersBar;
