import React from 'react';
import {Card} from '@material-ui/core';
import './style.css';

const gradientSummaryCard = props => (
    <Card className={"shadow gradient-summary-card-container p-2 mb-4"+(props.sm?" Small":"")} style={props.style}>
        <div className="left">
            <div className="title">{props.title}</div>
            <div className="value">{props.value}</div>
            {props.description&&<div className="description">{props.description}</div>}
        </div>
        <div className="right">
            <span className="Gradient-Icon">{props.icon} </span>
        </div>
        <svg className="VaweSvg" viewBox="0 0 100 30" width="100%" height="100%" preserveAspectRatio="none">
            <path d="M0 20 Q25 10 50 20 Q75 30 100 20 L100 30 L0 30 L0 20" fill={props.waveColor||"#0083b0"}/>
        </svg>
    </Card>
);

export default gradientSummaryCard;