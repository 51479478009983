import React from 'react';
import { Radar } from 'react-chartjs-2';



const options = {
  defaultFontFamily: "Open Sans",
  startAngle: 90,
  // elements: {
  //     line: {
  //         borderJoinStyle: "round"
  //     }
  // },            
  legend: {
    display: false
  },
  scale: {
    pointLabels: {
      fontSize: 15,
      // lineHeight:2,
      //fontColor:data.map(item=> item.color),
    },
    angleLines: {
      display: true,
    },
    gridLines: {},
    ticks: {
      display: false,
      min: 0
    }
  },
  tooltips: {
    enabled: false
  }
};
const RadarChart = ({ data,labels }) => {
  const chartData = {
    labels,
    datasets: [
      {
        data: data,
        backgroundColor: "rgba(255, 0, 0, 0.4)",
        label: "You",
        pointRadius: 0,
        borderColor: "red",
        borderWidth: 1
      }, 
    ]
  }

  return (<div style={{marginTop:"2em",marginBottom:"2em",display:"flex",justifyContent:"center"}}>
    <div style={{width:350}}>
      <Radar data={chartData} options={options} aspectRatio={1} />
    </div>
    </div>);
}

export default RadarChart;