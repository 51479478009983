import React from 'react';
import { Line } from 'react-chartjs-2';
import { withNamespaces } from 'react-i18next';
import { Grid } from '@material-ui/core';

const options ={
  legend:{
    display:false
  }
}


const ScoreByWeekChart = ({  labels,data, category, t }) => {

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Ağırlıklı Puan Ortalaması',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data
      }
    ]
  };

  const skills = t(`category_games.${category}.skills`,{returnObjects:true});
  return (
    <div>
      <Grid container>
        <Grid xs={3} item>
          <h4><img src={`/images/categories/${category}.png`} width={40} style={{marginRight:"1em"}} alt=""/>{t(`category_games.${category}.text`)}</h4>
          <ul>{
            skills.map((skill,index) => <li key={index}>
              {skill.text}
              <ul>{skill.subSkills.map((subSkill,index) => <li key={index} style={{fontSize:10}}>{subSkill}</li>)}</ul>
            </li>)
          }</ul>
        </Grid>
        <Grid xs={8} item>
          <Line data={chartData} options={options}/>
        </Grid>
      </Grid>
    </div>
  )
}



const ScoreByDate = ({ data, t }) => {
  const categoryGames = t('category_games', { returnObjects: true });
  const categories = Object.keys(categoryGames);
  const days = Object.keys(data).reverse();
  const chartData = categories.map(category => {
    const games = Object.keys(categoryGames[category].games);
    console.log("games", games);
    return {
      categoryName: category,
      data: days.map(day => +(games.reduce((acc, game) => acc + data[day][game], 0) / games.length).toFixed(2))
    }
  })
  return <div style={{pageBreakInside:"always", pageBreakAfter:"always"}}>
    {chartData.map((c,i) =><div> 
      <div style={{pageBreakBefore:i==3?"always":"avoid", paddingTop: i==3?"80px":"20px"}}></div> 
      <ScoreByWeekChart key={i} labels={days} data={c.data} category={c.categoryName} t={t}/>
    </div>)}
  </div>
}

export default withNamespaces('common')(ScoreByDate);