const api = {
    // USER APIs
    sign_in:"/user/sign_in",
    update_user:"user/edit",
    get_user:"user/user",
    transaction_history: "user/transaction_history",
    invite_to_family:"user/invite_to_family",
    remove_from_family:"user/remove_from_family",
    change_password : "user/change_password",
    user_game_summary: "user/game_summary",
    admin_notes: "user/get_admin_notes",
    update_admin_note: "user/update_admin_note",
    delete_admin_note: "user/delete_admin_note",
    add_admin_note: "user/add_admin_note",
    get_user_table: "user/filtered_user_table.xlsx",
    add_user: "user/create_user",
    fb_sign_in: "/user/facebook_signin",
    forgot_password: "user/forget_password",
    reset_password: "/user/reset_password",
    edit_user: "user/edit",
    filtered_user_list:"/user/filtered_user_list",
    set_play_limits: 'user/set_play_limit_for_users',
    restrict_games: 'game/add_restricted_games_to_users',

    // CORPORATION APIs
    list_corporations: "corporation/list_corporations",
    add_corporation: "corporation/create_corporation",
    list_corporations_for_filter:"/corporation/list_corporations_for_filter",
    get_corporation_details:"corporation/get_corporation_details",
    delete_corporation_admin:"corporation/del_corp_admin",
    create_corporation_admin:"corporation/create_corp_admin",
    del_corp_division_admin:"corporation/del_corp_division_admin",
    create_corp_division_admin:"corporation/create_corp_division_admin",
    del_corp_division_user:"corporation/del_corp_division_user",
    create_corp_division_user:"corporation/create_corp_division_user",
    get_div_names_and_counts: 'corporation/get_info_and_div_names_with_user_counts',
    edit_corporation_info: 'corporation/edit_corporation_info',
    add_division: 'corporation/add_division',
    edit_division: 'corporation/edit_division',
    delete_division: 'corporation/delete_division',
    get_divs_and_users: 'corporation/get_divs_and_users',
    create_corp_privilage_check:'corporation/create_corp_privilage_check',
    get_division_users:'corporation/get_division_users',

    // STATISTICS APIs
    get_user_counts: "statistics/get_user_counts",
    get_new_user_counts: "statistics/get_new_user_counts",
    admin_home_page_cards:"statistics/admin_home_page_cards",
    get_profile_card_game_summary: 'statistics/get_profile_card_game_summary',
    filtered_game_play_counts:"/statistics/filtered_game_play_counts",
    get_user_comparison_info: 'statistics/get_user_comparison_info',
    all_category_statistics: 'statistics/all_category_statistics',
    report_data: 'statistics/report_data',

    //purchase
    purchase: 'receipt/checkout',
    validate_coupon: 'receipt/validate_coupon',
    filter_coupons: 'receipt/filter_coupons',
    deactivate_coupon: 'receipt/deactivate_coupon',
    create_coupon: 'receipt/generate_coupon',

    //inventory_coupon
    // validate_inventory_coupon: 'receipt/validate_inventory_coupon',
    filter_inventory_coupons: 'receipt/filter_inventory_coupons',
    deactivate_inventory_coupon: 'receipt/deactivate_inventory_coupon',
    create_inventory_coupon: 'receipt/generate_inventory_coupon'

}

export default api;
