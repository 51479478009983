import attention from './attention.png';
import logic from './logic.png';
import memory from './memory.png';
import numeric from './numeric.png';
import visual from './visual.png';
import words from './words.png';

export default {
  attention,
  logic,
  memory,
  numeric,
  visual,
  words
};
