import {SHOW_ERROR, HIDE_ERROR} from './actionTypes';


export const showError = message => {
    return {
        type:SHOW_ERROR,
        message
    }
}

export const hideError = () => {
    return {
        type:HIDE_ERROR
    }
}
