import React, { Component } from "react";
import { connect } from "react-redux";
import { withI18n } from "react-i18next";
import {
  getDivNamesAndCounts,
  editCorporationInfo,
  deleteDivision,
  editDivision,
  addDivision
} from "../../../../store/actions/";
import MembershipTypeChip from "../../../../components/MembershipTypeChip";
import moment from "moment";
import Email from "@material-ui/icons/Email";
import LocalPhone from "@material-ui/icons/LocalPhone";
import Place from "@material-ui/icons/Place";
import Delete from "@material-ui/icons/Delete";
import DateRange from "@material-ui/icons/DateRange";
import EditIcon from "@material-ui/icons/Edit";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import CardMembership from "@material-ui/icons/CardMembership";
import Done from "@material-ui/icons/Done";
import { SingleDatePicker, isInclusivelyBeforeDay } from "react-dates";
import "./style.css";
import {
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Collapse
} from "@material-ui/core";

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      corp: {},
      formData: {
        corp_id: "",
        image: {
          value: "",
          data: ""
        },
        corpName: "",
        email: "",
        phone1: "",
        phone2: "",
        address: "",
        end_at: moment(new Date()),
        c_type: ""
      },
      editMode: false,
      endAtFocus: false,
      addUserToggle: false,
      addDivName: ""
    };
  }

  componentDidMount() {
    let corporation_id = this.props.match.params.id;
    this.props.getDivNamesAndCounts(corporation_id).then(corp => {
      if (corp) {
        this.setState({
          corp: {
            ...corp,
            divisions: corp.divisions.map(div => {
              return { ...div, editMode: false, editValue: div.name };
            })
          }
        });
        this.updateForm(corp);
      }
    });
  }

  dateFocusChange = ({ focused }, field) => this.setState({ [field]: focused });

  dateChange = (date, field) => {
    this.setState(prevState => {
      return {
        formData: {
          ...prevState.formData,
          [field]: date
        }
      };
    });
  };

  updateForm(corp) {
    this.setState({
      formData: {
        corp_id: corp.id,
        image: {
          value: corp.image || "",
          data: corp.image.data || ""
        },
        corpName: corp.name || "",
        email: corp.email || "",
        phone1: corp.phone1 || "",
        phone2: corp.phone2 || "",
        address: corp.address || "",
        end_at: moment(new Date(corp.end_at)) || "",
        c_type: corp.c_type || ""
      }
    });
  }

  onSaveCorp = () => {
    let corporation = {
      ...this.state.formData,
      image: this.state.formData.image.data
    };
    this.props.editCorporationInfo(corporation).then(res => {
      if(res){
        this.setState(prevState => {
          return { editMode: !prevState.editMode };
        });
        this.updateForm(res);
      }
      
    });
  };

  changeHandler = e => {
    const { name, value } = e.target;
    this.setState(prevState => {
      return {
        formData: {
          ...prevState.formData,
          [name]: value
        }
      };
    });
  };

  changeImage = e => {
    let { files } = e.target;
    const reader = new FileReader();

    reader.onload = e => {
      this.setState(prevState => {
        return {
          formData: {
            ...prevState.formData,
            image: {
              ...prevState.formData.image,
              data: e.target.result
            }
          }
        };
      });
    };
    reader.readAsDataURL(files[0]);
  };

  editDivs = (e, i) => {
    let { value } = e.target;
    this.setState(prevState => {
      return {
        corp: {
          ...prevState.corp,
          divisions: prevState.corp.divisions.map((div, index) => {
            if (index === i) return { ...div, editValue: value };
            return div;
          })
        }
      };
    });
  };

  editDivision = ind => {
    this.props
      .editDivision(this.state.corp.id, {
        name: this.state.corp.divisions[ind].editValue,
        div_id: this.state.corp.divisions[ind].id
      })
      .then(res => res && this.toggleDivEditMode(ind));
  };

  toggleDivEditMode = ind => {
    this.setState(prevState => {
      return {
        corp: {
          ...prevState.corp,
          divisions: prevState.corp.divisions.map((div, i) => {
            if (i === ind) return { ...div, editMode: !div.editMode };
            return div;
          })
        }
      };
    });
  };

  addDivNameHandler = e => {
    let { value } = e.target;
    this.setState({ addDivName: value });
  };

  render() {
    const { t } = this.props;
    const { corp, editMode, formData, endAtFocus } = this.state;
    if (!corp.id) {
      return (
        <div>
          {this.props.getDivNamesAndCountsLoading
            ? "Loading.."
            : "You are not authorized."}
        </div>
      );
    }
    const cTypes = t("cTypes", { returnObjects: true });

    return (
      <div className="row CorporationEdit">
        <div className="col-4">
          <div className="card d-block shadow p-4">
            <div
              className="text-center font-weight-bold"
              style={{ color: "#2E4D62", fontSize: "1.3em" }}
            >
              {t("corporation_profile")}
            </div>
            <label
              htmlFor="file-input"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img
                src={
                  formData.image.data
                    ? `${formData.image.data}`
                    : formData.image.value
                }
                alt={formData.image.value}
                style={{
                  width: 150,
                  height: 150,
                  objectFit: "contain",
                  backgroundColor: "white"
                }}
              />
            </label>
            {editMode && (
              <input
                type="file"
                id="file-input"
                style={{
                  width: 150,
                  height: 150,
                  display: "none",
                  objectFit: "contain",
                  backgroundColor: "white"
                }}
                name="image"
                onChange={this.changeImage}
                accept="image/x-png,image/gif,image/jpeg"
              />
            )}
            <div
              className="text-center font-weight-bold mb-2"
              style={{ color: "#2E4D62", fontSize: "1.3em" }}
            >
              {!editMode ? formData.corpName :
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <span
                    className={`input-group-text CorporationEditInputIconSpan ${
                      editMode ? "bg-white" : "bg-light"
                    }`}
                  >
                  </span>
                </div>
                <input
                  className={`form-control CorporationEditInput  ${
                    editMode ? "bg-white" : "bg-light"
                  }`}
                  name="corpName"
                  value={formData.corpName}
                  disabled={!editMode}
                  onChange={this.changeHandler}
                  type="corpName"
                  placeholder={corp.name}
                />
                <br />
              </div>}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "1em"
              }}
            >
              <MembershipTypeChip
                style={{ marginRight: "1em" }}
                label={t(`membershipType.${corp.c_type}`)}
                value={corp.c_type}
              />
              <MembershipTypeChip
                style={{
                  backgroundColor: "#FDD567",
                  color: "#31332A",
                  marginLeft: "1em"
                }}
                label={`${corp.user_count}/${corp.size}`}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "2.5em",
                fontSize: "0.9em"
              }}
            >
              <div
                className="py-1 mr-2"
                style={{
                  background: "#F0F0F0",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#7D7D7D"
                }}
              >
                <div className="ml-2">
                  {moment(new Date(corp.start_at)).format("DD.MM.YYYY")}
                </div>
                <div className="mx-2">|</div>
                <div className="mr-2 ">
                  {moment(new Date(formData.end_at)).format("DD.MM.YYYY")}
                </div>
              </div>
              <div
                className="px-2 py-1 font-weight-bold ml-2"
                style={{
                  background: "#F0F0F0",
                  borderRadius: "10px",
                  color: "#7D7D7D"
                }}
              >
                {moment(new Date(corp.end_at)).diff(moment(), "days") < 0
                  ? "Üyelik Sonu"
                  : moment(new Date(corp.end_at)).diff(moment(), "days") +
                    " " +
                    t("days")}
              </div>
            </div>

            <div>
              {this.props.authUser.role === 1 && (
                <div>
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <span
                        className={`input-group-text border-right-0 ${
                          editMode ? "bg-white" : "bg-light"
                        }`}
                      >
                        <CardMembership />
                      </span>
                    </div>
                    <select
                      className={`form-control custom-select border-left-0 ${
                        editMode ? "bg-white" : "bg-light"
                      }`}
                      style={{ textAlignLast: "center" }}
                      name="c_type"
                      onChange={this.changeHandler}
                      value={formData.c_type}
                      disabled={!editMode}
                    >
                      {Object.keys(cTypes).map(type => (
                        <option key={type} value={type}>
                          {cTypes[type]}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <span
                        className={`input-group-text CorporationEditInputIconSpan ${
                          editMode ? "bg-white" : "bg-light"
                        }`}
                      >
                        <DateRange />
                      </span>
                    </div>
                    <div
                      style={{
                        width: "calc(100% - 50px)",
                        fontFamily: "sans-serif"
                      }}
                    >
                      <SingleDatePicker
                        focused={endAtFocus}
                        onFocusChange={value =>
                          this.dateFocusChange(value, "endAtFocus")
                        }
                        numberOfMonths={1}
                        disabled={!editMode}
                        date={moment(new Date(formData.end_at))}
                        block
                        small
                        regular
                        displayFormat="DD/MM/YYYY"
                        id="end_at"
                        hideKeyboardShortcutsPanel
                        placeholder={t("choose_date")}
                        isOutsideRange={day =>
                          isInclusivelyBeforeDay(day, moment())
                        }
                        onDateChange={date => this.dateChange(date, "end_at")}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <span
                    className={`input-group-text CorporationEditInputIconSpan ${
                      editMode ? "bg-white" : "bg-light"
                    }`}
                  >
                    <Email />
                  </span>
                </div>
                <input
                  className={`form-control CorporationEditInput  ${
                    editMode ? "bg-white" : "bg-light"
                  }`}
                  name="email"
                  value={formData.email}
                  disabled={!editMode}
                  onChange={this.changeHandler}
                  type="email"
                  placeholder={t("email")}
                />
                <br />
              </div>
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <span
                    className={`input-group-text CorporationEditInputIconSpan ${
                      editMode ? "bg-white" : "bg-light"
                    }`}
                  >
                    <LocalPhone />
                  </span>
                </div>
                <input
                  className={`form-control CorporationEditInput  ${
                    editMode ? "bg-white" : "bg-light"
                  }`}
                  name="phone1"
                  value={formData.phone1}
                  disabled={!editMode}
                  onChange={this.changeHandler}
                  placeholder={t("phone") + " 1"}
                />
                <br />
              </div>
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <span
                    className={`input-group-text CorporationEditInputIconSpan ${
                      editMode ? "bg-white" : "bg-light"
                    }`}
                  >
                    <LocalPhone />
                  </span>
                </div>
                <input
                  className={`form-control CorporationEditInput  ${
                    editMode ? "bg-white" : "bg-light"
                  }`}
                  name="phone2"
                  value={formData.phone2}
                  disabled={!editMode}
                  onChange={this.changeHandler}
                  placeholder={t("phone") + " 2"}
                />
                <br />
              </div>
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <span
                    className={`input-group-text CorporationEditInputIconSpan ${
                      editMode ? "bg-white" : "bg-light"
                    }`}
                  >
                    <Place />
                  </span>
                </div>
                <textarea
                  className={`form-control CorporationEditInput  ${
                    editMode ? "bg-white" : "bg-light"
                  }`}
                  style={{ textIndent: "0px" }}
                  name="address"
                  disabled={!editMode}
                  value={formData.address}
                  onChange={this.changeHandler}
                  placeholder={t("address")}
                ></textarea>
                <br />
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              {editMode ? (
                <div style={{ display: "flex" }}>
                  <button
                    className="btn btn-primary btn-sm mx-1 ButtonGroupItem"
                    onClick={() => {
                      this.setState(prevState => {
                        return { editMode: !prevState.editMode };
                      });
                      this.updateForm(corp);
                    }}
                  >
                    {t("cancel")}
                  </button>
                  <button
                    className="btn btn-primary btn-sm mx-1 ButtonGroupItem"
                    onClick={() => {
                      this.onSaveCorp();
                    }}
                  >
                    {t("save")}
                  </button>
                </div>
              ) : (
                <>
                <button
                  className="btn btn-primary btn-sm mx-1 ButtonGroupItem"
                  onClick={() =>
                    this.setState(prevState => {
                      return { editMode: !prevState.editMode };
                    })
                  }
                >
                  {t("edit")}
                </button>
                <button
                className="btn btn-primary btn-sm mx-1 ButtonGroupItem"
                onClick={() =>this.props.history.goBack()
                }
              >
                {t("return")}
              </button>
                </>
                
              )}
            </div>
          </div>
        </div>

        {/**************************** Division Edit Panel  ****************************/}

        <div className="col-8">
          <div className="card d-block shadow p-4">
            <div className="d-flex align-items-baseline">
              <div
                className="font-weight-bold mb-3"
                style={{ color: "#2E4D62", fontSize: "1.3em" }}
              >
                {t("divisions")}
              </div>
              <div className="ml-2 mb-3">
                <IconButton
                  onClick={() =>
                    this.setState(prevState => {
                      return { addUserToggle: !prevState.addUserToggle };
                    })
                  }
                >
                  <PlaylistAdd />
                </IconButton>
              </div>
            </div>
            <Collapse in={this.state.addUserToggle}>
              <div className="d-flex align-items-center mb-2">
                <div className="mr-2">
                  {" "}
                  {t("corporation_general.div_name")}:{" "}
                </div>
                <input
                  className="form-control"
                  style={{ width: "auto" }}
                  value={this.state.addDivName}
                  onChange={this.addDivNameHandler}
                />
                <button
                  className="btn btn-primary btn-sm mx-1 ButtonGroupItem"
                  style={{ height: "calc(2.25rem + 2px)" }}
                  onClick={() =>
                    this.props
                      .addDivision(corp.id, { name: this.state.addDivName })
                      .then(
                        res =>
                          res &&
                          this.setState(prevState => {
                            return {
                              corp: {
                                ...prevState.corp,
                                divisions: prevState.corp.divisions.concat([
                                  {
                                    name: res.name,
                                    id: res.id,
                                    user_count: 0,
                                    editValue: res.name
                                  }
                                ])
                              }
                            };
                          })
                      )
                  }
                >
                  {t("save")}
                </button>
              </div>
            </Collapse>
            <Table style={{ tableLayout: "auto" }}>
              <colgroup>
                <col style={{ width: "50%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "15%" }} />
              </colgroup>
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "#eee",
                    borderRadius: 20,
                    height: "initial"
                  }}
                >
                  <TableCell
                    style={{
                      borderBottomWidth: 0,
                      height: "initial",
                      textAlign: "left"
                    }}
                  >
                    {t("corporation_general.div_name")}
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottomWidth: 0,
                      height: "initial",
                      textAlign: "center"
                    }}
                  >
                    {t("user_count")}
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottomWidth: 0,
                      height: "initial",
                      textAlign: "center"
                    }}
                  >
                    {t("edit")}
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottomWidth: 0,
                      height: "initial",
                      textAlign: "center"
                    }}
                  >
                    {t("delete")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {corp.divisions.map((div, ind) => (
                  <TableRow key={div.id}>
                    <TableCell>
                      {" "}
                      <input
                        className="form-control DivNameInput pr-0"
                        value={div.editValue}
                        disabled={!div.editMode}
                        onChange={e => this.editDivs(e, ind)}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {div.user_count}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <IconButton
                        onClick={() => {
                          div.editMode
                            ? this.editDivision(ind)
                            : this.toggleDivEditMode(ind);
                        }}
                      >
                        {!div.editMode ? (
                          <EditIcon
                            style={{
                              cursor: "pointer",
                              color: "#fff",
                              padding: "0 3px",
                              background: "#00BC9E",
                              borderRadius: 3
                            }}
                          />
                        ) : (
                          <Done
                            style={{
                              cursor: "pointer",
                              color: "#fff",
                              padding: "0 3px",
                              background: "#00BC9E",
                              borderRadius: 3
                            }}
                          />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <IconButton>
                        <Delete
                          style={{ color: "#F6707B" }}
                          onClick={() =>
                            this.props.deleteDivision(corp.id, div.id).then(
                              res =>
                                res &&
                                this.setState(prevState => {
                                  return {
                                    corp: {
                                      ...prevState.corp,
                                      divisions: prevState.corp.divisions.filter(
                                        deletedDiv => deletedDiv.id !== div.id
                                      )
                                    }
                                  };
                                })
                            )
                          }
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    getDivNamesAndCountsLoading: state.loading["GET_DIV_NAMES_AND_COUNTS"],
    authUser: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getDivNamesAndCounts: corporation_id =>
      dispatch(getDivNamesAndCounts(corporation_id)),
    editCorporationInfo: corporation =>
      dispatch(editCorporationInfo(corporation)),
    addDivision: (corp_id, division) =>
      dispatch(addDivision(corp_id, division)),
    editDivision: (corp_id, division) =>
      dispatch(editDivision(corp_id, division)),
    deleteDivision: (corp_id, div_id) =>
      dispatch(deleteDivision(corp_id, div_id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withI18n()(Edit));
