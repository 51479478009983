import React from "react";
import { InputBase } from "@material-ui/core";

export default props => (
  <div
    style={{
      display: "flex",
      borderRadius: 50,
      border: "1px solid #7a7a7a",
      paddingLeft: 5,
      paddingRight: 5,
      alignItems:'center',
      ...props.containerstyle
    }}
  >
    {props.lefticon}
    <InputBase variant="outlined" {...props} style={{width:"100%",...props.style}}/>
  </div>
);
